/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "rsuite";
import { useThemes } from "../../../UserContext/UserContext";


function AddressLivraison() {
    const { pay, SetPay,
        provin, Setprovin,
        comune, Setcomune,
        zone, setzone,
        CollineQuartie, SetCollineQuartie,
        avenue, Setavenue,
        NUmMaison, SetNUmMaison,
        tel, Settel,
        isTextarea, setIsTextarea
    } =useThemes()

    const elemetRefpay = useRef(null)
    const elemetRefprovin = useRef(null)
    const elemetRefcomune = useRef(null)
    const elemetRefzone = useRef(null)
    const elemetRefCollineQuartie = useRef(null)
    const elemetRefavenue = useRef(null)
    const elemetRefNUmMaison = useRef(null)
    const elemetReftel = useRef(null)
    const textaresRef = useRef(null)

    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [isSearching, setIsSearching] = useState(false);
    const [locationName, setLocationName] = useState('');

    useEffect(() => {
        const getLocation = () => {
            setIsSearching(true);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const lat = position.coords.latitude;
                        const lon = position.coords.longitude;
                        setLocation({
                            latitude: lat,
                            longitude: lon,
                        });
                        await reverseGeocode(lat, lon);
                        setIsSearching(false);
                    },
                    (error) => {
                        console.error('Erreur lors de la récupération de la position : ' + error.message);
                        setIsSearching(false);
                    }
                );
            } else {
                console.error("La géolocalisation n'est pas prise en charge par ce navigateur.");
                setIsSearching(false);
            }
        };

        getLocation();
    }, []);




    const reverseGeocode = async (lat, lon) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
            if (response.data && response.data.display_name) {
                const location = response.data.display_name;
                setLocationName(location);
                SetPay(response.data.address.country)
                Setprovin(response.data.address.city)
                setzone(response.data.address.suburb)
                SetCollineQuartie(response.data.address.amenity)
                Setavenue(response.data.address.road)
                setIsSearching(false);
            } else {
                console.error('Impossible de trouver le lieu.');
                setIsSearching(false);
            }
        } catch (error) {
            console.error('Erreur lors de la requête de géocodage inversé :', error.message);
            setIsSearching(false);
        }
    };




    function handleSubmit(e) {
        e.preventDefault()

        if (pay.trim() === '') {
            toast.warning('Votre pay de livraison', {
                autoClose: 2000
            });

            elemetRefpay.current.focus()

            return false;
        } else if (provin.trim() === '') {
            toast.warning('Le  pronvice de livraison est obligatoire', {
                autoClose: 2000
            });

            elemetRefprovin.current.focus()
            return false;
        }
        else if (comune.trim() == "") {
            toast.warning("Commune de livraison est obligatoire !!", {
                autoClose: 2000
            });
            elemetRefcomune.current.focus()
            return false;
        }
        else if (zone.trim() == '') {
            toast.warning("Zone de livraison est obligatoire !!", {
                autoClose: 2000
            });
            elemetRefzone.current.focus();
            return false;
        } else if (CollineQuartie.trim() == '') {
            toast.error("La colline ou quartier de livraison est obligatoire", {
                autoClose: 2000
            });
            elemetRefCollineQuartie.current.focus();
            return false;
        } else if (avenue.trim() == '') {
            toast.warning('Avenue de livraison est obligatoire', {
                autoClose: 2000
            });
            elemetRefavenue.current.focus();
            return false;
        } else if (NUmMaison.trim() == '') {
            toast.warning('Numero du maison de livraison est obligatoire', {
                autoClose: 2000
            });
            elemetRefNUmMaison.current.focus();
            return false;
        }
        else if (tel.trim() == '') {
            toast.warning('Le numero de telephone est obligatoire', {
                autoClose: 2000
            });
            elemetReftel.current.focus();
            return false;
        }


        else if (isTextarea.trim() == '') {
            toast.warning('la description des produit commandés est obligatoire', {
                autoClose: 2000
            });
            textaresRef.current.focus();
            return false;
        }
    }


    return (
        <>
            <div className="h-max">
                {isSearching ?
                    <div style={{ textAlign: 'center' }}>
                        <Loader size="md" />
                    </div>
                    :
                    <form onSubmit={handleSubmit} className="w-full">
                        <div className="flex flex-col h-max   justify-center ">
                            <div className={`w-full h-max  flex flex-col items-center`}>
                                <div className="px-1 h-max w-full">
                                    <div
                                        className="w-full"
                                    >
                                        <div className="flex flex-col h-max w-full">
                                            <div className=" transition-all flex flex-col w-[100%] ">

                                                <div class={`flex flex-col mt-5 h-11  w-full`}>
                                                    <label className="text-lg mb-1">Votre pay</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        ref={elemetRefpay} value={pay} onInput={(e) => SetPay(e.target.value)}
                                                        placeholder=" Votre pay" />
                                                </div>

                                                <div class={`flex flex-col mt-10 h-11  w-full`}>
                                                    <label className="text-lg mb-1">Province</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        ref={elemetRefprovin} value={provin} onInput={(e) => Setprovin(e.target.value)}
                                                        placeholder=" Votre Province" />
                                                </div>
                                            </div>


                                            <div className=" transition-all mt-10 flex flex-col w-[100%] ">

                                                <div class={`flex flex-col   h-11  w-full`}>
                                                    <label className="text-lg mb-1">Commune</label>
                                                    <input
                                                        className="p-2 bg-transparent  outline-none rounded border focus:border-[#5dca32]"
                                                        value={comune} ref={elemetRefcomune} onInput={(e) => Setcomune(e.target.value)}
                                                        placeholder="Commune" />
                                                </div>

                                                <div class={`flex flex-col mt-10 h-11  w-full`}>
                                                    <label className="text-lg mb-1">Zone</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={zone} ref={elemetRefzone} onInput={(e) => setzone(e.target.value)}
                                                        placeholder="Zone" />
                                                </div>
                                            </div>

                                            <div className=" transition-all mt-10 flex flex-col w-[100%] ">

                                                <div class={`flex flex-col  h-11  w-full`}>
                                                    <label className="text-lg mb-1">Colline/Quartier</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={CollineQuartie} ref={elemetRefCollineQuartie} onInput={(e) => SetCollineQuartie(e.target.value)}
                                                        placeholder="Colline/Quartier" />
                                                </div>

                                                <div class={`flex flex-col mt-10 h-11  w-full`}>
                                                    <label className="text-lg mb-1">Avenue</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={avenue} ref={elemetRefavenue} onInput={(e) => Setavenue(e.target.value)}
                                                        placeholder="Avenue" />
                                                </div>
                                            </div>






                                            <div className=" transition-all mt-10 flex flex-col w-[100%] ">

                                                <div class={`flex flex-col  h-11  w-full`}>
                                                    <label className="text-lg mb-1">Numéro de la maison</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={NUmMaison} ref={elemetRefNUmMaison} onInput={(e) => SetNUmMaison(e.target.value)}
                                                        placeholder="Le numéro de la maison" />
                                                </div>

                                                <div class={`flex flex-col mt-10 h-11  w-full`}>
                                                    <label className="text-lg mb-1">Numéro de téléphone actuel</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={tel} ref={elemetReftel} onInput={(e) => Settel(e.target.value)}
                                                        placeholder="Numéro de téléphone actuel" />
                                                </div>
                                            </div>

                                            <div class={`flex flex-col mt-10   w-full`}>
                                                <label className="text-lg mb-1">Déscription de commandés</label>
                                                <textarea ref={textaresRef}
                                                    value={isTextarea}
                                                    onChange={(e) => {
                                                        setIsTextarea(e.target.value);
                                                    }}
                                                    placeholder=" La déscription de commandés"
                                                    className="p-2 min-h-[10em] bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inline-flex h-max items-center">
                                        <label className="relative flex cursor-pointer items-center rounded-full p-3" htmlFor="login" data-ripple-dark="true">
                                            <input id="login" type="checkbox" className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#5dca32] checked:bg-[#5dca32] checked:before:bg-[#5dca32] hover:before:opacity-10" />
                                            <div className="pointer-events-none absolute top-[50%] left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                                                <i className="bi bi-check" />
                                            </div>
                                        </label>
                                        <label className="mt-px cursor-pointer select-none max-sm:text-sm max-sm:text-center font-light text-gray-700" htmlFor="login">
                                            Choisir comme adresse de livraison principale
                                        </label>
                                    </div>
                                    <div className="flex justify-end items-center mt-4">
                                        <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-[#5dca32]   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Confirme' className='cursor-pointer '></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                }

            </div>
        </>
    );
}

export default AddressLivraison;





