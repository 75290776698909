import React, { useEffect, useState } from 'react'

function ABP() {
    const [mobile1, Setmobile1] = useState(window.innerWidth < 804);
    const [mobile2, Setmobile2] = useState(window.innerWidth < 584);
    useEffect(() => {
        const hundleSize = () => {
            Setmobile1(window.innerWidth < 804)
            Setmobile2(window.innerWidth < 584)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    return (
        <div>
            <div className="w-full flex flex-col mt-3 items-center">
                <div className="w-[95%]  flex flex-col items-end rounded-xl my-3 shadow-2xl p-3">
                    <div className={`text-center ${mobile1 ? 'text-[22px]' : 'text-[35px]'} w-full  font-bold  border-b pb-2`}>
                        Ce quoi Agence Burundi Paradise ?
                    </div>
                    <div className={`${mobile1 ? 'text-[13px]' : 'text-[20px]'} sm:my-1 text-center`}>
                    Agence Burundi Paradise est votre guichet unique pour tous vos projets immobiliers au Burundi. 
                    Que vous soyez à la recherche d'un bien à acquérir, d'un investissement à réaliser ou d'un séjour à réserver, 
                    notre plateforme en ligne vous offre des solutions sur-mesure.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ABP