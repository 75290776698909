import React, { useState } from 'react';
import { useThemes } from '../../../UserContext/UserContext';

const VoyageurClient = () => {
    const { increaseAdults, chambre, decreaseAdults, increaseChildren, decreaseChildren, handleAgeChange, removeBlock, addRoom, logChambreValues } = useThemes()
    const [desable, Setdesable] = useState(false)
    return (
        <div>
            {chambre.map((block, index) => (
                <div key={index} className="  w-full p-1 sm:p-4 mb-10  flex flex-col">
                    <div className="sm:mt-4 mt-1 border rounded p-3 border-[#5dca32]">
                        <div className='text-xl mb-5 font-bold'>Chambre {index + 1} </div>
                        <div className='flex justify-center flex-col'>
                            <div className='flex items-center'>
                                <div className="text-lg">Adultes</div>
                                <div className="flex ml-3 border border-gray-300 divide-x divide-gray-300 w-max">
                                    <button
                                        disabled={desable}
                                        className={` ${desable ? 'opacity-40 bg-gray-400' : 'opacity-100'} h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                        onClick={() => {
                                            if (block.adults > 1) {
                                                decreaseAdults(index)
                                                Setdesable(false)
                                            } else {
                                                Setdesable(true)
                                            }
                                        }}
                                    >
                                        -
                                    </button>
                                    <div className="h-6 w-6 text-base flex items-center justify-center">
                                        {block.adults}
                                    </div>
                                    <button
                                        className={`h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                        onClick={() => { increaseAdults(index); Setdesable(false) }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>

                            <div className='flex items-start mt-2'>
                                <div>
                                    <div className="text-lg">Enfants</div>
                                    <div className="text-xs text-gray-500">0 à 17 ans</div>
                                </div>
                                <div className="flex mt-1 ml-3 border border-gray-300 divide-x divide-gray-300 w-max">
                                    <button
                                        className="h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none"
                                        onClick={() => decreaseChildren(index)}
                                    >
                                        -
                                    </button>
                                    <div className="h-6 w-6 text-base flex items-center justify-center">
                                        {block.children}
                                    </div>
                                    <button
                                        className={`h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                        onClick={() => increaseChildren(index)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            {/* Affichage des champs de saisie pour les âges des enfants */}
                            {block.children > 0 && (
                                <div className="mt-2">
                                    {block.childAges.map((age, childIndex) => (
                                        <select
                                            key={childIndex}
                                            className="border bg-transparent border-[#5dca32] rounded-md px-2 py-1 mt-1"
                                            value={age}
                                            onChange={(e) => handleAgeChange(index, childIndex, parseInt(e.target.value))}
                                        >
                                            <option className='hidden'>Âge de l'enfant {childIndex + 1}</option>
                                            <option className='text-black cursor-pointer' value="1">1</option>
                                            <option className='text-black cursor-pointer' value="2">2</option>
                                            <option className='text-black cursor-pointer' value="3">3</option>
                                            <option className='text-black cursor-pointer' value="4">4</option>
                                            <option className='text-black cursor-pointer' value="5">5</option>
                                            <option className='text-black cursor-pointer' value="6">6</option>
                                            <option className='text-black cursor-pointer' value="7">7</option>
                                            <option className='text-black cursor-pointer' value="8">8</option>
                                            <option className='text-black cursor-pointer' value="9">9</option>
                                            <option className='text-black cursor-pointer' value="10">10</option>
                                            <option className='text-black cursor-pointer' value="11">11</option>
                                            <option className='text-black cursor-pointer' value="12">12</option>
                                            <option className='text-black cursor-pointer' value="13">13</option>
                                            <option className='text-black cursor-pointer' value="14">14</option>
                                            <option className='text-black cursor-pointer' value="15">15</option>
                                            <option className='text-black cursor-pointer' value="16">16</option>
                                            <option className='text-black cursor-pointer' value="17">17</option>
                                        </select>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='w-full flex justify-start'>
                        {index !== 0 && (
                            <button type="button" className='text-red-600 font-extrabold' onClick={() => removeBlock(index)}>
                                Supprimer
                            </button>
                        )}
                    </div>
                </div>
            ))}
            <div className='w-full flex justify-end'>
                <div className='flex flex-col items-end'>
                    <div className='mb-2 cursor-pointer' onClick={addRoom} >
                        Ajouter une chambre
                    </div>
                    {/* <div className='px-3 py-2 cursor-pointer  bg-[#5dca32] text-white rounded' onClick={logChambreValues}>
                        Valider
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default VoyageurClient;
