import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'

function Hotel() {
    const { useDocTitle } = useThemes()
    useDocTitle("BPS| Hotels")
    return (
        <div className='flex mt-[5em] overflow-hidden relative'>
            <div className='w-1/2'>
                <div className="sm:text-[20px]  inline-flex items-center font-bold mb-4  ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    Réservation en ligne pour les hôtes
                </div>
                <div className='text-[20px] p-2'>
                    Grâce à notre interface intuitive, vos visiteurs peuvent réserver leur séjour en quelques clics seulement.
                    Fini les échanges de mails et les appels téléphoniques, tout se passe simplement en ligne.
                    Vos invités bénéficient ainsi d'une expérience de réservation rapide et sans encombre.
                    <br />
                    <br />
                    Depuis votre espace dédié, vous suivez en temps réel les réservations entrantes. Vous pouvez également communiquer facilement avec vos visiteurs, leur envoyer des informations pratiques et leur proposer des services complémentaires.
                    Grâce à notre système de réservation en ligne, offrez à vos visiteurs une expérience d'accueil fluide et sans tracas. Concentrez-vous sur l'essentiel : l'hospitalité et le bien-être de vos hôtes.
                    <br />
                    <div className='flex w-full justify-end  items-center pr-3'>
                        <div className='flex items-center text-[#5dca32b7] transition-all hover:border-b hover:border-[#5dca32] p-1 hover:text-[#5dca32] cursor-pointer'>
                            <div>Voir autre</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-2 bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#5dca32] animate-ping w-10 h-10 t-1/2 left-1/2 absolute'></div>
            <div className='bg-[#e75c39] animate-ping w-10 rounded-full h-10 top-1/2 left-1/4 absolute'></div>
            <div className='w-1/2'>
                <div className='h-[60vh] w-full flex justify-center items-center '>
                    <div className='w-[95%] h-[95%] animation rounded overflow-hidden'>
                        <img className='h-full w-full object-cover' src='https://roomraccoon.fr/wp-content/uploads/2023/07/HMS-Blog-hero.jpg' alt='   ' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hotel