export const medic = [
    {
        id: 1,
        "nom": "Fjallraven - Foldsack No. 1 Backpack, Fits 15 Laptops",
        "quantite": 109,
        "dateExpira": "20/2/2020",
    },
    {
        id: 2,
        "nom": "Mens Casual Premium Slim Fit T-Shirts ",
        "quantite": 22,
        "dateExpira": "20/2/2020",
    },
    {
        id: 3,
        "nom": "Mens Cotton Jacket",
        "quantite": 52,
        "dateExpira": "20/2/2020",
    },
    {
        id: 4,
        "nom": "Mens Casual Slim Fit",
        "quantite": 15,
        "dateExpira": "20/2/2020",
    },
    {
        id: 5,
        "nom": "John Hardy Women's Legends Naga Gold & Silver Dragon Station Chain Bracelet",
        "quantite": 695,
        "dateExpira": "20/2/2020",
    },
    {
        id: 6,
        "nom": "Solid Gold Petite Micropave ",
        "quantite": 168,
        "dateExpira": "20/2/2020",
    },
    {
        id: 7,
        "nom": "White Gold Plated Princess",
        "quantite": 9,
        "dateExpira": "20/2/2020",
    },
    {
        id: 8,
        "nom": "Pierced Owl Rose Gold Plated Stainless Steel Double",
        "quantite": 10,
        "dateExpira": "20/2/2020",
    },
    {
        id: 9,
        "nom": "WD 2TB Elements Portable External Hard Drive - USB 3.0 ",
        "quantite": 64,
        "dateExpira": "20/2/2020",
    },
    {
        id: 10,
        "nom": "SanDisk SSD PLUS 1TB Internal SSD - SATA III 6 Gb/s",
        "quantite": 109,
        "dateExpira": "20/2/2020",
    },
    {
        id: 11,
        "nom": "Silicon Power 256GB SSD 3D NAND A55 SLC Cache Performance Boost SATA III 2.5",
        "quantite": 109,
        "dateExpira": "20/2/2020",
    },
    {
        id: 12,
        "nom": "WD 4TB Gaming Drive Works with Playstation 4 Portable External Hard Drive",
        "quantite": 114,
        "dateExpira": "20/2/2020",
    },
    {
        id: 13,
        "nom": "Acer SB220Q bi 21.5 inches Full HD (1920 x 1080) IPS Ultra-Thin",
        "quantite": 599,
        "dateExpira": "20/2/2020",
    },
    {
        id: 14,
        "nom": "Samsung 49-Inch CHG90 144Hz Curved Gaming Monitor (LC49HG90DMNXZA) – Super Ultrawide Screen QLED ",
        "quantite": 999,
        "dateExpira": "20/2/2020",
    },
    {
        id: 15,
        "nom": "BIYLACLESEN Women's 3-in-1 Snowboard Jacket Winter Coats",
        "quantite": 56,
        "dateExpira": "20/2/2020",
    },
    {
        id: 16,
        "nom": "Lock and Love Women's Removable Hooded Faux Leather Moto Biker Jacket",
        "quantite": 29,
        "dateExpira": "20/2/2020",
    },
    {
        id: 17,
        "nom": "Rain Jacket Women Windbreaker Striped Climbing Raincoats",
        "quantite": 39,
        "dateExpira": "20/2/2020",
    },
    {
        id: 18,
        "nom": "MBJ Women's Solid Short Sleeve Boat Neck V ",
        "quantite": 9,
        "dateExpira": "20/2/2020",
    },
    {
        id: 19,
        "nom": "Opna Women's Short Sleeve Moisture",
        "quantite": 7,
        "dateExpira": "20/2/2020",
    },
    {
        id: 20,
        "nom": "DANVOUY Womens T Shirt Casual Cotton Short",
        "quantite": 12,
        "dateExpira": "20/2/2020",
    }
]


export const dataSloger = [
    {
        titre: "Réservation en ligne pour les hôtes",
        ur: "https://roomraccoon.fr/wp-content/uploads/2023/07/HMS-Blog-hero.jpg",
        description: "Nos services de réservation en ligne vous permet de réserver rapidement et facilement les services dont vous avez besoin pour votre événement. En quelques clics seulement, vous pouvez sélectionner les dates, le nombre de participants, les options de service, et finaliser votre réservation."
    },
    {
        titre: 'Équipement photo videographie',
        ur: "https://images.unsplash.com/photo-1619479674806-8f6e70be07da?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        description: "Notre arsenal de microphones comprend des modèles renommés tels que les Neumann U87, Shure SM7B, Sennheiser MKH416 et bien d'autres. Ces microphones de studio offrent une reproduction sonore détaillée et naturelle, idéale pour capturer avec précision la moindre nuance de vos performances vocales ou instrumentales."
    },

    {
        titre: "Tourisme",
        ur: "https://pro.regiondo.com/wp-content/uploads/2023/03/tourism-trends-2023-travel-industry-1024x576.jpg",
        description: "Grâce à notre moteur de recherche avancé, vous pouvez facilement trouver les destinations qui correspondent à vos critères (budget, thématique, activités, etc.). Consultez les informations détaillées sur les villes, régions et pays, et comparez les différentes options pour choisir la meilleure."
    },
    {
        titre: "Location immobiliers",
        ur: "https://renoverpourgagner.com/wp-content/uploads/2023/07/AdobeStock_603805220.jpeg",
        description: "Nos équipes d'experts analysent en permanence les différents marchés immobiliers pour identifier les meilleures opportunités d'investissement. Nous vous proposons une sélection de biens correspondant à votre stratégie d'investissement (appartements, maisons, bureaux, commerces, etc.)."
    },
    {
        titre: "Réservation de billets d'avion en ligne",
        ur: "https://cdn-s-www.estrepublicain.fr/images/44D8BF74-806E-45BA-938E-E894431CA532/NW_raw/photo-illustration-adobe-stock-1664889872.jpg",
        description: "Notre moteur de recherche vous permet de trouver facilement les vols correspondant à vos critères. Vous pouvez rechercher des vols par date, destination, nombre de passagers, etc. Le système affiche alors les différentes options disponibles avec les tarifs associés, vous permettant de comparer et de choisir la meilleure offre."
    },

]



export const ProductListData = [
    {
        id: 101,
        title: "Alice Dupont",
        url: "https://img.lignes-formations.com/wp-content/uploads/sites/45/2019/05/metier-photographe-professionnel.jpg",
        description: "Photographe spécialisée en portraits et mariages avec plus de 10 ans d'expérience.",
        portfolio: ["alice1.jpg", "alice2.jpg", "alice3.jpg"],
        email: "alice@example.com",
        phone: "123-456-7890",
        socialMedia: {
            instagram: "https://instagram.com/alicephoto",
            facebook: "https://facebook.com/alicephoto"
        },
        website: "https://www.alicephoto.com",
        specialization: ["Portrait", "Mariage"],
        awards: "Prix de la Photographie 2021",
        experience: "10 ans",
        testimonials: "Alice a capturé des moments magnifiques lors de notre mariage. - Client A",
        availability: " Lodge de prestige avec center d'affaire et reception ouverte 24h/24hDisponible pour des projets en 2024.",
        location: "Paris, France",
        equipment: ["Canon EOS R5", "Adobe Lightroom"],
        priceRange: {
            hourly: "100€ - 200€",
            halfDay: "350€ - 700€",
            fullDay: "600€ - 1200€"
        }
    },
    {
        id: 1,
        title: "Souplesse",
        url: "https://www.okumak.fr/wp-content/uploads/2022/05/chaussur-807fjk-1.jpg",
        qty: 10,
        price: 20.99,
        category: "Chaussure",
        brand: "Burundi en temps reel",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        delivery_time: "1-3 days",
        delivery_address: " 10 Rue de la Solidarité, Bujumbura, Burundi",
    },
    {
        id: 32,
        title: "vetements_garcon",
        url: "https://catalogue.aubert.com/images/specific/textile/vetements_garcon/hero.jpg",
        qty: 10,
        price: 202.99,
        category: "Chaussure",
        brand: "Burundi en temps reel",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        delivery_time: "1-3 days",
        delivery_address: " 10 Rue de la Solidarité, Bujumbura, Burundi",
    },
    {
        id: 2,
        title: "Flat souplesse",
        url: "https://www.cdiscount.com/pdt2/2/0/9/1/700x700/mp54571209/rw/skateshoes-usb-charge-baskets-enfants-led-chaussur.jpg",
        qty: 5,
        price: 15.99,
        category: "Slippers",
        brand: "Slipper",
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        delivery_time: "2-5 days",
        delivery_address: "24 Avenue de l'Umoja, Gitega, Burundi",
    },
    {
        id: 25,
        title: "guitare",
        url: "https://guitare-et-studio.fr/wp-content/uploads/2024/04/Enregistrement-dune-guitare-en-home-studio.jpg",
        qty: 50,
        price: 15.99,
        category: "Slippers",
        brand: "Slipper",
        description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        delivery_time: "2-5 days",
        delivery_address: "24 Avenue de l'Umoja, Gitega, Burundi",
    },
    {
        id: 3,
        title: "Edelman",
        url: "https://i.pinurl.com/236x/19/7e/2c/197e2c2fbe13de264b7da26dc95f7c3f.jpg",
        qty: 20,
        price: 9.99,
        category: "category 3",
        brand: "brand 3",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "3-7 days",
        delivery_address: "5 Rue de l'Unité, Ngozi, Burundi",
    },

    {
        id: 4,
        title: "microphone-studio-professionnel-filaire-dynamique",
        url: "https://www.cdiscount.com/pdt2/4/3/7/1/700x700/wew3615610178437/rw/microphone-studio-professionnel-filaire-dynamique.jpg",
        qty: 15,
        price: 12.99,
        category: "category 4",
        brand: "brand 4",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "1-3 days",
        delivery_address: "18 Avenue de la Paix, Kayanza, Burundi",
    },
    {
        id: 26,
        title: "Chatie",
        url: "https://www.droit-travail-france.fr/photos/800x532/habit-pas-moine.jpg",
        qty: 10,
        price: 12.99,
        category: "category 4",
        brand: "brand 4",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "1-3 days",
        delivery_address: "18 Avenue de la Paix, Kayanza, Burundi",
    },
    {
        id: 5,
        title: "Videocameras",
        url: "https://www.kamera-express.fr/media/3dc8bf2e-274a-41f3-b926-ee19e823cf65/Top-10-Professionele-Videocameras.png",
        qty: 8,
        price: 24.99,
        category: "category 5",
        brand: "brand 5",
        description:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        delivery_time: "2-5 days",
        delivery_address: "3 Rue du Commerce, Rutana, Burundi",
    },
    {
        id: 6,
        title: "microphone-electret-pro-1",
        url: "https://www.bbeam.com/4210-tm_large_default/microphone-electret-pro-1.jpg",
        qty: 12,
        price: 7.99,
        category: "category 6",
        brand: "brand 6",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        delivery_time: "3-7 days",
        delivery_address: " 22 Avenue de la Révolution, Makamba, Burundi",
    },
    {
        id: 7,
        title: "Multimedia",
        url: "https://multimedia.bbycastatic.ca/multimedia/products/500x500/138/13841/13841720.jpeg",
        qty: 3,
        price: 18.99,
        category: "category 7",
        brand: "brand 7",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "1-3 days",
        delivery_address: " 9 Rue de la Liberté, Bururi, Burundi",
    },
    {
        id: 24,
        title: "Audio-professionnel",
        url: "https://image.made-in-china.com/44f3j00JKVUDypWEPql/L-Audio-professionnel-650W-amplificateur-de-son.webp",
        qty: 3,
        price: 180.99,
        category: "category 7",
        brand: "brand 7",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "1-3 days",
        delivery_address: " 9 Rue de la Liberté, Bururi, Burundi",
    },
    {
        id: 8,
        title: "Camera simple",
        url: "https://dtz3um9jw7ngl.cloudfront.net/p/m/7634616N/7634616N.jpg",
        qty: 18,
        price: 6.99,
        category: "category 8",
        brand: "brand 8",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "2-5 days",
        delivery_address: " 12 Avenue de la Justice, Rumonge, Burundi",
    },
    {
        id: 9,
        title: "plissee",
        url: "https://www.lamode.tn/44722-large_default/jupe-courte-plissee-sh-de380008.webp",
        qty: 7,
        price: 14.99,
        category: "category 9",
        brand: "brand 9",
        description:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        delivery_time: "3-7 days",
        delivery_address: "6 Rue de l'Espoir, Cibitoke, Burundi",
    },
    {
        id: 10,
        title: "Microphone",
        url: "https://m.media-amazon.com/images/I/61mEkbjNoCL._AC_UF1000,1000_QL80_.jpg",
        qty: 25,
        price: 21.99,
        category: "category 10",
        brand: "brand 10",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        delivery_time: "1-3 days",
        delivery_address: "15 Avenue de la Démocratie, Kirundo, Burundi",
    },
    {
        id: 23,
        title: "Piano",
        url: "https://pianosbolduc.com/cdn/shop/products/BostonPE_GP163EP-min-copie.jpg?v=1670270202",
        qty: 154,
        price: 110.99,
        category: "category 11",
        brand: "brand 11",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "2-5 days",
        delivery_address: "1 Rue de la Fraternité, Bubanza, Burundi",
    },

    {
        id: 11,
        title: "Keza",
        url: "https://m.media-amazon.com/images/I/61TvHvdCtkL._AC_UF350,350_QL50_.jpg",
        qty: 14,
        price: 11.99,
        category: "category 11",
        brand: "brand 11",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "2-5 days",
        delivery_address: "1 Rue de la Fraternité, Bubanza, Burundi",
    },
    {
        id: 12,
        title: "Camera professionel",
        url: "https://pro.sony/s3/2017/09/20110236/F9-and-Z750-category-image.jpg",
        qty: 9,
        price: 17.99,
        category: "category 12",
        brand: "brand 12",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "3-7 days",
        delivery_address: "19 Avenue de l'Égalité, Muyinga, Burundi",
    },
    {
        id: 13,
        title: "Moonwalker_chaussures_big",
        url: "https://sf1.cnetfrance.fr/wp-content/uploads/cnet/2023/05/Moonwalker_chaussures_big.jpg",
        qty: 2,
        price: 8.99,
        category: "Chaussure",
        brand: "brand 13",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        delivery_time: "1-3 days",
        delivery_address: "4 Rue de la Santé, Muramvya, Burundi",
    },
    {
        id: 14,
        title: "Ikiyiko",
        url: "https://m.media-amazon.com/images/I/71BAA1QyjYL._AC_SR175,263_QL70_.jpg",
        qty: 17,
        price: 23.99,
        category: "category 14",
        brand: "brand 14",
        description:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        delivery_time: "2-5 days",
        delivery_address: "20 Avenue de la Solidarité, Ngozi, Burundi",
    },
    {
        id: 22,
        title: "catalog",
        url: "https://url.kentfaith.de/cache/catalog/products/de/KF09.115/KF09.115-1-518x518.jpg",
        qty: 107,
        price: 203.99,
        category: "category 14",
        brand: "brand 14",
        description:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        delivery_time: "2-5 days",
        delivery_address: "20 Avenue de la Solidarité, Ngozi, Burundi",
    },
    {
        id: 15,
        title: "Patron",
        url: "https://www.ateliercharlotteauzou.com/cdn/shop/articles/comparatif-jupes-cercle-patron-couture.jpg?v=1677677504&width=2048",
        qty: 6,
        price: 13.99,
        category: "category 15",
        brand: "brand 15",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "3-7 days",
        delivery_address: " 2 Rue de la Foi, Cankuzo, Burundi",
    },
    {
        id: 16,
        title: "camera-video-numerique-professionnel",
        url: "https://c8.alamy.com/compfr/by26db/rouge-une-camera-video-numerique-professionnel-modulaire-by26db.jpg",
        qty: 22,
        price: 19.99,
        category: "category 16",
        brand: "brand 16",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "1-3 days",
        delivery_address: " 14 Avenue de la Concorde, Karuzi, Burundi",
    },
    {
        id: 17,
        title: "camera-professionnelle-dslr",
        url: "https://url.phonandroid.com/2018/11/camera-professionnelle-dslr.jpg",
        qty: 11,
        price: 10.99,
        category: "category 17",
        brand: "brand 17",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        delivery_time: "2-5 days",
        delivery_address: " 7 Rue de la Tolérance, Bujumbura Rural, Burundi",
    },
    {
        id: 18,
        title: "couleur-usb",
        url: "https://www.cdiscount.com/pdt2/4/3/1/1/700x700/mp13790431/rw/chaussure-led-lumineuse-homme-femme-7-couleur-usb.jpg",
        qty: 4,
        price: 16.99,
        category: "category 18",
        brand: "brand 18",
        description:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        delivery_time: "3-7 days",
        delivery_address: "23 Avenue de la Charité, Bururi, Burundi",
    },
    {
        id: 19,
        title: "Camera-cinema format-4K-HDR",
        url: "https://www.avbroadcast.fr/media/catalog/product/cache/1/small_image/840x/602f0fa2c1f0d1ba5e241f914e856ff9/s/o/sony-fx6_1_/ILME-FX6-Camera-cinema-plein-format-4K-HDR-a-monture-Sony-E-Sony-20.jpg",
        qty: 19,
        price: 22.99,
        category: "category 19",
        brand: "brand 19",
        description:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        delivery_time: "1-3 days",
        delivery_address: " 11 Rue de la Prospérité, Makamba, Burundi",
    },
    {
        id: 20,
        title: "baguette",
        url: "https://www.stellinox.eu/5190-superlarge_default/fourchette-a-poisson-baguette.jpg",
        qty: 13,
        price: 7.49,
        category: "category 20",
        brand: "brand 20",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "2-5 days",
        delivery_address: "16 Avenue de la Patience, Gitega, Burundi",
    },

    {
        id: 22,
        title: "monopode camera",
        url: "https://www.cdiscount.com/pdt2/2/1/4/1/700x700/esd6973900311214/rw/esddi-trepied-appareil-photo-monopode-camera-profe.jpg",
        qty: 13,
        price: 172.49,
        category: "category 20",
        brand: "brand 20",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "2-5 days",
        delivery_address: "16 Avenue de la Patience, Gitega, Kenya",
    },


    {
        id: 29,
        title: "vetements",
        url: "https://www.cdiscount.com/pdt2/8/5/5/1/700x700/mp181089855/rw/ensemble-de-vetements-1-5-ans-bebe-enfant-garcon-2.jpg",
        qty: 23,
        price: 762.49,
        category: "category 20",
        brand: "brand 20",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "2-5 days",
        delivery_address: "16 Avenue de la Patience, Gitega, Kenya",
    },
    {
        id: 30,
        title: "alicdn",
        url: "https://ae01.alicdn.com/kf/S6b4114a3a14a4562ba0bcfda90d3175ao.jpg_300x300Q70.jpg_.webp",
        qty: 12,
        price: 702.49,
        category: "category 20",
        brand: "brand 20",
        description:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        delivery_time: "2-5 days",
        delivery_address: "16 Avenue de la Patience, Gitega, Kenya",
    },
    {
        id: 41,
        title: "Fjallraven - Foldsack No. 1 Backpack, Fits 15 Laptops",
        price: 109.95,
        description: "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
        category: "men's clothing",
        image: "https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg",
        qty: 120,
        brand: "brand 20",

    },
    {
        id: 42,
        title: "Mens Casual Premium Slim Fit T-Shirts ",
        price: 22.3,
        description: "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
        category: "men's clothing",
        url: "https://fakestoreapi.com/img/71-3HjGNDUL._AC_SY879._SX._UX._SY._UY_.jpg",
        qty: 259

    },
    {
        id: 43,
        title: "Mens Cotton Jacket",
        price: 55.99,
        description: "great outerwear jackets for Spring/Autumn/Winter, suitable for many occasions, such as working, hiking, camping, mountain/rock climbing, cycling, traveling or other outdoors. Good gift choice for you or your family member. A warm hearted love to Father, husband or son in this thanksgiving or Christmas Day.",
        category: "men's clothing",
        url: "https://fakestoreapi.com/img/71li-ujtlUL._AC_UX679_.jpg",
        qty: 500

    },
    {
        id: 44,
        title: "Mens Casual Slim Fit",
        price: 15.99,
        description: "The color could be slightly different between on the screen and in practice. / Please note that body builds vary by person, therefore, detailed size information should be reviewed below on the product description.",
        category: "men's clothing",
        url: "https://fakestoreapi.com/img/71YXzeOuslL._AC_UY879_.jpg",
        qty: 430,
        brand: "brand 20",
    },
    {
        id: 45,
        title: "John Hardy Women's Legends Naga Gold & Silver Dragon Station Chain Bracelet",
        price: 695,
        description: "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
        category: "jewelery",
        url: "https://fakestoreapi.com/img/71pWzhdJNwL._AC_UL640_QL65_ML3_.jpg",
        qty: 400,
        brand: "brand 20",
    },
    {
        id: 46,
        title: "Solid Gold Petite Micropave ",
        price: 168,
        description: "Satisfaction Guaranteed. Return or exchange any order within 30 days.Designed and sold by Hafeez Center in the United States. Satisfaction Guaranteed. Return or exchange any order within 30 days.",
        category: "jewelery",
        url: "https://fakestoreapi.com/img/61sbMiUnoGL._AC_UL640_QL65_ML3_.jpg",
        qty: 70,
        brand: "brand 20",
    },
    {
        id: 47,
        title: "White Gold Plated Princess",
        price: 9.99,
        description: "Classic Created Wedding Engagement Solitaire Diamond Promise Ring for Her. Gifts to spoil your love more for Engagement, Wedding, Anniversary, Valentine's Day...",
        category: "jewelery",
        url: "https://fakestoreapi.com/img/71YAIFU48IL._AC_UL640_QL65_ML3_.jpg",
        qty: 400,
        brand: "brand 20",
    },
    {
        id: 48,
        title: "Pierced Owl Rose Gold Plated Stainless Steel Double",
        price: 10.99,
        description: "Rose Gold Plated Double Flared Tunnel Plug Earrings. Made of 316L Stainless Steel",
        category: "jewelery",
        url: "https://fakestoreapi.com/img/51UDEzMJVpL._AC_UL640_QL65_ML3_.jpg",
        qty: 100,
        brand: "brand 20",
    },
    {
        id: 49,
        title: "WD 2TB Elements Portable External Hard Drive - USB 3.0 ",
        price: 64,
        description: "USB 3.0 and USB 2.0 Compatibility Fast data transfers Improve PC Performance High Capacity; Compatibility Formatted NTFS for Windows 10, Windows 8.1, Windows 7; Reformatting may be required for other operating systems; Compatibility may vary depending on user’s hardware configuration and operating system",
        category: "electronics",
        url: "https://fakestoreapi.com/img/61IBBVJvSDL._AC_SY879_.jpg",
        qty: 230,
        brand: "brand 20",
    },
    {
        id: 50,
        title: "SanDisk SSD PLUS 1TB Internal SSD - SATA III 6 Gb/s",
        price: 109,
        description: "Easy upgrade for faster boot up, shutdown, application load and response (As compared to 5400 RPM SATA 2.5” hard drive; Based on published specifications and internal benchmarking tests using PCMark vantage scores) Boosts burst write performance, making it ideal for typical PC workloads The perfect balance of performance and reliability Read/write speeds of up to 535MB/s/450MB/s (Based on internal testing; Performance may vary depending upon drive capacity, host device, OS and application.)",
        category: "electronics",
        url: "https://fakestoreapi.com/img/61U7T1koQqL._AC_SX679_.jpg",
        qty: 470,
        brand: "brand 20",
    },
    {
        id: 51,
        title: "Silicon Power 256GB SSD 3D NAND A55 SLC Cache Performance Boost SATA III 2.5",
        price: 109,
        description: "3D NAND flash are applied to deliver high transfer speeds Remarkable transfer speeds that enable faster bootup and improved overall system performance. The advanced SLC Cache Technology allows performance boost and longer lifespan 7mm slim design suitable for Ultrabooks and Ultra-slim notebooks. Supports TRIM command, Garbage Collection technology, RAID, and ECC (Error Checking & Correction) to provide the optimized performance and enhanced reliability.",
        category: "electronics",
        url: "https://fakestoreapi.com/img/71kWymZ+c+L._AC_SX679_.jpg",
        qty: 319,
        brand: "brand 20",
    },
    {
        id: 52,
        title: "WD 4TB Gaming Drive Works with Playstation 4 Portable External Hard Drive",
        price: 114,
        description: "Expand your PS4 gaming experience, Play anywhere Fast and easy, setup Sleek design with high capacity, 3-year manufacturer's limited warranty",
        category: "electronics",
        url: "https://fakestoreapi.com/img/61mtL65D4cL._AC_SX679_.jpg",
        qty: 400,
        brand: "brand 20",
    },
    {
        id: 53,
        title: "Acer SB220Q bi 21.5 inches Full HD (1920 x 1080) IPS Ultra-Thin",
        price: 599,
        description: "21. 5 inches Full HD (1920 x 1080) widescreen IPS display And Radeon free Sync technology. No compatibility for VESA Mount Refresh Rate: 75Hz - Using HDMI port Zero-frame design | ultra-thin | 4ms response time | IPS panel Aspect ratio - 16: 9. Color Supported - 16. 7 million colors. Brightness - 250 nit Tilt angle -5 degree to 15 degree. Horizontal viewing angle-178 degree. Vertical viewing angle-178 degree 75 hertz",
        category: "electronics",
        url: "https://fakestoreapi.com/img/81QpkIctqPL._AC_SX679_.jpg",
        qty: 120,
        brand: "brand 20",
    },
    {
        id: 54,
        title: "Samsung 49-Inch CHG90 144Hz Curved Gaming Monitor (LC49HG90DMNXZA) – Super Ultrawide Screen QLED ",
        price: 999.99,
        description: "49 INCH SUPER ULTRAWIDE 32:9 CURVED GAMING MONITOR with dual 27 inch screen side by side QUANTUM DOT (QLED) TECHNOLOGY, HDR support and factory calibration provides stunningly realistic and accurate color and contrast 144HZ HIGH REFRESH RATE and 1ms ultra fast response time work to eliminate motion blur, ghosting, and reduce input lag",
        category: "electronics",
        url: "https://fakestoreapi.com/img/81Zt42ioCgL._AC_SX679_.jpg",
        qty: 100,
        brand: "brand 20",
    },
    {
        id: 55,
        title: "BIYLACLESEN Women's 3-in-1 Snowboard Jacket Winter Coats",
        price: 56.99,
        description: "Note:The Jackets is US standard size, Please choose size as your usual wear Material: 100% Polyester; Detachable Liner Fabric: Warm Fleece. Detachable Functional Liner: Skin Friendly, Lightweigt and Warm.Stand Collar Liner jacket, keep you warm in cold weather. Zippered Pockets: 2 Zippered Hand Pockets, 2 Zippered Pockets on Chest (enough to keep cards or keys)and 1 Hidden Pocket Inside.Zippered Hand Pockets and Hidden Pocket keep your things secure. Humanized Design: Adjustable and Detachable Hood and Adjustable cuff to prevent the wind and water,for a comfortable fit. 3 in 1 Detachable Design provide more convenience, you can separate the coat and inner as needed, or wear it together. It is suitable for different season and help you adapt to different climates",
        category: "women's clothing",
        url: "https://fakestoreapi.com/img/51Y5NI-I5jL._AC_UX679_.jpg",
        qty: 125,
        brand: "brand 20",
    },
    {
        id: 56,
        title: "Lock and Love Women's Removable Hooded Faux Leather Moto Biker Jacket",
        price: 29.95,
        description: "100% POLYURETHANE(shell) 100% POLYESTER(lining) 75% POLYESTER 25% COTTON (SWEATER), Faux leather material for style and comfort / 2 pockets of front, 2-For-One Hooded denim style faux leather jacket, Button detail on waist / Detail stitching at sides, HAND WASH ONLY / DO NOT BLEACH / LINE DRY / DO NOT IRON",
        category: "women's clothing",
        url: "https://fakestoreapi.com/img/81XH0e8fefL._AC_UY879_.jpg",
        qty: 920,
        brand: "brand 20",
    },
    {
        id: 57,
        title: "Rain Jacket Women Windbreaker Striped Climbing Raincoats",
        price: 39.99,
        description: "Lightweight perfet for trip or casual wear---Long sleeve with hooded, adjustable drawstring waist design. Button and zipper front closure raincoat, fully stripes Lined and The Raincoat has 2 side pockets are a good size to hold all kinds of things, it covers the hips, and the hood is generous but doesn't overdo it.Attached Cotton Lined Hood with Adjustable Drawstrings give it a real styled look.",
        category: "women's clothing",
        url: "https://fakestoreapi.com/img/71HblAHs5xL._AC_UY879_-2.jpg",
        qty: 10,
        brand: "brand 20",
    },
    {
        id: 58,
        title: "MBJ Women's Solid Short Sleeve Boat Neck V ",
        price: 9.85,
        description: "95% RAYON 5% SPANDEX, Made in USA or Imported, Do Not Bleach, Lightweight fabric with great stretch for comfort, Ribbed on sleeves and neckline / Double stitching on bottom hem",
        category: "women's clothing",
        url: "https://fakestoreapi.com/img/71z3kpMAYsL._AC_UY879_.jpg",
        qty: 20,
        brand: "brand 20",
    },
    {
        id: 59,
        title: "Opna Women's Short Sleeve Moisture",
        price: 7.95,
        description: "100% Polyester, Machine wash, 100% cationic polyester interlock, Machine Wash & Pre Shrunk for a Great Fit, Lightweight, roomy and highly breathable with moisture wicking fabric which helps to keep moisture away, Soft Lightweight Fabric with comfortable V-neck collar and a slimmer fit, delivers a sleek, more feminine silhouette and Added Comfort",
        category: "women's clothing",
        url: "https://fakestoreapi.com/img/51eg55uWmdL._AC_UX679_.jpg",
        qty: 920,
        brand: "brand 20",
    },
    {
        id: 60,
        title: "DANVOUY Womens T Shirt Casual Cotton Short",
        price: 12.99,
        description: "95%Cotton,5%Spandex, Features: Casual, Short Sleeve, Letter Print,V-Neck,Fashion Tees, The fabric is soft and has some stretch., Occasion: Casual/Office/Beach/School/Home/Street. Season: Spring,Summer,Autumn,Winter.",
        category: "women's clothing",
        url: "https://fakestoreapi.com/img/61pHAEJ4NML._AC_UX679_.jpg",
        qty: 190,
        brand: "brand 20",
    }
    ,

    {
        id: 102,
        title: "Bob Martin",
        url: "https://www.cnfdi.com/images/formations/fiche_formations_site/formation-photographie.jpg",
        description: "Passionné de photographie de paysages et de nature.",
        portfolio: ["bob1.jpg", "bob2.jpg", "bob3.jpg"],
        email: "bob@example.com",
        phone: "234-567-8901",
        socialMedia: {
            instagram: "https://instagram.com/bobphoto",
            facebook: "https://facebook.com/bobphoto"
        },
        website: "https://www.bobphoto.com",
        specialization: ["Paysage", "Nature"],
        awards: "Photographe de l'année 2022",
        experience: "8 ans",
        testimonials: "Les photos de Bob sont tout simplement époustouflantes. - Client B",
        availability: "Disponible pour des projets en 2023.",
        location: "Lyon, France",
        equipment: ["Nikon D850", "Adobe Photoshop"],
        priceRange: {
            hourly: "80€ - 160€",
            halfDay: "300€ - 600€",
            fullDay: "500€ - 1000€"
        }
    },
    {
        id: 103,
        title: "Chloe Blanc",
        url: "https://c8.alamy.com/compfr/2j9yk46/portrait-de-la-femme-professionnelle-photographe-dans-le-parc-photographie-sur-un-appareil-photo-photo-prise-de-la-photosession-dans-la-ville-2j9yk46.jpg",
        description: "Photographe de mode avec un style unique.",
        portfolio: ["chloe1.jpg", "chloe2.jpg", "chloe3.jpg"],
        email: "chloe@example.com",
        phone: "345-678-9012",
        socialMedia: {
            instagram: "https://instagram.com/chloephoto",
            facebook: "https://facebook.com/chloephoto"
        },
        website: "https://www.chloephoto.com",
        specialization: ["Mode", "Portrait"],
        awards: "Prix de la Mode 2020",
        experience: "7 ans",
        testimonials: "Chloe a un talent incroyable pour la photographie de mode. - Client C",
        availability: "Disponible pour des projets en 2023.",
        location: "Marseille, France",
        equipment: ["Sony A7 III", "Capture One"],
        priceRange: {
            hourly: "120€ - 240€",
            halfDay: "400€ - 800€",
            fullDay: "700€ - 1400€"
        }
    },
    {
        id: 104,
        title: "David Leroy",
        url: "https://tedeted.com/wp-content/uploads/2017/05/kawin-harasai-245040.jpg.webp",
        description: "Spécialiste de la photographie sportive.",
        portfolio: ["david1.jpg", "david2.jpg", "david3.jpg"],
        email: "david@example.com",
        phone: "456-789-0123",
        socialMedia: {
            instagram: "https://instagram.com/davidphoto",
            facebook: "https://facebook.com/davidphoto"
        },
        website: "https://www.davidphoto.com",
        specialization: ["Sport", "Action"],
        awards: "Prix du Sport 2019",
        experience: "6 ans",
        testimonials: "David capture l'essence du sport dans chaque photo. - Client D",
        availability: "Disponible pour des projets en 2024.",
        location: "Nice, France",
        equipment: ["Canon EOS 1D X", "Adobe Lightroom"],
        priceRange: {
            hourly: "110€ - 220€",
            halfDay: "375€ - 750€",
            fullDay: "650€ - 1300€"
        }
    },
    {
        id: 105,
        title: "Emma Petit",
        url: "https://independant.io/wp-content/uploads/devenir-photographe-independant-1024x576.jpg",
        description: "Photographe de famille et de maternité.",
        portfolio: ["emma1.jpg", "emma2.jpg", "emma3.jpg"],
        email: "emma@example.com",
        phone: "567-890-1234",
        socialMedia: {
            instagram: "https://instagram.com/emmaphoto",
            facebook: "https://facebook.com/emmaphoto"
        },
        website: "https://www.emmaphoto.com",
        specialization: ["Famille", "Maternité"],
        awards: "Photographe de Famille 2018",
        experience: "5 ans",
        testimonials: "Emma a su capturer des moments précieux de notre famille. - Client E",
        availability: "Disponible pour des projets en 2023.",
        location: "Bordeaux, France",
        equipment: ["Fujifilm X-T4", "Adobe Photoshop"],
        priceRange: {
            hourly: "90€ - 180€",
            halfDay: "320€ - 640€",
            fullDay: "550€ - 1100€"
        }
    },
    {
        id: 106,
        title: "Felix Dubois",
        url: "https://www.studycdn.space/sites/default/files/metier/2022-07/arton870.png",
        description: "Expert en photographie d'architecture.",
        portfolio: ["felix1.jpg", "felix2.jpg", "felix3.jpg"],
        email: "felix@example.com",
        phone: "678-901-2345",
        socialMedia: {
            instagram: "https://instagram.com/felixphoto",
            facebook: "https://facebook.com/felixphoto"
        },
        website: "https://www.felixphoto.com",
        specialization: ["Architecture", "Immobilier"],
        awards: "Prix de l'Architecture 2017",
        experience: "10 ans",
        testimonials: "Les photos de Felix subliment les bâtiments. - Client F",
        availability: "Disponible pour des projets en 2024.",
        location: "Toulouse, France",
        equipment: ["Nikon Z7", "Luminar"],
        priceRange: {
            hourly: "130€ - 260€",
            halfDay: "450€ - 900€",
            fullDay: "750€ - 1500€"
        }
    },
    {
        id: 107,
        title: "Gabrielle Moreau",
        url: "https://local-fr-public.s3.eu-west-3.amazonaws.com/prod/webtool/userfiles/34346/REFONTE/qui-suis-je1.jpg",
        description: "Photographe événementielle avec une touche créative.",
        portfolio: ["gabrielle1.jpg", "gabrielle2.jpg", "gabrielle3.jpg"],
        email: "gabrielle@example.com",
        phone: "789-012-3456",
        socialMedia: {
            instagram: "https://instagram.com/gabriellephoto",
            facebook: "https://facebook.com/gabriellephoto"
        },
        website: "https://www.gabriellephoto.com",
        specialization: ["Événementiel", "Corporate"],
        awards: "Prix de l'Événement 2020",
        experience: "8 ans",
        testimonials: "Gabrielle capture l'essence des événements. - Client G",
        availability: "Disponible pour des projets en 2024.",
        location: "Lille, France",
        equipment: ["Canon EOS R", "Adobe Lightroom"],
        priceRange: {
            hourly: "100€ - 200€",
            halfDay: "350€ - 700€",
            fullDay: "600€ - 1200€"
        }
    },
    {
        id: 108,
        title: "Henri Lambert",
        url: "https://cc-prod.scene7.com/is/image/CCProdAuthor/what-is-videography_P1_900x420?$pjpeg$&jpegSize=200&wid=900",
        description: "Photographe animalier passionné par la nature.",
        portfolio: ["henri1.jpg", "henri2.jpg", "henri3.jpg"],
        email: "henri@example.com",
        phone: "890-123-4567",
        socialMedia: {
            instagram: "https://instagram.com/henriphoto",
            facebook: "https://facebook.com/henriphoto"
        },
        website: "https://www.henriphoto.com",
        specialization: ["Animalier", "Nature"],
        awards: "Prix de la Nature 2021",
        experience: "12 ans",
        testimonials: "Les photos animalières de Henri sont exceptionnelles. - Client H",
        availability: "Disponible pour des projets en 2023.",
        location: "Strasbourg, France",
        equipment: ["Sony A9", "Adobe Photoshop"],
        priceRange: {
            hourly: "110€ - 220€",
            halfDay: "400€ - 800€",
            fullDay: "700€ - 1400€"
        }
    },
    {
        id: 109,
        title: "Isabelle Girard",
        url: "https://images.unsplash.com/photo-1619479674806-8f6e70be07da?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        description: "Photographe culinaire avec un œil pour les détails.",
        portfolio: ["isabelle1.jpg", "isabelle2.jpg", "isabelle3.jpg"],
        email: "isabelle@example.com",
        phone: "901-234-5678",
        socialMedia: {
            instagram: "https://instagram.com/isabellephoto",
            facebook: "https://facebook.com/isabellephoto"
        },
        website: "https://www.isabellephoto.com",
        specialization: ["Culinaire", "Produit"],
        awards: "Prix Culinaire 2019",
        experience: "9 ans",
        testimonials: "Les photos culinaires d'Isabelle donnent vraiment envie de déguster. - Client I",
        availability: "Disponible pour des projets en 2024.",
        location: "Rennes, France",
        equipment: ["Canon EOS R6", "Adobe Lightroom"],
        priceRange: {
            hourly: "100€ - 200€",
            halfDay: "350€ - 700€",
            fullDay: "600€ - 1200€"
        }
    }
]


const pricePerBed = 50; // Prix par lit en EUR

export const hosts = [
    {
        id: 1,
        name: "Anne Martin",
        url: "https://images.ctfassets.net/guen72jxl4tk/3VPp6byeYG4NGb5eVKge8q/b90e75f2d1567fe7b59695d36b65fe2a/home.jpg?w=1920&q=80",
        bio: "Hôte accueillante avec une expérience de 5 ans dans la gestion de locations de vacances.",
        properties: ["Appartement cosy au centre-ville", "Maison avec vue sur la mer"],
        specialization: ["Locations de vacances", "Accueil personnalisé"],
        awards: ["Superhost Airbnb 2020"],
        experience: "5 ans",
        testimonials: ["Anne est une hôte formidable, toujours prête à aider. - Client A"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Petit-déjeuner", "Parking gratuit"],
        rates: {
            daily: 100,
            weekly: 600,
            monthly: 2000,
            roomPerBed: 3 * pricePerBed // 3 lits
        },
        beds: 3,
        pools: 1,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 150,
        location: {
            city: "Paris",
            address: "123 Rue de Rivoli",
            details: "Louvre Opéra Garnier"
        },
        totalRooms: 20,
        availableRooms: 11,
        initialPrice: 50000
    },
    {
        id: 2,
        name: "Bruno Lefevre",
        url: "https://images.trvl-media.com/lodging/29000000/28970000/28964400/28964319/f3ed8bd9.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
        bio: "Hôte spécialisé dans les séjours de courte durée en ville.",
        properties: ["Studio moderne proche des transports", "Loft design en plein cœur de la ville"]
        ,
        totalRooms: 42,
        availableRooms: 21,
        initialPrice: 5000,
        specialization: ["Séjours urbains", "Business travel"],
        awards: ["Top Host Booking.com 2019"],
        experience: "4 ans",
        testimonials: ["Bruno a rendu notre séjour en ville inoubliable. - Client B"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Cuisine équipée", "Transports à proximité"],
        rates: {
            daily: 120,
            weekly: 700,
            monthly: 2500,
            roomPerBed: 2 * pricePerBed // 2 lits
        },
        beds: 2,
        pools: 0,
        breakfastIncluded: false,
        parkingIncluded: false,
        numberOfReviews: 200,
        location: {
            city: "Lyon",
            address: "45 Rue de la République",
            details: "Place Bellecour Vieux Lyon"
        }
    },
    {
        id: 3,
        name: "Claire Dupuis",
        url: "https://www.hotel-lechapitre.com/bases/diaporama_image/grande/19/Chapitre_LM_780_2071.jpg",
        bio: "Passionnée par l'accueil des voyageurs dans des demeures historiques.",
        properties: ["Château du 18ème siècle", "Maison de maître rénovée"],
        totalRooms: 82,
        availableRooms: 51,
        initialPrice: 5000,
        website: "https://www.clairehost.com",
        specialization: ["Demeures historiques", "Séjours de charme"],
        awards: ["Prix de l'Accueil 2021"],
        experience: "6 ans",
        testimonials: ["Claire nous a fait sentir comme des rois dans son château. - Client C"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Petit-déjeuner inclus", "Visites guidées", "Piscine"],
        rates: {
            daily: 200,
            weekly: 1200,
            monthly: 4000,
            roomPerBed: 10 * pricePerBed // 10 lits
        },
        beds: 10,
        pools: 1,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 120,
        location: {
            city: "Bordeaux",
            address: "78 Rue du Château",
            details: "Place de la Bourse Cité du Vin"
        }
    },
    {
        id: 4,
        name: "David Morel",
        url: "https://toohotel.com/wp-content/uploads/2022/09/TOO_Hotel_Suite_Bathroom_Panoramique.jpg",
        bio: "Expert en locations de vacances en bord de mer.",
        properties: ["Villa avec accès direct à la plage", "Appartement avec vue sur l'océan"],
        totalRooms: 42,
        availableRooms: 16,
        initialPrice: 10000,
        website: "https://www.davidhost.com",
        specialization: ["Bord de mer", "Vacances en famille"],
        awards: ["Host of the Year 2020"],
        experience: "7 ans",
        testimonials: ["David a rendu notre séjour en bord de mer mémorable. - Client D"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Piscine", "Plage privée"],
        rates: {
            daily: 150,
            weekly: 900,
            monthly: 3000,
            roomPerBed: 5 * pricePerBed // 5 lits
        },
        beds: 5,
        pools: 2,
        breakfastIncluded: false,
        parkingIncluded: true,
        numberOfReviews: 180,
        location: {
            city: "Nice",
            address: "12 Avenue des Plages",
            details: "Promenade des Anglais Vieux Nice"
        }
    },
    {
        id: 5,
        name: "Olivia Renault",
        url: "https://www.lemontrachet.com/assets/gallery/chambre-classique-2-le-montrachet-a-meursault-restaurant-et-hotel-5-etoiles-1336088.jpg",
        bio: "Hôte proposant des séjours dans des hôtels de luxe.",
        properties: ["Hôtel 5 étoiles avec restaurant gastronomique", "Suites de luxe avec vue sur les vignobles"],
        totalRooms: 28,
        availableRooms: 55,
        initialPrice: 25000,
        specialization: ["Hôtels de luxe", "Expériences gastronomiques"],
        awards: ["Luxury Hotel Award 2020"],
        experience: "8 ans",
        testimonials: ["Olivia nous a offert un séjour gastronomique inoubliable. - Client O"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Restaurant étoilé", "Cave à vin"],
        rates: {
            daily: 350,
            weekly: 2000,
            monthly: 7000,
            roomPerBed: 2 * pricePerBed // 2 lits
        },
        beds: 2,
        pools: 1,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 180,
        location: {
            city: "Meursault",
            address: "10 Rue du Moulin Foulot",
            details: "Vignobles de Bourgogne Château de Meursault"
        }
    },
    {
        id: 6,
        name: "Emma Bernard",
        url: "https://www.lourdes-infotourisme.com/app/uploads/lourdes/2021/10/thumbs/chambre-hotel-1920x960-crop-1635427113.jpg",
        bio: "Hôte spécialisée dans les séjours à la campagne.",
        properties: ["Gîte rural avec jardin", "Ferme rénovée avec animaux"],
        totalRooms: 802,
        availableRooms: 51,
        initialPrice: 9000,
        specialization: ["Séjours à la campagne", "Écotourisme"],
        awards: ["Eco-friendly Host 2019"],
        experience: "6 ans",
        testimonials: ["Emma nous a offert un séjour paisible à la campagne. - Client E"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Petit-déjeuner bio", "Jardin"],
        rates: {
            daily: 90,
            weekly: 550,
            monthly: 1800,
            roomPerBed: 4 * pricePerBed // 4 lits
        },
        beds: 4,
        pools: 1,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 220,
        location: {
            city: "Dijon",
            address: "34 Rue des Vignes",
            details: "Côte de Nuits Jardin Darcy"
        }
    },
    {
        id: 7,
        name: "Lucas Laurent",
        url: "https://www.hotel-lechapitre.com/bases/diaporama_image/grande/19/Chapitre_LM_780_2071.jpg",
        bio: "Hôte proposant des séjours de luxe en montagne.",
        properties: ["Chalet avec spa", "Appartement avec vue sur les Alpes"],
        totalRooms: 52,
        availableRooms: 95,
        initialPrice: 45000,
        specialization: ["Séjours en montagne", "Hébergements de luxe"],
        awards: ["Top Mountain Host 2021"],
        experience: "5 ans",
        testimonials: ["Lucas nous a offert un séjour luxueux en montagne. - Client L"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Spa", "Balcon avec vue"],
        rates: {
            daily: 180,
            weekly: 1000,
            monthly: 3500,
            roomPerBed: 3 * pricePerBed // 3 lits
        },
        beds: 3,
        pools: 1,
        breakfastIncluded: false,
        parkingIncluded: true,
        numberOfReviews: 160,
        location: {
            city: "Chamonix",
            address: "50 Rue des Alpes",
            details: "Mont Blanc Mer de Glace"
        }
    },
    {
        id: 8,
        name: "Sophie Caron",
        url: "https://toohotel.com/wp-content/uploads/2022/09/TOO_Hotel_Suite_Bathroom_Panoramique.jpg",
        bio: "Hôte spécialisée dans les séjours culturels.",
        properties: ["Appartement proche des musées", "Maison avec bibliothèque"],
        totalRooms: 882,
        availableRooms: 501,
        initialPrice: 55000,
        specialization: ["Séjours culturels", "Visites guidées"],
        awards: ["Cultural Host 2022"],
        experience: "4 ans",
        testimonials: ["Sophie a enrichi notre séjour avec des activités culturelles passionnantes. - Client S"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Bibliothèque", "Guide touristique"],
        rates: {
            daily: 110,
            weekly: 650,
            monthly: 2200,
            roomPerBed: 2 * pricePerBed // 2 lits
        },
        beds: 2,
        pools: 0,
        breakfastIncluded: true,
        parkingIncluded: false,
        numberOfReviews: 140,
        location: {
            city: "Avignon",
            address: "23 Rue des Arts",
            details: "Palais des Papes Pont d'Avignon"
        }
    },
    {
        id: 9,
        name: "Julien Moreau",
        url: "https://www.hotel-lechapitre.com/bases/diaporama_image/grande/19/Chapitre_LM_780_2071.jpg",
        bio: "Hôte spécialisé dans les escapades romantiques.",
        properties: ["Cottage isolé", "Appartement avec jacuzzi"],
        totalRooms: 12,
        availableRooms: 2,
        initialPrice: 4000,
        specialization: ["Escapades romantiques", "Hébergements intimes"],
        awards: ["Romantic Getaway Host 2021"],
        experience: "7 ans",
        testimonials: ["Julien a fait de notre escapade romantique un rêve devenu réalité. - Client J"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Jacuzzi", "Cheminée"],
        rates: {
            daily: 130,
            weekly: 750,
            monthly: 2600,
            roomPerBed: 1 * pricePerBed // 1 lit
        },
        beds: 1,
        pools: 0,
        breakfastIncluded: false,
        parkingIncluded: true,
        numberOfReviews: 100,
        location: {
            city: "Annecy",
            address: "67 Rue des Amoureux",
            details: "Lac d'Annecy Vieille Ville"
        }
    },
    {
        id: 10,
        name: "Marie Petit",
        url: "https://toohotel.com/wp-content/uploads/2022/09/TOO_Hotel_Suite_Bathroom_Panoramique.jpg",
        bio: "Hôte proposant des séjours pour les amateurs de vin.",
        properties: ["Domaine viticole", "Maison avec cave à vin"],
        totalRooms: 2,
        availableRooms: 1,
        initialPrice: 5000,
        specialization: ["Oenotourisme", "Dégustations de vin"],
        awards: ["Wine Lover Host 2022"],
        experience: "5 ans",
        testimonials: ["Marie nous a offert une expérience œnologique exceptionnelle. - Client M"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Dégustation de vin", "Visite du vignoble"],
        rates: {
            daily: 160,
            weekly: 900,
            monthly: 3200,
            roomPerBed: 4 * pricePerBed // 4 lits
        },
        beds: 4,
        pools: 1,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 180,
        location: {
            city: "Beaune",
            address: "88 Rue des Vignes",
            details: "Hospices de Beaune Route des Grands Crus"
        }
    },
    {
        id: 11,
        name: "Paul Girard",
        url: "https://www.hotel-lechapitre.com/bases/diaporama_image/grande/19/Chapitre_LM_780_2071.jpg",
        bio: "Hôte spécialisé dans les séjours sportifs.",
        properties: ["Maison avec salle de sport", "Appartement proche des pistes de ski"],
        totalRooms: 182,
        availableRooms: 151,
        initialPrice: 25000,
        specialization: ["Séjours sportifs", "Activités outdoor"],
        awards: ["Active Host 2020"],
        experience: "6 ans",
        testimonials: ["Paul nous a permis de profiter d'un séjour sportif complet. - Client P"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Salle de sport", "Équipement sportif"],
        rates: {
            daily: 140,
            weekly: 800,
            monthly: 2800,
            roomPerBed: 3 * pricePerBed // 3 lits
        },
        beds: 3,
        pools: 1,
        breakfastIncluded: false,
        parkingIncluded: true,
        numberOfReviews: 120,
        location: {
            city: "Grenoble",
            address: "34 Rue des Montagnes",
            details: "Alpes Françaises Parc Mistral"
        }
    },
    {
        id: 12,
        name: "Nathalie Blanc",
        url: "https://toohotel.com/wp-content/uploads/2022/09/TOO_Hotel_Suite_Bathroom_Panoramique.jpg",
        bio: "Hôte proposant des séjours bien-être.",
        properties: ["Retraite avec spa", "Maison de bien-être avec sauna"],
        totalRooms: 182,
        availableRooms: 151,
        initialPrice: 5000,
        specialization: ["Bien-être", "Relaxation"],
        awards: ["Wellness Host 2021"],
        experience: "4 ans",
        testimonials: ["Nathalie nous a offert un séjour de relaxation ultime. - Client N"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Spa", "Sauna"],
        rates: {
            daily: 170,
            weekly: 950,
            monthly: 3400,
            roomPerBed: 2 * pricePerBed // 2 lits
        },
        beds: 2,
        pools: 1,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 140,
        location: {
            city: "Aix-les-Bains",
            address: "23 Rue des Thermes",
            details: "Lac du Bourget Mont Revard"
        }
    },
    {
        id: 13,
        name: "Louis Morel",
        url: "https://www.hotel-lechapitre.com/bases/diaporama_image/grande/19/Chapitre_LM_780_2071.jpg",
        bio: "Hôte spécialisé dans les séjours d'aventure.",
        properties: ["Cabane dans les arbres", "Bungalow au bord de la rivière"],
        totalRooms: 982,
        availableRooms: 151,
        initialPrice: 55000,
        specialization: ["Aventure", "Nature"],
        awards: ["Adventure Host 2021"],
        experience: "5 ans",
        testimonials: ["Louis nous a offert un séjour d'aventure en pleine nature. - Client L"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Équipement de camping", "Canoë"],
        rates: {
            daily: 100,
            weekly: 600,
            monthly: 2200,
            roomPerBed: 3 * pricePerBed // 3 lits
        },
        beds: 3,
        pools: 0,
        breakfastIncluded: false,
        parkingIncluded: true,
        numberOfReviews: 150,
        location: {
            city: "Nantes",
            address: "45 Rue des Explorateurs",
            details: "Vallée de la Loire Parc des Chantiers"
        }
    },
    {
        id: 14,
        name: "Chloé Dubois",
        url: "https://toohotel.com/wp-content/uploads/2022/09/TOO_Hotel_Suite_Bathroom_Panoramique.jpg",
        bio: "Hôte proposant des séjours gastronomiques.",
        properties: ["Maison avec cuisine de chef", "Appartement avec terrasse et barbecue"],
        totalRooms: 582,
        availableRooms: 551,
        initialPrice: 5000,
        specialization: ["Gastronomie", "Cuisine locale"],
        awards: ["Gastronomy Host 2020"],
        experience: "6 ans",
        testimonials: ["Chloé nous a offert un séjour gastronomique de rêve. - Client C"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Cuisine équipée", "Barbecue"],
        rates: {
            daily: 130,
            weekly: 750,
            monthly: 2700,
            roomPerBed: 2 * pricePerBed // 2 lits
        },
        beds: 2,
        pools: 0,
        breakfastIncluded: true,
        parkingIncluded: true,
        numberOfReviews: 160,
        location: {
            city: "Toulouse",
            address: "67 Rue des Gourmands",
            details: "Capitole Jardin des Plantes"
        }
    },
    {
        id: 15,
        name: "Elodie Lefevre",
        url: "https://www.hotel-lechapitre.com/bases/diaporama_image/grande/19/Chapitre_LM_780_2071.jpg",
        bio: "Hôte spécialisée dans les séjours artistiques.",
        properties: ["Loft avec atelier d'artiste", "Appartement avec galerie d'art"],
        totalRooms: 382,
        availableRooms: 151,
        initialPrice: 5000,
        specialization: ["Art", "Création"],
        awards: ["Artistic Host 2021"],
        experience: "5 ans",
        testimonials: ["Elodie nous a inspiré avec son espace artistique. - Client E"],
        availability: "Propriétés disponibles toute l'année.",
        amenities: ["Wi-Fi", "Atelier", "Galerie"],
        rates: {
            daily: 140,
            weekly: 800,
            monthly: 2900,
            roomPerBed: 1 * pricePerBed // 1 lit
        },
        beds: 1,
        pools: 0,
        breakfastIncluded: false,
        parkingIncluded: true,
        numberOfReviews: 130,
        location: {
            city: "Paris",
            address: "89 Rue des Artistes",
            details: "Montmartre Musée d'Orsay"
        }
    }
];


export const tourist = [
    {
        nom: "Tour Eiffel",
        emplacement: "Paris, France",
        description: "La Tour Eiffel est une célèbre tour en fer située au cœur de Paris. Symbole emblématique de la France, elle offre une vue panoramique imprenable sur la ville.",
        attractions: ["Plateforme d'observation", "Restaurant gastronomique", "Boutiques de souvenirs"],
        activites: ["Ascension de la tour", "Pique-nique dans les jardins", "Croisière sur la Seine"],
        prix: "12€ pour l'ascension",
        recommandations: "Visitez la Tour Eiffel au coucher du soleil pour profiter d'une vue magnifique sur Paris illuminé."
    },
    {
        nom: "Machu Picchu",
        emplacement: "Cusco, Pérou",
        description: "Le Machu Picchu est une ancienne cité inca perchée sur une montagne des Andes. C'est l'un des sites archéologiques les plus spectaculaires et mystérieux du monde.",
        attractions: ["Citadelle inca", "Sentiers de randonnée", "Vue panoramique sur les montagnes"],
        activites: ["Randonnée sur le Chemin de l'Inca", "Exploration des ruines", "Observation de la faune et de la flore"],
        prix: "152 soles péruviens (environ 40€) pour l'entrée",
        recommandations: "Planifiez votre visite tôt le matin pour éviter la foule et profiter d'une atmosphère plus calme."
    },
    {
        nom: "Grand Canyon",
        emplacement: "Arizona, États-Unis",
        description: "Le Grand Canyon est un immense canyon sculpté par le fleuve Colorado. C'est l'une des merveilles naturelles les plus impressionnantes du monde, offrant des paysages à couper le souffle.",
        attractions: ["Points de vue panoramiques", "Randonnées", "Excursions en hélicoptère"],
        activites: ["Randonnée sur les sentiers", "Rafting dans le fleuve Colorado", "Observation de la faune et de la flore"],
        prix: "30$ pour le pass d'entrée par véhicule",
        recommandations: "Prévoyez de passer une nuit sur place pour admirer le lever ou le coucher du soleil sur le canyon."
    },
    {
        nom: "Tour de Pise",
        emplacement: "Pise, Italie",
        description: "La Tour de Pise est une tour campanile penchée située dans la ville de Pise en Italie. C'est l'un des sites les plus célèbres du pays, connu pour son inclinaison unique.",
        attractions: ["Tour penchée", "Cathédrale de Pise", "Baptistère"],
        activites: ["Montée jusqu'au sommet de la tour", "Visite des monuments historiques", "Dégustation de spécialités locales"],
        prix: "18€ pour l'accès à la tour",
        recommandations: "N'oubliez pas de prendre une photo amusante où vous semblez tenir la tour pour la redresser!"
    },
    {
        nom: "Château de Versailles",
        emplacement: "Versailles, France",
        description: "Le Château de Versailles est un palais somptueux situé à proximité de Paris. Il est renommé pour son architecture époustouflante, ses jardins à la française et son histoire liée à la monarchie française.",
        attractions: ["Appartements royaux", "Galerie des Glaces", "Jardins de Versailles"],
        activites: ["Visite guidée du château", "Promenade dans les jardins", "Spectacle des Grandes Eaux"],
        prix: "20€ pour l'accès complet (château et jardins)",
        recommandations: "Assistez au spectacle des Grandes Eaux Musicales pour voir les fontaines du jardin danser en musique."
    },
    {
        nom: "Pyramides de Gizeh",
        emplacement: "Le Caire, Égypte",
        description: "Les Pyramides de Gizeh sont des structures monumentales construites il y a des milliers d'années. Elles sont considéréescomme l'une des Sept Merveilles du monde antique et continuent d'étonner les visiteurs par leur taille et leur mystère.",
        attractions: ["Grande Pyramide de Khéops", "Pyramide de Khéphren", "Pyramide de Mykérinos"],
        activites: ["Exploration des pyramides", "Visite du Sphinx", "Croisière sur le Nil"],
        prix: "200 livres égyptiennes (environ 10€) pour l'entrée",
        recommandations: "Embauchez un guide local pour en apprendre davantage sur l'histoire et les secrets des pyramides."
    },
    {
        nom: "Grande Muraille de Chine",
        emplacement: "Chine",
        description: "La Grande Muraille de Chine est une structure défensive ancienne qui s'étend sur des milliers de kilomètres à travers la Chine. C'est l'une des réalisations architecturales les plus impressionnantes de l'histoire.",
        attractions: ["Sections de la muraille", "Tour de guet", "Vue panoramique sur les montagnes"],
        activites: ["Randonnée sur la muraille", "Photographie panoramique", "Découverte des forts et des tours de guet"],
        prix: "60 yuans chinois (environ 8€) pour l'entrée",
        recommandations: "Prévoyez suffisamment de temps pour explorer les vastes jardins et les multiples palais de la Cité interdite."
    },
    {
        nom: "Chichen Itza",
        emplacement: "Yucatan, Mexique",
        description: "Chichen Itza est une ancienne cité maya située dans la péninsule du Yucatan, au Mexique. Elle abrite des pyramides, des temples et d'autres structures fascinantes qui témoignent de la grandeur de la civilisation maya.",
        attractions: ["Pyramide de Kukulcan", "Terrain de jeu de pelote", "Cénote sacré"],
        activites: ["Exploration des ruines", "Spectacle son et lumière", "Baignade dans un cénote"],
        prix: "481 pesos mexicains (environ 20€) pour l'entrée",
        recommandations: "Assistez au spectacle son et lumière en soirée pour admirer Chichen Itza sous un éclairage spectaculaire et en apprendre davantage sur son histoire."
    },
    {
        nom: "Acropole d'Athènes",
        emplacement: "Athènes, Grèce",
        description: "L'Acropole d'Athènes est une ancienne citadelle située sur une colline surplombant la ville d'Athènes. Elle abrite des temples antiques, dont le célèbre Parthénon, ainsi que d'autres monuments historiques.",
        attractions: ["Parthénon", "Temple d'Athéna Niké", "Théâtre de Dionysos"],
        activites: ["Visite guidée de l'Acropole", "Découverte des temples et des monuments", "Vue panoramique sur Athènes"],
        prix: "20€ pour l'entrée à l'Acropole",
        recommandations: "Montez à l'Acropole tôt le matin pour éviter la chaleur et la foule, et pour bénéficier d'une vue imprenable sur la ville d'Athènes."
    },
    {
        nom: "Colisée de Rome",
        emplacement: "Rome, Italie",
        description: "Le Colisée est un amphithéâtre romain emblématique situé au cœur de la ville de Rome. Il est connu pour son architecture impressionnante et son histoire liée aux jeux de gladiateurs de l'Empire romain.",
        attractions: ["Arènes du Colisée", "Souterrains et couloirs", "Expositions sur l'histoire romaine"],
        activites: ["Visite guidée du Colisée", "Promenade dans le Forum romain", "Dégustation de la cuisine italienne"],
        prix: "16€ pour l'entrée au Colisée, au Forum romain et au mont Palatin",
        recommandations: "Optez pour une visite guidée pour en apprendre davantage sur l'histoire fascinante du Colisée et pour accéder à des zones généralement fermées au public."
    }
];




  export  const notifications = [
    {
    id: 1,
    titre: 'Nouveau Notifcation',
    description: 'Vous avez reçu un nouveau Notifcation de John Doe.',
    date: '13 juin 2024'
    },
    {
    id: 2,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 3,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 4,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 5,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 6,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 7,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 8,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 9,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 10,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 11,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 12,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 13,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 14,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    },
    {
    id: 15,
    titre: 'Nouvelle demande d\'ami',
    description: 'Jane Smith souhaite devenir votre amie.',
    date: '14 juin 2024'
    }
    ];