/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Popover, Sidenav, Whisper } from 'rsuite'
import { useThemes } from '../../../UserContext/UserContext';

function Notifica() {
    const { showNotifcation, GetShowNotifcation, notifData, GetNotiData } = useThemes()

    const hundleDelete = (id) => {
        const newNotefi = notifData.filter((not) => not.id != id)
        GetNotiData(newNotefi)
    }



    return (
        <>
            {showNotifcation &&
                <div className='flex bg-[rgba(0,0,0,0.12)] z-[1000]  fixed top-0 left-0 w-full h-[100vh] justify-end '>
                    <div className='h-[100vh] border-l-2  border-[#5dca32] w-[25em]'>
                        <Sidenav>
                            <div className="h-[9vh] px-1 bg-[#5dca32] relative overflow-hidden flex justify-between items-center font-serif text-white text-[18px] w-full text-center">
                                <div onClick={() => GetShowNotifcation(false)} className={`   cursor-pointer  h-[95%]  flex justify-center items-center`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi rounded bg-white text-[#5dca32] bi-arrow-left-short" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                                    </svg>
                                </div>
                                <div>
                                    Tous les notification
                                </div>
                                <Whisper
                                    trigger="hover"
                                    placement='auto'
                                    speaker={
                                        <Popover>
                                            <span> Clique pour effacer  tous les notifications</span>
                                        </Popover>
                                    }
                                >
                                    <div onClick={() => GetNotiData([])} className={`cursor-pointer  h-[95%]  flex justify-center items-center`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi p-1 rounded text-red-600 bg-white bi-trash3-fill" viewBox="0 0 16 16">
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                    </div>
                                </Whisper>

                            </div>
                            <div className='overflow-x-hidden overflow-y-auto h-[91vh]'>
                                {notifData.map((notification, index) => (
                                    <div key={index} className="border-t-[1px] p-2">
                                        <div className=' flex w-full justify-between'>
                                            <div className="font-bold">{notification.titre}</div>
                                            <div onClick={() => hundleDelete(notification.id)} className="hover:bg-red-600  text-red-600 flex justify-center items-center  transition-all cursor-pointer rounded-full hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text-gray-500 text-[15px] pl-2">{notification.description}</div>
                                        <div className="text-[10px] text-gray-500 w-full text-end">{notification.date}</div>
                                    </div>
                                ))}
                                {notifData.length == 0 &&
                                    <div className='w-full text-gray-500 flex-col h-full flex justify-center items-center'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-bell-slash-fill" viewBox="0 0 16 16">
                                                <path d="M5.164 14H15c-1.5-1-2-5.902-2-7q0-.396-.06-.776zm6.288-10.617A5 5 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5 5 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z" />
                                            </svg>
                                        </div>
                                        <div className='mt-5'>
                                            Aucun notification
                                        </div>
                                    </div>}
                            </div>
                        </Sidenav>
                    </div>
                </div>
            }
        </>
    )
}

export default Notifica



