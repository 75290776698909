/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useThemes } from '../../../UserContext/UserContext';
import NavBarsImmobilier from "./NavBarsImmobilier";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FootentContent from "../FootentContent/FootentContent";
import { Modal, Popover, Rate, Whisper } from "rsuite";
import ClientPhotgraphie from "./ClientPhotgraphie";
import Carousel from "react-multi-carousel";
import QrCode from "./QrCode";


const Image = styled.img.attrs((props) => ({
    src: props.source,
}))`
  width: 100%;
    height: 100%;

`;

const Target = styled(Image)`
  position: absolute;
  left: ${(props) => props.offset.left}px;
  top: ${(props) => props.offset.top}px;
  opacity: ${(props) => props.opacity};
  transform: scale(1.7);
  transform-origin: top left;
  cursor:grab;

`;

function DetailProduits() {

    const commentaires = [
        {
            id: 1,
            profil: 'https://img.lignes-formations.com/wp-content/uploads/sites/45/2019/05/metier-photographe-professionnel.jpg',
            nom: 'KEZA KIMANA Elyvine',
            date: '2022-05-15',
            contenu: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
            id: 2,
            profil: 'https://img.lignes-formations.com/wp-content/uploads/sites/45/2019/05/metier-photographe-professionnel.jpg',
            nom: 'KANA Vestine',
            date: '2022-06-03',
            contenu: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
        },
    ];

    const { currentAmount, produit, isDark, AjoutApnier, GetproduitDetail, produitDetail, HundlwScrollTop, amounts, increaseAmount, decreaseAmount, GetShowMessage, isLivraisonSelected, setIsLivraisonSelected } = useThemes();

    const [desable, Setdesable] = useState(false)
    const [ShowComment, SetShowComment] = useState(false)


    const navig = useNavigate()
    const sourceRef = useRef(null);
    const targetRef = useRef(null);
    const containerRef = useRef(null);

    const [opacity, setOpacity] = useState(0);
    const [offset, setOffset] = useState({ left: 0, top: 0 });
    if (amounts == 0) {
        navig('/Immobilier')
    }


    const handleMouseEnter = () => {
        setOpacity(1);
    };

    const handleMouseLeave = () => {
        setOpacity(0);
    };

    const handleMouseMove = (e) => {
        const targetRect = targetRef.current.getBoundingClientRect();
        const sourceRect = sourceRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
        const yRatio = (targetRect.height - containerRect.height) / sourceRect.height;

        const left = Math.max(
            Math.min(e.pageX - sourceRect.left, sourceRect.width),
            0
        );
        const top = Math.max(
            Math.min(e.pageY - sourceRect.top, sourceRect.height),
            0
        );

        setOffset({
            left: left * -xRatio,
            top: top * -yRatio,
        });
    };

    const [isloading, SetIsLoading] = useState(true)
    const hundleLoading = () => {
        SetIsLoading(false)
    }

    useEffect(() => {
        Setdesable(false)
    }, [produitDetail.qty])

    const [showAbonn, SetShowAbonn] = useState(false)

    useEffect(() => {
        const hundleClick = () => {
            SetShowAbonn(false)
        }
        const hundleScoll = () => {
            SetShowAbonn(false)
        }
        const hundleSize = () => {
            SetShowAbonn(false)
        }

        window.addEventListener('click', hundleClick)
        window.addEventListener('scroll', hundleScoll)
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('click', hundleClick)
            window.removeEventListener('scroll', hundleScoll)
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const CustomRightArrow = ({ onClick }) => {
        return (
            <div className='absolute flex justify-center items-center z-[1000] right-5 h-full'>
                <button
                    onClick={onClick}
                    className=' bg-[#5dca32] border-none p-[5px] cursor-pointer text-white  rounded-full'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                </button>
            </div>
        );
    };

    const CustomLeftArrow = ({ onClick }) => {
        return (
            <div className='absolute flex justify-center items-center z-[1000] h-full'>
                <button
                    onClick={onClick}
                    className='bg-[#5dca32]  border-none p-[5px] cursor-pointer text-white left-1 rounded-full'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                    </svg>
                </button>
            </div>
        );
    };


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 11
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    const [ratings, setRatings] = useState([5, 5, 5, 4.5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

    const totalRatings = ratings.reduce((sum, rating) => sum + rating, 0);
    const calculateAverageRating = () => {
        const averageRating = totalRatings / ratings.length;
        return averageRating;
    };


    return (
        <div className="mt-[10vh]">
            <NavBarsImmobilier />
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/Immobilier")} className="text-[#5dca32] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div className="flex p-2">
                <div className="w-full flex">
                    <div className="w-1/2">
                        <div
                            className="relative w-full  rounded border border-[#5dca32] overflow-hidden  h-[80vh] "
                            ref={containerRef}
                            onMouseEnter={!produitDetail.experience ? handleMouseEnter : null}
                            onMouseLeave={!produitDetail.experience ? handleMouseLeave : null}
                            onMouseMove={!produitDetail.experience ? handleMouseMove : null}
                        >
                            <Image ref={sourceRef} onLoad={hundleLoading} alt="source" source={produitDetail.url} />
                            {!produitDetail.experience &&
                                <Target
                                    ref={targetRef}
                                    alt="target"
                                    opacity={opacity}
                                    offset={offset}
                                    source={produitDetail.url}
                                />
                            }
                            {isloading &&
                                <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}`}>
                                    <svg className="animate-spin h-10 w-10 text-[#5dca32]" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="green" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                    </svg>
                                </div>
                            }
                        </div>
                        {!produitDetail.experience &&
                            <div>
                                <div className='text-[18px] mt-2 pl-1'>Autre partie d'un produit</div>
                                <div className='flex items-center'>
                                    <div className='w-[100%] mt-1'>
                                        <Carousel
                                            customRightArrow={<CustomRightArrow />}
                                            customLeftArrow={<CustomLeftArrow />}
                                            responsive={responsive}
                                            containerClass="h-[5em] p-1"
                                        >
                                            {produit.map((data, index) => (
                                                <div data-position={index} key={index} onClick={() => { GetproduitDetail(data); HundlwScrollTop() }} className='m-1 p-1'>
                                                    <div >{data.name}</div>
                                                    <div className='h-[4em] w-[4em]'>
                                                        <img
                                                            onLoad={() => {
                                                                document.querySelector(`[data-position="${index}"] .spinnerw`).style.display = 'none';
                                                            }}
                                                            src={data.url}
                                                            alt={data.name}
                                                            className='w-full h-full object-cover object-center' />
                                                        <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinnerw`}>
                                                            <svg
                                                                className="animate-spin h-8 w-8 text-[#5dca32]"
                                                                viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="w-1/2">
                        <div className="text-lg w-full pl-4 uppercase">{!produitDetail.experience ? 'Détail du produit' : 'Détail du photographe'}</div>
                        <div className="w-full pl-4 mt-4">
                            <div className="flex items-center justify-between">
                                <div className="text-[30px] text-nowrap overflow-hidden text-ellipsis font-bold">
                                    {produitDetail.title}
                                </div>
                                <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                    </svg>
                                    <div className='ml-0.5 text-lg pr-1'>{totalRatings}K</div>
                                </div>
                            </div>
                            {produitDetail.experience && <div className="mb-1 space-x-2 my-2 font-roboto">
                                <div>
                                    <div className="ml-[5em] text-[15px] flex items-center">
                                        <div >105M abonnées</div>
                                        <div className="text-[#5dca32] ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center pt-2 pl-10 relative" >
                                    <Whisper
                                        trigger='click'
                                        placement="left"
                                        open={showAbonn}
                                        speaker={
                                            <Popover>
                                                <div className="flex flex-col gap-2">
                                                    <div className=" flex items-center hover:bg-[#5dca32] transition all rounded hover:text-white cursor-pointer p-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                                                        </svg>
                                                        <div className="ml-2">Tous les notifications</div>
                                                    </div>
                                                    <div className=" flex items-center hover:bg-[#5dca32] transition all rounded hover:text-white cursor-pointer p-2">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell-slash-fill" viewBox="0 0 16 16">
                                                                <path d="M5.164 14H15c-1.5-1-2-5.902-2-7q0-.396-.06-.776zm6.288-10.617A5 5 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5 5 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z" />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-2">Aucun notification</div>
                                                    </div>
                                                    <div className=" flex items-center hover:bg-[#5dca32] transition all rounded hover:text-white cursor-pointer p-2">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill-dash" viewBox="0 0 16 16">
                                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1m0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-2">Se désabonner</div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <div onClick={(e) => { SetShowAbonn(true); e.stopPropagation() }} className="bg-fuchsia-700 cursor-pointer hover:bg-transparent mx-2 hover:text-fuchsia-700 hover:border hover:border-fuchsia-700 text-white font-bold py-2 px-4 rounded">
                                            S'abonner
                                        </div>
                                    </Whisper>
                                    <div onClick={() => GetShowMessage(true)} className="cursor-pointer bg-transparent mx-2 border  font-bold py-2 px-4 rounded">
                                        Écrire un message
                                    </div>
                                </div>
                            </div>

                            }
                            {produitDetail.category &&
                                <div className="font-semibold text-lg my-2 space-x-2">
                                    Categories : {produitDetail.category}
                                </div>
                            }
                            <div className="space-x-2 text-lg">
                                {produitDetail.description}
                            </div>
                            {produitDetail.experience && <>
                                <div className="mt-2 text-lg">
                                    Spécialisation : {produitDetail.specialization[0]}, {produitDetail.specialization[1]}
                                </div>
                                <div className="mt-2  text-lg">
                                    Expérience : {produitDetail.experience}
                                </div>
                                <div className="mt-2 text-lg">
                                    Échelle des prix : <br />
                                    <div className="pl-5">
                                        - {produitDetail.priceRange.hourly.split(" - ")[1]}  par heure <br />
                                        - {produitDetail.priceRange.halfDay.split(" - ")[1]}  par demi-journée <br />
                                        - {produitDetail.priceRange.fullDay.split(" - ")[1]}  par jour
                                    </div>
                                </div>
                            </>}
                            {produitDetail.qty &&
                                <div className="mt-2 text-lg">
                                    Disponibilité : {produitDetail.qty}  articles en stock
                                </div>
                            }
                            {produitDetail.delivery_address
                                &&
                                <div className="mt-2 text-lg">
                                    {produitDetail.delivery_address}
                                </div>
                            }
                            {produitDetail.price &&
                                <div className="flex items-baseline mb-1 space-x-2 font-roboto sm:mt-4">
                                    <div className="text-lg">Prix:</div>
                                    <div className={` md:text-lg text-[#5dca32] font-semibold`}>
                                        {produitDetail.price.toFixed(0)}Fbu
                                    </div>
                                    <div className={` md:text-base ml-3 text-right  text-red-400 line-through`}>
                                        {(produitDetail.price + (produitDetail.price * 20) / 100).toFixed(0)}Fbu
                                    </div>
                                </div>
                            }
                            {produitDetail.qty &&
                                <div className="sm:mt-4 mt-1">
                                    <h3 className="text-xs md:text-sm  mb-1">Quantites</h3>
                                    <div className="flex border border-gray-300 divide-x divide-gray-300 w-max">
                                        <button
                                            onClick={() => {
                                                if (currentAmount == 1) {
                                                    navig('/Immobilier')
                                                    Setdesable(false)
                                                } else {
                                                    decreaseAmount(produitDetail.id)
                                                    Setdesable(false)
                                                }
                                            }}
                                            class="h-6  w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none">
                                            -
                                        </button>
                                        <div className="h-6 w-6 md:h-8 md:w-8 text-base flex items-center justify-center">
                                            {currentAmount}
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (currentAmount < produitDetail.qty) {
                                                    increaseAmount(produitDetail.id)
                                                    Setdesable(false)
                                                }
                                                else {
                                                    Setdesable(true)
                                                    toast.warning("Vous avez atteint la quantité maximale disponible pour ce produit");
                                                }
                                            }}
                                            disabled={desable}
                                            className={`${desable ? 'opacity-30' : 'opacity-100'}  h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none`}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            }

                            {!produitDetail.experience &&
                                <div className={`flex ml-1 mt-2  pt-2 justify-start`}>
                                    <div onClick={() => AjoutApnier(produitDetail, produitDetail.id)} className={`px-4  py-2 flex  m-[1px] cursor-pointer justify-center items-center  text-center text-white bg-[#5dca32] border border-[#5dca32] rounded hover:bg-transparent hover:text-[#5dca32] transition-all w-max`}>
                                        <span> Ajouter au panier</span>
                                    </div>
                                    <div onClick={() => { navig('/Immobilier/card'); AjoutApnier(produitDetail, produitDetail.id) }} className={`px-4  py-2 flex  m-[1px] cursor-pointer justify-center items-center  text-center text-white bg-[#5dca32] border border-[#5dca32] rounded hover:bg-transparent hover:text-[#5dca32] transition-all w-max`}>
                                        Acheter maintenant
                                    </div>
                                </div>
                            }

                            <div className='text-[20px] mt-5'>Avis: ({calculateAverageRating().toFixed(2)})<Rate color="green" size="xs" value={calculateAverageRating()} readOnly />({totalRatings})</div>


                            {!produitDetail.experience && <div className="mb-1 space-x-2 my-2 font-roboto">
                                <div>
                                    <div className="flex">
                                        <div className="text-lg">Source:</div>
                                        <div className={` md:text-lg ml-2 font-semibold`}>
                                            NIYOMUKIZA Justin
                                        </div>
                                    </div>
                                    <div className="ml-[5em] text-[15px] flex items-center">
                                        <div >105M abonnées</div>
                                        <div className="text-[#5dca32] ml-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center pt-2 pl-10 relative" >
                                    <Whisper
                                        trigger='click'
                                        placement="top"
                                        open={showAbonn}
                                        speaker={
                                            <Popover>
                                                <div className="flex flex-col gap-2">
                                                    <div className=" flex items-center hover:bg-[#5dca32] transition all rounded hover:text-white cursor-pointer p-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                                                        </svg>
                                                        <div className="ml-2">Tous les notifications</div>
                                                    </div>
                                                    <div className=" flex items-center hover:bg-[#5dca32] transition all rounded hover:text-white cursor-pointer p-2">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell-slash-fill" viewBox="0 0 16 16">
                                                                <path d="M5.164 14H15c-1.5-1-2-5.902-2-7q0-.396-.06-.776zm6.288-10.617A5 5 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5 5 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z" />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-2">Aucun notification</div>
                                                    </div>
                                                    <div className=" flex items-center hover:bg-[#5dca32] transition all rounded hover:text-white cursor-pointer p-2">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill-dash" viewBox="0 0 16 16">
                                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1m0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-2">Se désabonner</div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <div onClick={(e) => { SetShowAbonn(true); e.stopPropagation() }} className="bg-fuchsia-700 cursor-pointer hover:bg-transparent mx-2 hover:text-fuchsia-700 hover:border hover:border-fuchsia-700 text-white font-bold py-2 px-4 rounded">
                                            S'abonner
                                        </div>
                                    </Whisper>
                                    <div onClick={() => GetShowMessage(true)} className="cursor-pointer bg-transparent mx-2 border  font-bold py-2 px-4 rounded">
                                        Écrire un message
                                    </div>
                                </div>
                            </div>

                            }



                            {produitDetail.experience &&
                                <div className={`flex ml-1 mt-2  pt-2 justify-start`}>
                                    <div onClick={() => setIsLivraisonSelected(true)} className={`px-4  py-2 flex  m-[1px] cursor-pointer justify-center items-center  text-center text-white bg-[#5dca32] border border-[#5dca32] rounded hover:bg-transparent hover:text-[#5dca32] transition-all w-max`}>
                                        <span> Contact</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-2 text-lg font-bold pl-2">
                    45M  Commentaires
                </div>
                <div className=" pl-2">
                    {commentaires.map((commentaire) => (
                        <div key={commentaire.id} className="flex items-start mb-4">
                            <img
                                src={commentaire.profil}
                                alt="Profil"
                                className="w-10 h-10 rounded-full mr-4"
                            />
                            <div>
                                <p className="font-bold">{commentaire.nom}</p>
                                <p className="text-gray-500">{commentaire.date}</p>
                                <p>{commentaire.contenu}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div onClick={() => SetShowComment(!ShowComment)} className="cursor-pointer w-max bg-transparent mx-2 border  font-bold py-2 px-4 rounded">
                    Commentaire
                </div>
                {ShowComment && <form class="m-4 ">
                    <textarea
                        class="w-full p-2 border outline-none min-h-[10em] bg-transparent border-green-300 rounded-lg"
                        placeholder="Ajouter un commentaire"></textarea>
                    <button type="button" class="mt-2 bg-[#5dca32] text-white px-4 py-2 rounded-lg" onclick="addTestimonial(this)">Ajouter un commentaire</button>
                </form>
                }
            </div>

            <div>
                <h2 className={`'text-gray-800'} text-base max-sm:text-center md:text-2xl font-bold py-4 ml-3`} >
                    {produitDetail.experience ? 'Autre photographe' : 'Produits similaires'}
                </h2>
                <div className='flex flex-wrap'>
                    {produitDetail.experience ? (
                        produit.filter(data => data.experience).map((data, index) => (
                            <div data-position={index} key={index} className='border w-[13.5em] rounded  m-1 overflow-hidden'>
                                <div className="relative w-full  h-max  overflow-hidden">
                                    <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className='cursor-pointer w-full h-[12em] relative  overflow-hidden'>
                                        <img className="w-full h-full object-cover  object-center"
                                            src={data.url}
                                            alt='   '
                                            onLoad={() => {
                                                document.querySelector(`[data-position="${index}"] .spinner`).style.display = 'none';
                                            }}
                                        />
                                        <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinner`}>
                                            <svg
                                                className="animate-spin h-8 w-8 text-[#5dca32]"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <div className='flex items-center justify-between'>
                                            <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className={` cursor-pointer font-bold text-[17px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px] sm:mb-2 first-letter:uppercase`}>
                                                {data.title}
                                            </div>
                                            <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                                </svg>
                                                <div className='ml-0.5'>100K</div>
                                            </div>
                                        </div>
                                        <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className='text-left cursor-pointer text-wrap text-[11px] '>
                                            {data.description && data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
                                        </div>
                                        <div className="text-[12px] font-thin">
                                            Expérience : {data.experience}
                                        </div>
                                        <div className="text-[12px] font-thin">
                                            Disponibilité : {data.availability}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        produit.filter(data => !data.experience).map((data, index) => (
                            <div data-position={index} key={index} className='border w-[13.5em] rounded  m-1 overflow-hidden'>
                                <div className="relative w-full  h-max  overflow-hidden">
                                    <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className='cursor-pointer w-full h-[12em] relative  overflow-hidden'>
                                        <img className="w-full h-full object-cover  object-center"
                                            src={data.url}
                                            alt='   '
                                            onLoad={() => {
                                                document.querySelector(`[data-position="${index}"] .spinner`).style.display = 'none';
                                            }}
                                        />
                                        <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinner`}>
                                            <svg
                                                className="animate-spin h-8 w-8 text-[#5dca32]"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        <div className='flex items-center justify-between'>
                                            <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className={` cursor-pointer font-bold text-[17px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px] sm:mb-2 first-letter:uppercase`}>
                                                {data.title}
                                            </div>
                                            <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                                </svg>
                                                <div className='ml-0.5'>100K</div>
                                            </div>
                                        </div>
                                        {data.price && <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className={`flex cursor-pointer justify-between  items-baseline sm:mb-1 sm:space-x-2`}>
                                            <div>Prix</div>
                                            <div className='flex items-center'>
                                                <div className={`text-[13px]  mr-1  text-[#5dca32] font-semibold`}>
                                                    {data.price.toFixed(0).toLocaleString()} fbu
                                                </div>
                                                <div className="text-[13px] text-right md:text-sm text-red-400 line-through">
                                                    {(data.price + (data.price * 20 / 100)).toFixed(0).toLocaleString()} fbu
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className='text-left cursor-pointer text-wrap text-[11px] '>
                                            {data.description && data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
                                        </div>
                                        {data.qty &&
                                            <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data) }} className="flex cursor-pointer items-baseline my-1 space-x-2">
                                                <p className={`text-[12px] font-thin`}>
                                                    Qauntite: {data.qty}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div onClick={() => AjoutApnier(data, data.id)} className='bg-[#5dca3228] transition-all hover:bg-[#5dca3250] text-[#5dca32] font-bold w-full text-center p-2 cursor-pointer'>Ajouter un panier</div>
                            </div>
                        ))
                    )}
                </div>
                <div className='w-full flex justify-center  mt-10'>
                    <div className='text-gray-500 w-[80%] text-center'>
                        Profitez de nos offres spéciales et de nos soldes exceptionnels, jusqu'à -50% de réduction sur une sélection de produits de haute qualité. Nos articles sont fabriqués avec des matériaux durables et sont couverts par une garantie satisfait . La livraison est rapide , et le paiement en ligne est sécurisé. Nos clients sont enchantés de leurs achats, comme en témoignent les notes élevées qu'ils nous ont attribuées. Mais ne tardez pas, ces offres à durée limitée risquent de s'épuiser rapidement ! Découvrez dès maintenant notre gamme de produits innovants et tendance, dotés de fonctionnalités avancées pour vous simplifier la vie.
                    </div>
                </div>
            </div>
            <Modal dialogClassName="pb-5" overflow={false} size="md" open={isLivraisonSelected} onClose={() => setIsLivraisonSelected(false)}>
                <Modal.Header>
                    <div className="flex items-center justify-between">
                        <div className="font-bold sm:text-2xl max-sm:text-[20px] text-center text-[#5dca32]">
                            Meilleur service de photographe Agence Burundi Paradise
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='h-max'>
                        <ClientPhotgraphie />
                    </div>
                </Modal.Body>
            </Modal>
            <FootentContent />
        </div>
    );
}

export default DetailProduits;


