/* eslint-disable eqeqeq */
import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
function Product() {
    const navig = useNavigate()
    const { produit, isDark, AjoutApnier, GetproduitDetail, HundlwScrollTop } = useThemes()

    return (
        <div>
            <h2 className={`'text-gray-800'} text-base max-sm:text-center md:text-2xl font-bold py-4 ml-3`} >
                Offres extraordinaires adaptées à vos préférences
            </h2>

            <div className='flex flex-wrap'>
                {produit.map((data, index) => (
                    <div data-position={index} key={index} className='border w-[13.5em] relative rounded  m-1 overflow-hidden'>
                        <div className="relative w-full  h-max  overflow-hidden">
                            <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className='cursor-pointer w-full h-[12em] relative  overflow-hidden'>
                                <img className="w-full h-full object-cover  object-center"
                                    src={data.url}
                                    alt='   '
                                    onLoad={() => {
                                        document.querySelector(`[data-position="${index}"] .spinner`).style.display = 'none';
                                    }}
                                />
                                <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinner`}>
                                    <svg
                                        className="animate-spin h-8 w-8 text-[#5dca32]"
                                        viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className='p-2'>
                                <div className='flex items-center justify-between'>
                                    <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className={` cursor-pointer font-bold text-[17px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px] sm:mb-2 first-letter:uppercase`}>
                                        {data.title}
                                    </div>
                                    <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                        </svg>
                                        <div className='ml-0.5'>100K</div>
                                    </div>
                                </div>
                                {data.price && <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className={`flex cursor-pointer justify-between  items-baseline sm:mb-1 sm:space-x-2`}>
                                    <div>Prix</div>
                                    <div className='flex items-center'>
                                        <div className={`text-[13px]  mr-1  text-[#5dca32] font-semibold`}>
                                            {data.price.toFixed(0).toLocaleString()} fbu
                                        </div>
                                        <div className="text-[13px] text-right md:text-sm text-red-400 line-through">
                                            {(data.price + (data.price * 20 / 100)).toFixed(0).toLocaleString()} fbu
                                        </div>
                                    </div>
                                </div>
                                }
                                <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data); HundlwScrollTop() }} className='text-left cursor-pointer text-wrap text-[11px] '>
                                    {data.description && data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
                                </div>
                                {data.qty &&
                                    <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data) }} className="flex cursor-pointer items-baseline my-1 space-x-2">
                                        <p className={`text-[12px] font-thin`}>
                                            Qauntite: {data.qty}
                                        </p>
                                    </div>
                                }

                                {!data.experience && <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data) }} className="flex cursor-pointer items-baseline my-1 space-x-2">
                                    <p className={`text-[12px] font-thin`}>
                                        Status:<span className='text-[#5dca32]'>{index % 2 == 0 ? ' à vendre' : ' à louer'}</span>
                                    </p>
                                </div>
                                }


                                {data.experience &&
                                    <>
                                        <div className="text-[12px] font-thin">
                                            Expérience : {data.experience}
                                        </div>
                                        <div className="text-[12px] font-thin">
                                            Disponibilité :   {data.availability && data.availability.length > 50 ? data.availability.slice(0, 50) + '...' : data.availability}
                                        </div>
                                    </>
                                }
                                {data.experience && <div onClick={() => { navig(`/Immobilier/detail`); GetproduitDetail(data) }} className="flex cursor-pointer items-baseline my-1 space-x-2">
                                    <p className={`text-[12px] font-thin`}>
                                        Status:<span className='text-[#5dca32]'>{index % 2 == 0 ? 'Photographe' : 'PhotoVidéoGraphe'}</span>
                                    </p>
                                </div>
                                }
                            </div>
                        </div>
                        {!data.experience &&
                            <div onClick={() => AjoutApnier(data, data.id)} className='bg-[#5dca3228] top-[89%] absolute transition-all hover:bg-[#5dca3250] text-[#5dca32] font-bold w-max right-0.5 text-center p-1 cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart-plus" viewBox="0 0 16 16">
                                    <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9z" />
                                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg>
                            </div>
                        }
                    </div>
                ))}
            </div>

            <div className='w-full flex justify-center  mt-10'>
                <div className='text-gray-500 w-[80%] text-center'>
                    Profitez de nos offres spéciales et de nos soldes exceptionnels, jusqu'à -50% de réduction sur une sélection de produits de haute qualité. Nos articles sont fabriqués avec des matériaux durables et sont couverts par une garantie satisfait . La livraison est rapide , et le paiement en ligne est sécurisé. Nos clients sont enchantés de leurs achats, comme en témoignent les notes élevées qu'ils nous ont attribuées. Mais ne tardez pas, ces offres à durée limitée risquent de s'épuiser rapidement ! Découvrez dès maintenant notre gamme de produits innovants et tendance, dotés de fonctionnalités avancées pour vous simplifier la vie.
                </div>
            </div>
        </div>
    )
}

export default Product



