/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { ProductListData, hosts, medic, notifications } from '../Data/Data';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
const UserContext = createContext()

export function ContextPovider({ children }) {

    // '''''''''''''''''''''''''''''''''''''''''''''''' Dark Mode Admin et Visteur''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
    const triggerRef = useRef();
    const close = () => triggerRef.current && triggerRef.current.close();
    //visiteur avec evenement onClick
    const [isDark, setIsDark] = useState('light');
    const toggleDarkMode = () => {
        const newDarkMode = isDark === 'dark' ? 'light' : 'dark';
        setIsDark(newDarkMode);
        localStorage.setItem('isDark', newDarkMode);
        close()
    };

    useEffect(() => {
        const storedValue = localStorage.getItem('isDark');
        if (storedValue) {
            setIsDark(storedValue);
        }
    }, [])

    //pour adimin avec evenement onChange dark
    const [isDarkadimin, GetisDarkadimin] = useState('light')
    const handleChange = (value) => {
        GetisDarkadimin(value);
        close()
        localStorage.setItem('isDark', value);
    };

    useEffect(() => {
        const storedValue = localStorage.getItem('isDark')
        if (storedValue) {
            GetisDarkadimin(storedValue);
        }
    }, [isDark]);




    // ...............................scroll window......................
    const [getWidth, SetgetWidth] = useState(0)
    const [isScroll, SetisScroll] = useState(false)

    useEffect(() => {
        const hundleScroll = (e) => {
            const value = window.pageYOffset;
            if (value > 100) {
                SetisScroll(true)
            } else {
                SetisScroll(false)
            }
            Getcategorie(false)
            SetgetWidth(value / (document.documentElement.scrollHeight - document.documentElement.clientHeight));
        }

        const hundleClick = () => {
            Getcategorie(false)
        }

        window.addEventListener('scroll', hundleScroll)
        window.addEventListener('click', hundleClick)
        return () => {
            window.removeEventListener('scroll', hundleScroll)
            window.removeEventListener('click', hundleClick)
        }
    }, [])


    const HundlwScrollTop = () => {
        document.documentElement.scrollTop = 0
        document.documentElement.style.scrollBehavior = 'smooth'
    }



    // ............................ProductListData et card................
    const [produit, Getproduit] = useState(ProductListData);
    const [produitDetail, GetproduitDetail] = useState({});
    const [charite, setcharite] = useState(0)
    const [expedition, setExpedition] = useState(0);

    const [categorie, Getcategorie] = useState(false);
    const [cart, setCart] = useState([]);
    const [itemAmount, setItemAmount] = useState(0);
    const [amount, setAmount] = useState({});
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const total = cart.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.price * currentItem.amount;
        }, 0);
        setTotal(total);
    }, [cart]);

    useEffect(() => {
        if (cart) {
            const amount = cart.reduce((accumulator, currentItem) => {
                return accumulator + currentItem.amount;
            }, 0);
            setItemAmount(amount);
        }
    }, [cart]);


    const AjoutApnier = (product, id) => {
        const newAmount = amount[id] || 1;
        const newItem = { ...product, amount: newAmount };
        const cartItem = cart.find((item) => item.id === id);

        if (cartItem) {
            const newCart = cart.map((item) => {
                if (item.id === id) {
                    return { ...item, amount: newAmount };
                } else {
                    return item;
                }
            });
            setCart(newCart);
            toast.warning('Existant dans le panier');
        } else {
            setCart([...cart, newItem]);
            toast.success('Nouveau produit ajouté avec succès');
        }
    };

    const removeFromCart = (id) => {
        const newCart = cart.filter((item) => item.id !== id);
        setCart(newCart);
    };

    const effaceCart = () => {
        setCart([]);
    };

    const increaseAmount = (id) => {
        setAmount((prev) => ({
            ...prev,
            [id]: (prev[id] || 1) + 1
        }));
        const cartItem = cart.find((item) => item.id === id);
        if (cartItem) {
            const newCart = cart.map((item) => {
                if (item.id === id) {
                    return { ...item, amount: item.amount + 1 };
                } else {
                    return item;
                }
            });
            setCart(newCart);
        }
    };

    const decreaseAmount = (id) => {
        setAmount((prev) => ({
            ...prev,
            [id]: Math.max((prev[id] || 1) - 1, 1)
        }));
        const cartItem = cart.find((item) => item.id === id);
        if (cartItem) {
            if (cartItem.amount > 1) {
                const newCart = cart.map((item) => {
                    if (item.id === id) {
                        return { ...item, amount: item.amount - 1 };
                    } else {
                        return item;
                    }
                });
                setCart(newCart);
            } else {
                toast.error("La quantité minimale pour un produit est 1");
            }
        }
    };

    const currentAmount = amount[produitDetail.id] || 1;

    // .................................. hotel..............................
    const [hoteData, GetHotelData] = useState(hosts)
    const [hoteDetail, GetHotelDetail] = useState({})



    const [isclients, setIsclients] = useState(false);
    const [chambre, setChambre] = useState([{ adults: 1, children: 0, childAges: [], totalOccupants: 1 }]);
    const [totals, setTotals] = useState(0);
    const [itemAmounts, setItemAmounts] = useState(0);
    const [payData, GetpayData] = useState([]);

    const [lieu, setlieu] = useState('');
    const [dateDepart, SetdateDepart] = useState('');
    const [dateEnd, SetdateEnd] = useState('');
    const [categories, SetCategorie] = useState('');

    const data = {
        lieu: lieu,
        dateDepart: dateDepart,
        dateEnd: dateEnd,
    };

    useEffect(() => {
        const total = chambre.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.totalOccupants;
        }, 0);
        setTotals(total);
    }, [chambre]);

    useEffect(() => {
        if (payData) {
            const amount = payData.reduce((accumulator, currentItem) => {
                return accumulator + currentItem.initialPrice;
            }, 0);
            setItemAmounts(amount);
        }
    }, [payData]);

    const choisHotel = (hotel) => {
        const newItem = {
            ...hotel,
            chambre,
            amount: 1,
            dateDepart: dateDepart,
            dateEnd: dateEnd,
        };

        GetpayData([...payData, newItem]);
    };

    const addRoom = () => {
        const newChambre = [...chambre, { adults: 1, children: 0, childAges: [], totalOccupants: 1 }];
        updateTotals(newChambre);
        const updatedPayData = payData.map(item => {
            return { ...item, chambre: newChambre };
        });
        GetpayData(updatedPayData);
        setChambre(newChambre);
    };





    const increaseAdults = (index) => {
        setChambre(prevChambre => {
            const updatedChambre = [...prevChambre];
            updatedChambre[index].adults += 1;
            updatedChambre[index].totalOccupants = updatedChambre[index].adults + updatedChambre[index].children;
            return updatedChambre;
        });
    };

    const decreaseAdults = (index) => {
        setChambre(prevChambre => {
            const updatedChambre = [...prevChambre];
            updatedChambre[index].adults = Math.max(0, updatedChambre[index].adults - 1);
            updatedChambre[index].totalOccupants = updatedChambre[index].adults + updatedChambre[index].children;
            return updatedChambre;
        });
    };

    const increaseChildren = (index) => {
        setChambre(prevChambre => {
            const updatedChambre = [...prevChambre];
            updatedChambre[index].children += 1;
            updatedChambre[index].childAges.push(0);
            updatedChambre[index].totalOccupants = updatedChambre[index].adults + updatedChambre[index].children;
            return updatedChambre;
        });
    };

    const decreaseChildren = (index) => {
        setChambre(prevChambre => {
            const updatedChambre = [...prevChambre];
            updatedChambre[index].children = Math.max(0, updatedChambre[index].children - 1);
            updatedChambre[index].childAges.pop();
            updatedChambre[index].totalOccupants = updatedChambre[index].adults + updatedChambre[index].children;
            return updatedChambre;
        });
    };

    const handleAgeChange = (index, childIndex, age) => {
        setChambre(prevChambre => {
            const updatedChambre = [...prevChambre];
            updatedChambre[index].childAges[childIndex] = age;
            return updatedChambre;
        });
    };

    const updateTotals = (chambreArray) => {
        const updatedChambre = chambreArray.map(room => ({
            ...room,
            totalOccupants: room.adults + room.children,
        }));
        setChambre(updatedChambre);
    };

    const removeBlock = (index) => {
        const updatedChambre = chambre.filter((_, idx) => idx !== index);
        updateTotals(updatedChambre);
        const updatedPayData = payData.map(item => {
            return { ...item, chambre: updatedChambre };
        });
        GetpayData(updatedPayData);
        setChambre(updatedChambre);
    };


    const logChambreValues = () => {
        let allFieldsFilled = true;

        chambre.forEach((block) => {
            if (block.children > 0) {
                block.childAges.forEach((age) => {
                    if (age === 0) {
                        allFieldsFilled = false;
                    }
                });
            }
        });

        if (allFieldsFilled) {

            setIsclients(false)
        } else {
            toast.warning("Tous les champs d'âge d'enfants doivent être obligatoire !!.");
        }
    };

    const sommeAdlt = chambre.reduce((acc, data) =>
        acc + data.adults, 1
    )
    const sommeEnfat = chambre.reduce((acc, data) =>
        acc + data.children, 0
    )
    const sommeHeader = sommeAdlt + sommeEnfat


    // ..................flesh personalise coursel...........
    const CustomRightArrow = ({ onClick }) => {
        return (
            <button
                onClick={onClick}
                className='absolute top-1/2 z-[1000] bg-[#5dca32] border-none p-[5px] cursor-pointer text-white right-1 rounded-full'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
            </button>
        );
    };


    const CustomLeftArrow = ({ onClick }) => {
        return (
            <button
                onClick={onClick}
                className='absolute top-1/2 z-[1000] bg-[#5dca32] border-none p-[5px] cursor-pointer text-white left-1 rounded-full'

            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                    <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                </svg>
            </button>
        );
    };

    // ...................... show notification................
    const [showNotifcation, GetShowNotifcation] = useState(false)
    const [notifData, GetNotiData] = useState(notifications)


    // ...................... show notification................
    const [howMessage, GetShowMessage] = useState(false)

    // ......................facture photographie...................
    const [IsPrinting, setIsPrinting] = useState(false);
    const [cahsPrintAll, SetPrintAll] = useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            setIsPrinting(true);
        },
        onAfterPrint: () => {
            setIsPrinting(false);
            SetshowConf(false)
        },
        onPrintError: (error) => {
            console.error('Erreur d\'impression :', error);
            SetshowConf(false)
        },
    });


    useEffect(() => {
        window.onafterprint = () => {
            setIsPrinting(false);
        };
        return () => {
            window.onafterprint = null;
        };
    }, []);

    // ....show confidEvelutation  ....................
    const [showConf, SetshowConf] = useState(false)

    // ......si on clikc contact pour les photographes
    const [isLivraisonSelected, setIsLivraisonSelected] = useState(false);



    // ....................donnee de client de la livraison......

    const [pay, SetPay] = useState('')
    const [provin, Setprovin] = useState('')
    const [comune, Setcomune] = useState('')
    const [zone, setzone] = useState('')
    const [CollineQuartie, SetCollineQuartie] = useState('')
    const [avenue, Setavenue] = useState('')
    const [NUmMaison, SetNUmMaison] = useState('')
    const [tel, Settel] = useState('')
    const [isTextarea, setIsTextarea] = useState('');

    const useDocTitle = title => {
        const [doctitle, setDocTitle] = useState(title);

        useEffect(() => {
            document.title = doctitle;
        }, [doctitle]);

        return [doctitle, setDocTitle];
    };


    return (
        <UserContext.Provider
            value={{
                CustomLeftArrow,
                CustomRightArrow,
                HundlwScrollTop,
                getWidth,
                isScroll,
                useDocTitle,
                toggleDarkMode,
                isDark,
                produit,
                categorie,
                Getcategorie,
                produitDetail,
                GetproduitDetail,
                cart,
                AjoutApnier,
                removeFromCart,
                effaceCart,
                itemAmount,
                amount,
                setAmount,
                increaseAmount,
                decreaseAmount,
                currentAmount,
                total,

                hoteData,
                GetHotelDetail,
                hoteDetail,

                increaseAdults,
                chambre,
                decreaseAdults,
                increaseChildren,
                decreaseChildren,
                handleAgeChange,
                removeBlock,
                addRoom,
                setIsclients,
                isclients,
                sommeHeader,
                charite, setcharite,
                expedition, setExpedition,


                data,
                setlieu,
                SetdateDepart,
                SetdateEnd,
                lieu,
                dateDepart,
                dateEnd,
                setChambre,
                logChambreValues,
                choisHotel,
                totals,
                itemAmounts,
                payData
                ,
                categories,
                SetCategorie
                ,
                showNotifcation,
                GetShowNotifcation,
                notifData, GetNotiData,
                howMessage, GetShowMessage,

                handlePrint, componentRef, IsPrinting, cahsPrintAll, SetPrintAll,
                showConf, SetshowConf,
                isLivraisonSelected, setIsLivraisonSelected,

                pay, SetPay,
                provin, Setprovin,
                comune, Setcomune,
                zone, setzone,
                CollineQuartie, SetCollineQuartie,
                avenue, Setavenue,
                NUmMaison, SetNUmMaison,
                tel, Settel,
                isTextarea, setIsTextarea
            }}
        >
            {children}
        </UserContext.Provider>
    )
}


export const useThemes = () => {
    return useContext(UserContext)
}
