/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import FootentContent from '../FootentContent/FootentContent'
import { Rate } from 'rsuite';
import Carousel from 'react-multi-carousel';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function HotelDetail() {
    const { hoteDetail, GetHotelDetail, isDark, hoteData, HundlwScrollTop, chambre, nombresClient, chambresNombres, setIsclients, choisHotel } = useThemes()
    const navig = useNavigate()


    const [ratings, setRatings] = useState([5, 5, 5, 4.5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

    const totalRatings = ratings.reduce((sum, rating) => sum + rating, 0);
    const calculateAverageRating = () => {
        console.log(totalRatings)
        const averageRating = totalRatings / ratings.length;
        return averageRating;
    };

    const [isloading, SetIsLoading] = useState(true)

    const hundleLoading = () => {
        SetIsLoading(false)
    }




    const CustomRightArrow = ({ onClick }) => {
        return (
            <div className='absolute flex justify-center items-center z-[1000] right-5 h-full'>
                <button
                    onClick={onClick}
                    className=' bg-[#5dca32] border-none p-[5px] cursor-pointer text-white  rounded-full'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                </button>
            </div>
        );
    };

    const CustomLeftArrow = ({ onClick }) => {
        return (
            <div className='absolute flex justify-center items-center z-[1000] h-full'>
                <button
                    onClick={onClick}
                    className='bg-[#5dca32]  border-none p-[5px] cursor-pointer text-white left-1 rounded-full'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                    </svg>
                </button>
            </div>
        );
    };


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 11
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };



    const [dateDepart, SetdateDepart] = useState('')
    const elemetRefdateDepart = useRef(null)

    const [dateEnd, SetdateEnd] = useState('')
    const elemetRefdateEnd = useRef(null)

    const datas = {
        dateDepart: dateDepart,
        dateEnd: dateEnd,
        chambre: chambre
    }
    const hundleSubmit = (e) => {
        e.preventDefault()
        if (dateDepart === '') {
            toast.warning("La date d'entrée", {
                autoClose: 2000
            });
            elemetRefdateDepart.current.focus()
            return false;
        } else if (dateEnd === '') {
            toast.warning("La date de sortir", {
                autoClose: 2000
            });
            elemetRefdateEnd.current.focus()
            return false;
        } else {
            toast.success(`Vous avez recherché `)
            console.log("hotel recher", datas)
        }
    }




    return (
        <div className='mt-[10vh]'>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/Hotel")} className="text-[#5dca32] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div className='w-full min-h-[80vh] border'>
                <img className='w-full h-full' onLoad={hundleLoading} src={hoteDetail.url} alt='   ' />
                {isloading &&
                    <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}`}>
                        <svg className="animate-spin h-10 w-10 text-[#5dca32]" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="green" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                        </svg>
                    </div>
                }
            </div>
            <div>
                <div className='text-[20px] mt-5 pl-2'>Autre partie d'un hotel <span className='font-bold'>{hoteDetail.name}</span> </div>
                <div className='flex items-center'>
                    <div className='w-[100%] mt-1'>
                        <Carousel
                            customRightArrow={<CustomRightArrow />}
                            customLeftArrow={<CustomLeftArrow />}
                            responsive={responsive}
                            containerClass="h-[10em] p-2"
                        >
                            {hoteData.map((host, index) => (
                                <div data-position={index} key={index} onClick={() => { GetHotelDetail(host); HundlwScrollTop() }} className='m-1 p-1'>
                                    <div >{host.name}</div>
                                    <div className='h-[9em] w-[9em]'>
                                        <img
                                            onLoad={() => {
                                                document.querySelector(`[data-position="${index}"] .spinnerw`).style.display = 'none';
                                            }}
                                            src={host.url}
                                            alt={host.name}
                                            className='w-full h-full object-cover object-center' />
                                        <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinnerw`}>
                                            <svg
                                                className="animate-spin h-8 w-8 text-[#5dca32]"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <div className='text-[40px]'>Présentation de hote <span className='font-bold'>{hoteDetail.name}</span> </div>
                <div className='pl-4'>
                    <div className='text-[25px] mt-5'>Bio: {hoteDetail.bio}</div>
                    <div className='text-[20px] mt-2'>Propriétaire: {hoteDetail.properties[0]},{hoteDetail.properties[1]}</div>
                    <div className='text-[20px] mt-5'>Spécialisation: {hoteDetail.specialization[0]},{hoteDetail.specialization[1]}</div>
                    <div className='text-[20px] mt-5'>Disponibilité:<br /><span className='text-[15px]'> {hoteDetail.availability}</span></div>
                    <div className='text-[20px] mt-5'>Remboursement:<br /> <span className='text-[15px]'>{hoteDetail.refundPolicy}</span> </div>
                    <div className='text-[20px] mt-5'>Agréments:<br />
                        <div className='pl-3 text-[15px]'>
                            -{hoteDetail.amenities[0]}<br />
                            -{hoteDetail.amenities[1]}<br />
                            -{hoteDetail.amenities[2]}<br />
                        </div>
                    </div>
                    <div className='text-[20px] mt-5'>Emplacement:<br />
                        <div className='pl-3 text-[15px]'>
                            Ville: {hoteDetail.location.city}<br />
                            Avenue: {hoteDetail.location.address}<br />
                            Détail: {hoteDetail.location.details}<br />
                        </div>
                    </div>
                    <div className='text-[25px] mt-5'>Equpement populaire</div>
                    <div className='pl-3 text-[15px]'>
                        <div className='text-[15px]'>-Nombres des chambres disponible: {hoteDetail.availableRooms}</div>
                        <div className='text-[15px]'>-Nombres des lits disponible: {hoteDetail.beds}</div>
                        <div className='text-[15px]'>-Nombres des piscines: {hoteDetail.pools}</div>
                        <div className='text-[15px]'>-Petit-déjeuner: {hoteDetail.breakfastIncluded ? <span className='text-[#5dca32]'>Oui</span> : <span className='text-red-500'>Non</span>}</div>
                        <div className='text-[15px]'>-Parking: {hoteDetail.parkingIncluded ? <span className='text-[#5dca32]'>Oui</span> : <span className='text-red-500'>Non</span>}</div>
                    </div>
                    <div className='text-[20px] mt-5'>Avis: {calculateAverageRating().toFixed(2)} <Rate color="green" size="xs" value={calculateAverageRating()} readOnly /> {totalRatings}</div>

                    <div className='text-[20px] mt-5'>Taux:<br />
                        <div className='pl-3 text-[15px]'>
                            -Par jour: {hoteDetail.rates.daily.toLocaleString()} Fbu à  {hoteDetail.rates.weekly.toLocaleString()} fbu<br />
                            -Hebdomadaire: {hoteDetail.rates.weekly.toLocaleString()}  Fbu à {hoteDetail.rates.monthly.toLocaleString()}  Fbu<br />
                            -Mensuel : {hoteDetail.rates.monthly.toLocaleString()} Fbu à {hoteDetail.initialPrice.toLocaleString()}  <br />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[full flex justify-end mr-5 mt-4'>
                <buttom onClick={() => { navig('/Hotel/PaymentHotel'); choisHotel(hoteDetail, hoteDetail.id) }} className="hover:text-[#5dca32] bg-[#5dca32] text-white text-[16px] cursor-pointer hover:bg-transparent border h-max transition-all duration-200  border-[#5dca32] px-3 py-2 rounded-lg ">
                    Réserver une chambre
                </buttom>
            </div>
            <div className='mt-10 pl-1 text-[20px]'>Choisissez votre chambre</div>
            <form onSubmit={hundleSubmit} className='flex gap-2 items-end mt-1 h-max p-2'>
                <div class={`flex flex-col    w-full`}>
                    <label className="text-lg mb-1">Date d'entree</label>
                    <input
                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                        value={dateDepart} ref={elemetRefdateDepart} onInput={(e) => SetdateDepart(e.target.value)}
                        type="date"
                    />
                </div>
                <div class={`flex flex-col    w-full`}>
                    <label className="text-lg mb-1">Date de sortie</label>
                    <input
                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                        value={dateEnd} ref={elemetRefdateEnd} onInput={(e) => SetdateEnd(e.target.value)}
                        type="date"
                    />
                </div>
                <div onClick={() => setIsclients(true)} class={`flex flex-col  cursor-pointer  w-full`}>
                    <label className="text-lg mb-1">Voyageurs</label>
                    <div className="p-2 cursor-pointer bg-transparent outline-none rounded border focus:border-[#5dca32]">
                        {nombresClient} {nombresClient > 1 ? 'clients' : 'client'} , {chambresNombres && chambresNombres} {chambresNombres && (chambresNombres > 1 ? 'chambres' : 'chambre')}
                    </div>
                </div>
                <button type='submite' class={`flex flex-col    w-max`}>
                    <div className="p-2 cursor-pointer bg-transparent text-[#5dca32] outline-none rounded border border-[#5dca32]">
                        Rechercher
                    </div>
                </button>
            </form>
            <div className='flex flex-wrap'>
                {hoteData.map((data, index) => (
                    <div onClick={() => { GetHotelDetail(data); HundlwScrollTop() }} data-position={index} key={index} className='border w-[22em] cursor-pointer relative rounded  m-1 overflow-hidden'>
                        <div className="relative w-full  h-max  overflow-hidden">
                            <div className=' w-full h-[15em] relative  overflow-hidden'>
                                <img className="w-full h-full object-cover  object-center"
                                    src={data.url}
                                    alt='   '
                                    onLoad={() => {
                                        document.querySelector(`[data-position="${index}"] .spinner`).style.display = 'none';
                                    }}
                                />
                                <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinner`}>
                                    <svg
                                        className="animate-spin h-8 w-8 text-[#5dca32]"
                                        viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className='p-2'>
                                <div className='flex justify-between mt-2 items-center'>
                                    <div className='flex items-center'>
                                        <div className='text-[#5dca32] mr-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                        <div className={` r font-bold text-[17px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                            {data.name}
                                        </div>
                                    </div>
                                    <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                        </svg>
                                        <div className='ml-0.5'>{data.numberOfReviews}K</div>
                                    </div>
                                </div>
                                <div className='flex items-start mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px] first-letter:uppercase`}>
                                        {data.properties[0]}
                                    </div>
                                </div>

                                <div className='flex items-start mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px] first-letter:uppercase`}>
                                        {data.bio && data.bio.length > 50 ? data.bio.slice(0, 50) + '....' : data.bio}
                                    </div>
                                </div>



                                <div className='flex items-start mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px] first-letter:uppercase`}>
                                        {data.specialization[0]}
                                    </div>
                                </div>

                                <div className='flex items-start mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px] first-letter:uppercase`}>
                                        {data.amenities[0]}, {data.amenities[1]}, {data.amenities[2]}
                                    </div>
                                </div>
                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        {data.availableRooms} chambres disponible
                                    </div>
                                </div>
                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        {data.beds} lits
                                    </div>
                                </div>
                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        {data.rates.roomPerBed} fb/chambre
                                    </div>
                                </div>
                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        {data.pools} piscine(s)
                                    </div>
                                </div>

                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        Petit-déjeuner: {data.breakfastIncluded ? <span className='text-[#5dca32]'>Oui</span> : <span className='text-red-500'>Non</span>}
                                    </div>
                                </div>

                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        Parking: {hoteDetail.parkingIncluded ? <span className='text-[#5dca32]'>Oui</span> : <span className='text-red-500'>Non</span>}
                                    </div>
                                </div>

                                <div className='flex items-center mt-2'>
                                    <div className='text-[#5dca32] mr-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                    <div className={`text-[15px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px]  first-letter:uppercase`}>
                                        {data.location.city},{data.location.address}
                                    </div>
                                </div>

                                <div className='flex justify-end w-full'>
                                    <div onClick={() => { navig('/Hotel/PaymentHotel'); choisHotel(hoteDetail, hoteDetail.id) }} className='text-white bg-[#5dca32] px-3 py-1 cursor-pointer rounded-sm hover:scale-[1.1] transition-all'>Réserver</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <FootentContent />
        </div>
    )
}

export default HotelDetail


