import React, { useState } from 'react';
import { useThemes } from '../../../UserContext/UserContext';

const VoyageurClientTourism = () => {
    const { increaseAdults, chambre, decreaseAdults } = useThemes()
    const [desable, Setdesable] = useState(false)
    return (
        <div>
            {chambre.map((block, index) => (
                <div key={index} className="  w-full p-1 sm:p-4 mb-10  flex flex-col">
                    <div className="sm:mt-4 mt-1 border rounded p-3 border-[#5dca32]">
                        <div className='flex justify-center flex-col'>
                            <div className='flex items-center'>
                                <div className="text-lg">Invites</div>
                                <div className="flex ml-3 border border-gray-300 divide-x divide-gray-300 w-max">
                                    <button
                                        disabled={desable}
                                        className={` ${desable ? 'opacity-40 bg-gray-400' : 'opacity-100'} h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                        onClick={() => {
                                            if (block.adults > 1) {
                                                decreaseAdults(index)
                                                Setdesable(false)
                                            } else {
                                                Setdesable(true)
                                            }
                                        }}
                                    >
                                        -
                                    </button>
                                    <div className="h-6 w-6 text-base flex items-center justify-center">
                                        {block.adults}
                                    </div>
                                    <button
                                        className={`h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                        onClick={() => { increaseAdults(index); Setdesable(false) }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className='w-full flex justify-end'>
                <div className='flex flex-col items-end'>
                    <div className='mb-2 cursor-pointer' onClick={addRoom} >
                        Ajouter une chambre
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default VoyageurClientTourism;
