import React from 'react'
import Sloger from './Sloger'
import ABP from './ABP'
import Service from './Service'
import Hotel from './Hotel'
import Tourisme from './Tourisme'
import Reservation from './Reservation'
import QuestionReponse from './QuestionReponse'
import FootentContent from '../FootentContent/FootentContent'
import { useThemes } from '../../../UserContext/UserContext'
function AcceuilleComponent() {

  const { useDocTitle } = useThemes()
  useDocTitle("BPS| Acceuil")
  return (
    <>
      <Sloger />
      <ABP />
      <Service />
      <Hotel />
      <Tourisme />
      <Reservation />
      <QuestionReponse />
      <FootentContent />
    </>
  )
}

export default AcceuilleComponent