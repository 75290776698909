/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-self-compare */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import FootentContent from '../FootentContent/FootentContent'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'

function CardProduct() {
    const navig = useNavigate()
    const { cart, total, removeFromCart, increaseAmount, decreaseAmount, effaceCart, isDark, AjoutApnier, produit } = useThemes()
    const [desable, Setdesable] = useState(false)
    useEffect(() => {
        if (cart.length == 0) {
            navig('/Immobilier')
        }
    }, [cart.length])




    return (
        <div className='mt-[10vh]'>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/Immobilier")} className="text-[#5dca32] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <h1 className="my-2 px-2 text-[#5dca32]  text-left text-2xl max-sm:text-center max-sm:text-xl font-bold">

                Tous les articles du panier
            </h1>
            <div className={`  mx-auto justify-center flex  relative  xl:flex md:space-x-6 xl:px-0`}>

                <div className={` rounded-lg  w-[70%] border  p-2`}>
                    <div >Votre produit: {cart.length} {1 == 2 ? 'article' : 'articles'}</div>
                    {cart.map((data, index) => (
                        <div data-position={index} key={index} className={` relative flex ${(index + 1) == cart.length ? '' : 'border-b'}    m-2  p-2 full `}>
                            <div class={`z-[1000] flex absolute top-0 left-0 w-full p-2   justify-end`}>
                                <svg onClick={() => removeFromCart(data.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi cursor-pointer h-6 w-6 bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                </svg>
                            </div>
                            <div className={` p-2 relative block overflow-hidden    w-[22em] h-[22em]`}>
                                <img
                                    onLoad={() => {
                                        document.querySelector(`[data-position="${index}"] .spinne`).style.display = 'none';
                                    }}
                                    src={data.url}
                                    className="h-full object-cover   rounded-lg w-full"
                                />
                                <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinne`}>
                                    <svg
                                        className="animate-spin h-8 w-8 text-[#5dca32]"
                                        viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className={` relative  ml-3  h-full w-[55%] overflow-hidden `}>
                                <div className={` text-gray-800'} `}>
                                    <div className="flex flex-row justify-between ">
                                        <h2 className="text-lg font-bold ">
                                            {data.title}
                                        </h2>
                                    </div>
                                    <div className="font-semibold text-lg my-2 space-x-2">
                                        Categories : {data.category}
                                    </div>
                                    <div className="flex items-baseline mb-1 space-x-2 font-roboto sm:mt-4">
                                        <div className="text-lg">Prix:</div>
                                        <div className={` md:text-lg text-[#5dca32] font-semibold`}>
                                            {data && data.price.toFixed(0)}Fbu
                                        </div>
                                        <div className={` md:text-base ml-3 text-right  text-red-400 line-through`}>
                                            {data && (data.price + (data.price * 20) / 100).toFixed(0)}Fbu
                                        </div>
                                    </div>
                                    <div className="space-x-2 text-lg">
                                        {data.description}
                                    </div>
                                    <p className="mt-1 text-base">
                                        Disponibilité: {data.qty}
                                    </p>
                                </div>
                                <div className="flex items-baseline mb-1 space-x-2 font-roboto">
                                    <div className="text-lg">Vendeur</div>
                                    <div className={` md:text-lg  font-semibold`}>
                                        NIYOMUKIZA Justin
                                    </div>
                                </div>
                                <div className="mt-4  flex">
                                    <div className="flex border border-gray-300 divide-x divide-gray-300 w-max">
                                        <button
                                            onClick={() => {
                                                if (data.amount == 1) {
                                                    Setdesable(false)
                                                    removeFromCart(data.id)
                                                } else {
                                                    decreaseAmount(data.id)
                                                    Setdesable(false)
                                                }
                                            }}
                                            class="h-6  w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none">
                                            -
                                        </button>
                                        <div className="h-6 w-6 md:h-8 md:w-8 text-base flex items-center justify-center">
                                            {data.amount}
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (data.amount < data.qty) {
                                                    increaseAmount(data.id)
                                                    Setdesable(false)
                                                }
                                                else {
                                                    Setdesable({ ...desable, [data.id]: true });
                                                    toast.warning("Vous avez atteint la quantité maximale disponible pour ce produit");
                                                }
                                            }}
                                            disabled={desable[data.id]}
                                            className={`${desable[data.id] ? 'opacity-30' : 'opacity-100'}  h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none`}>
                                            +
                                        </button>
                                    </div>

                                    <div className="flex  justify-end w-full">
                                        <div class="flex   mb-1 font-roboto ">
                                            <p class="text-xl text-primary font-semibold">
                                                {(data.price * data.amount).toLocaleString()} Fbu
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>


                <div className={` sticky top-5   h-full px-8 py-2 rounded-lg border shadow-2xl  w-[30%]`}>
                    <div className="flex w-full text-[#5dca32] font-bold text-2xl mb-5">Synthèse</div>
                    <hr className="my-4" />
                    <div className="flex justify-between mb-4">
                        <p className="text-lg font-bold">Sous total</p>
                        <div className="">
                            <p className=""> {total.toLocaleString()} Fbu</p>
                        </div>
                    </div>
                    <div className="flex justify-between mb-4">
                        <p className="text-lg font-bold">Expédition</p>
                        <p className="">0 Fbu</p>
                    </div>

                    <div className="flex justify-between">
                        <p className="text-lg font-bold">Total</p>
                        <p className="font-extrabold text-lg text-[#5dca32]">{total.toLocaleString()} Fbu</p>
                    </div>
                    <div className='flex mt-5 justify-end w-full items-center'>
                        <div onClick={() => effaceCart()} className="text-red-600 hover:bg-red-200 bg-red-100 rounded py-2 px-4 cursor-pointer">
                            Vider le panier
                        </div>
                    </div>
                    <div onClick={() => navig("/Immobilier/pay")}
                        to="/Orders/Paiement"
                        class="block w-full mt-6 py-2 cursor-pointer text-center text-white bg-[#5dca32] border border-[#5dca32] rounded-2xl hover:bg-transparent hover:text-[#5dca32]"
                    >
                        Vérifier
                    </div>



                    <div class="flex flex-col  max-md:items-center">

                        <p class="text-gray-400 max-md:w-full  font-bold my-2">Payer avec:
                        </p>
                        <div class={`flex   justify-around gap-2`}>
                            <div class={` w-[3em]  h-[3em] flex relative overflow-hidden justify-center items-center  bg-gray-200`}>
                                <img draggable='false'
                                    className=" object-cover w-full h-full"
                                    src="../../../image/ecocashe.jpg" />
                            </div>

                            <div class={` w-[3em]  h-[3em] flex relative overflow-hidden justify-center items-center  bg-gray-200`}>
                                <img draggable='false'
                                    className=" object-cover w-full h-full"
                                    src="../../../image/lumitel.png" />
                            </div>
                            <div class={` w-[3em]  h-[3em] flex relative overflow-hidden justify-center items-center  bg-gray-200`}>
                                <img draggable='false'
                                    className=" object-contain bg-white  w-full h-full"

                                    src="../../../image/mobinotel.png"
                                />
                            </div>
                            <div class={` w-[3em]  h-[3em] flex relative overflow-hidden justify-center items-center  bg-gray-200`}>
                                <img draggable='false'
                                    className=" object-contain bg-white w-full h-full"

                                    src="../../../image/feza.png"
                                />
                            </div>
                            <div class={` w-[3em]  h-[3em] flex relative overflow-hidden justify-center items-center  bg-gray-200`}>
                                <img draggable='false'
                                    className=" object-contain bg-white w-24 h-24"
                                    src="../../../image/New-logo-CECM-01.jpg" />
                            </div>
                            <div class={` w-[3em]  h-[3em] flex relative overflow-hidden justify-center items-center  bg-gray-200`}>
                                <img draggable='false'
                                    className=" object-contain bg-white  w-full h-full"
                                    src="../../../image/BancobueNoti.jpg" />
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 border p-2 rounded">
                        <div className="font-bold text-xl">Services du client</div>
                        <div className="sm:pl-3 max-sm:text-[12px] max-sm:text-center">
                            Obtenez un remboursement complet
                            si l'article vous est livré en retard
                            ou bien ne correspond pas à sa description.
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <h2 className={`'text-gray-800'} text-base max-sm:text-center md:text-2xl font-bold py-4 ml-3`} >
                    Vous aimez aussi
                </h2>
                <div className='flex flex-wrap'>
                    {produit.filter(data => !data.experience).map((data, index) => (
                        <div data-position={index} key={index} className='border w-[13.5em] rounded  m-1 overflow-hidden'>
                            <div className="relative w-full  h-max  overflow-hidden">
                                <div className='cursor-pointer w-full h-[12em] relative  overflow-hidden'>
                                    <img className="w-full h-full object-cover  object-center"
                                        src={data.url}
                                        alt='   '
                                        onLoad={() => {
                                            document.querySelector(`[data-position="${index}"] .spinner`).style.display = 'none';
                                        }}
                                    />
                                    <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinner`}>
                                        <svg
                                            className="animate-spin h-8 w-8 text-[#5dca32]"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <div className='flex items-center justify-between'>
                                        <div className={` cursor-pointer font-bold text-[17px]  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px] sm:mb-2 first-letter:uppercase`}>
                                            {data.title}
                                        </div>
                                        <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                            </svg>
                                            <div className='ml-0.5'>100K</div>
                                        </div>
                                    </div>
                                    {data.price && <div className={`flex cursor-pointer justify-between  items-baseline sm:mb-1 sm:space-x-2`}>
                                        <div>Prix</div>
                                        <div className='flex items-center'>
                                            <div className={`text-[13px]  mr-1  text-[#5dca32] font-semibold`}>
                                                {data.price.toFixed(0).toLocaleString()} fbu
                                            </div>
                                            <div className="text-[13px] text-right md:text-sm text-red-400 line-through">
                                                {(data.price + (data.price * 20 / 100)).toFixed(0).toLocaleString()} fbu
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className='text-left cursor-pointer text-wrap text-[11px] '>
                                        {data.description && data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description}
                                    </div>
                                    {data.qty &&
                                        <div className="flex cursor-pointer items-baseline my-1 space-x-2">
                                            <p className={`text-[12px] font-thin`}>
                                                Qauntite: {data.qty}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div onClick={() => AjoutApnier(data, data.id)} className='bg-[#5dca3228] transition-all hover:bg-[#5dca3250] text-[#5dca32] font-bold w-full text-center p-2 cursor-pointer'>Ajouter un panier</div>
                        </div>
                    ))}
                </div>
                <div className='w-full flex justify-center  mt-10'>
                    <div className='text-gray-500 w-[80%] text-center'>
                        Profitez de nos offres spéciales et de nos soldes exceptionnels, jusqu'à -50% de réduction sur une sélection de produits de haute qualité. Nos articles sont fabriqués avec des matériaux durables et sont couverts par une garantie satisfait . La livraison est rapide , et le paiement en ligne est sécurisé. Nos clients sont enchantés de leurs achats, comme en témoignent les notes élevées qu'ils nous ont attribuées. Mais ne tardez pas, ces offres à durée limitée risquent de s'épuiser rapidement ! Découvrez dès maintenant notre gamme de produits innovants et tendance, dotés de fonctionnalités avancées pour vous simplifier la vie.
                    </div>
                </div>
            </div>
            <FootentContent />
        </div>
    )
}

export default CardProduct