/* eslint-disable eqeqeq */
import React, { useState } from 'react'

function Evaluations() {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const handleRating = (rate) => {
        setRating(rate);
        console.log(`Note attribuée : ${rate} étoiles`);
    };

    return (
        <div>
            <div className="flex flex-row justify-center items-center">
                {[...Array(5)].map((star, index) => {
                    const ratingValue = index + 1;
                    return (
                        <label key={index}>
                            <input
                                type="radio"
                                name="rating"
                                hidden
                                value={ratingValue}
                                onClick={() => handleRating(ratingValue)}
                            />
                            <svg
                                className="cursor-pointer transuition-all duration-200"
                                viewBox="0 0 24 24"
                                fill={ratingValue <= (hover || rating) ? "#5dca32" : "#e4e5e9"}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(0)}
                                height="30" width="30"
                            >
                                <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73L5.82 21z" />
                            </svg>
                        </label>
                    );
                })}
                {rating != 0 && <>
                    {rating == 1 && 'Mauvais'}
                    {rating == 2 && 'Moyen'}
                    {rating == 3 && 'Bon'}
                    {rating == 4 && 'Excellent'}
                    {rating == 5 && 'Très excellent'}
                </>
                }
            </div> </div>
    )
}

export default Evaluations