import { Accordion } from 'rsuite';
import { FaAngleDoubleDown } from 'react-icons/fa';

const QuestionReponse = () => (
    <div className='w-full px-2 flex justify-center py-4'>
        <div className='w-full'>
            <div className='sm:text-[25px] max-sm:text-center p-2 text-[15px] border-b pb-5 mb-1'>Question fréquemment posées sur  nos services </div>
            <div className='mb-10'>
                <div className='inline-flex items-center text-[#5dca32] text-xl'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    <div>
                        Immobilier à vendre et à louer
                    </div>
                </div>
                <Accordion >
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal   max-sm:text-center '>
                            Quels types de biens immobiliers proposez-vous à la vente et à la location ?
                        </div>
                    } eventKey={1} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Maisons, appartements, terrains, immeubles, etc. en vente et en location
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Comment puis-je rechercher un bien immobilier qui correspond à mes critères ?
                        </div>
                    } eventKey={2} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Moteur de recherche avancé par nom, budget, nombre de pièces, etc.
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>
                            Pouvez-vous m'aider dans les démarches d'achat ou de location d'un bien ?
                        </div>
                    } eventKey={3} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Accompagnement dans toutes les étapes (visites, négociations, dossiers, etc.)
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>
                            Quels sont les avantages de passer par votre agence immobilière ?
                        </div>
                    } eventKey={4} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Expertise locale, réseau de partenaires, services sur-mesure
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Pouvez-vous m'estimer la valeur de mon bien immobilier ?
                        </div>
                    } eventKey={5} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400' >
                            Estimation gratuite et sans engagement de votre bien immobilier
                        </div>
                    </Accordion.Panel>
                </Accordion>
            </div>

            <div className='mb-10'>
                <div className='inline-flex items-center text-[#5dca32] text-xl'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    <div>
                        Réservation hôtelière
                    </div>
                </div>
                <Accordion >
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal   max-sm:text-center '>
                            Quels types d'hébergements proposez-vous à la réservation ?
                        </div>
                    } eventKey={1} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Hôtels, resorts, lodges, guest houses, etc. dans tout le Burundi
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Comment puis-je trouver et réserver un hôtel adapté à mes besoins ?
                        </div>
                    } eventKey={2} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Moteur de réservation en ligne avec filtres par localisation, prix, équipements
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>
                            Bénéficié-je de tarifs préférentiels en réservant par votre intermédiaire ?
                        </div>
                    } eventKey={3} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Oui, grâce à nos accords avec les hôtels partenaires
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>
                            Quels sont les avantages de réserver un hôtel via votre plateforme ?
                        </div>
                    } eventKey={4} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Conseil d'experts, disponibilité 24/7, paiement sécurisé, garantie de meilleur prix
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Que faire en cas de modification ou d'annulation de ma réservation ?
                        </div>
                    } eventKey={5} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400' >
                            Assistance pour toute demande de modification ou d'annulation
                        </div>
                    </Accordion.Panel>
                </Accordion>
            </div>

            <div className='mb-10'>
                <div className='inline-flex items-center text-[#5dca32] text-xl'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    <div>
                        Services touristiques
                    </div>
                </div>
                <Accordion >
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal   max-sm:text-center '>
                            Quelles sont les principales activités et excursions proposées ?
                        </div>
                    } eventKey={1} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Safaris, randonnées, visites culturelles, sports nautiques, etc.
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Pouvez-vous m'aider à concevoir un séjour sur-mesure au Burundi ?
                        </div>
                    } eventKey={2} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>

                            Nos conseillers créent des forfaits personnalisés selon vos centres d'intérêt
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>

                            Quels sont les avantages d'utiliser vos services touristiques ?
                        </div>
                    } eventKey={3} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>

                            Expertise locale, tarifs négociés, services clé en main, suivi attentionné
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>

                            Comment se déroule l'organisation pratique de mon voyage ?
                        </div>
                    } eventKey={4} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Réservation de vols, hébergements, transports, activités, etc.
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>

                            Quelle est la qualité de votre accompagnement avant et pendant le séjour ?
                        </div>
                    } eventKey={5} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400' >

                            Conseils avant le départ, assistance sur place, réactivité 24/7
                        </div>
                    </Accordion.Panel>
                </Accordion>
            </div>

            <div className='mb-10'>
                <div className='inline-flex items-center text-[#5dca32] text-xl'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    <div>
                        Réservations diverses
                    </div>
                </div>
                <Accordion >
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal   max-sm:text-center '>
                            Quels autres services de réservation proposez-vous ?
                        </div>
                    } eventKey={1} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Locations de voitures, transferts, activités, etc.
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Comment puis-je réserver mes billets d'avion par votre intermédiaire ?
                        </div>
                    } eventKey={2} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Nos conseillers s'occupent de la réservation de vos vols avec nos partenaires
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal'>
                            Bénéficié-je de tarifs préférentiels sur les réservations effectuées ?
                        </div>
                    } eventKey={3} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Oui, grâce à nos accords négociés avec les compagnies aériennes et prestataires
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal  '>
                            Que faire en cas de modification ou d'annulation d'une réservation ?
                        </div>
                    } eventKey={4} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400'>
                            Nous facilitons les démarches de modification ou d'annulation dans la mesure du possible
                        </div>
                    </Accordion.Panel>
                    <Accordion.Panel header={
                        <div className=' max-sm:text-[12px] font-normal '>
                            Quel est le niveau de service et d'assistance fourni pour ces réservations ?
                        </div>
                    } eventKey={5} caretAs={FaAngleDoubleDown}>
                        <div className=' max-sm:text-[12px] text-gray-400' >
                            Service personnalisé, accompagnement avant/pendant le voyage, assistance 24/7
                        </div>
                    </Accordion.Panel>
                </Accordion>
            </div>
        </div>
    </div>
);

export default QuestionReponse
