/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import { useThemes } from '../../../UserContext/UserContext';

const QrCode = () => {
    const { pay,
        provin,
        comune,
        zone,
        CollineQuartie,
        avenue,
        NUmMaison,
        tel,
        isTextarea,
    } = useThemes()

    const qrCodeData = JSON.stringify({
        pay,
        provin,
        comune,
        zone,
        CollineQuartie,
        avenue,
        NUmMaison,
        tel,
        isTextarea
    });
    const downloadQRCode = () => {
        html2canvas(document.querySelector("#qrcode-container"))
            .then(canvas => {
                const imageURL = canvas.toDataURL("image/png");
                const downloadLink = document.createElement("a");
                downloadLink.href = imageURL;
                downloadLink.download = "KBc.png";
                downloadLink.click();
            });
    };
    return (
        <div className='flex bg-slate-500 h-full flex-col justify-start items-center pt-10'>
            <div className="bg-green-400  p-1 mt-1 relative" style={{ position: 'relative', display: 'inline-block' }} >
                <div id="qrcode-container" className=' p-1 bg-green-600 w-max mt-2'>
                    <QRCode value={qrCodeData} size={200} fgColor='black' bgColor='#5dca32' />
                    <img src='image/btr.png' style={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }} className='rounded-full w-[30px] h-[30px] bg-white p-[1px] pointer-events-none' />
                </div>)

                <button onClick={downloadQRCode} className='bg-[#5dca32] text-white w-full'><i className="bi bi-download"></i></button>
            </div>
        </div>
    );
};

export default QrCode;

