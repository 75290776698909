import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from 'swiper/modules';
import { dataSloger } from "../../../Data/Data";

function Sloger() {
  return (
    <Swiper
      className="mySwiper mt-[10vh]"
      loop={true}
      effect={'fade'}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, EffectFade]}
    >
      {dataSloger.map((data, index) => (
        <SwiperSlide key={index} className="relative">
          <img src={`${data.ur}`} className="obje" alt="   " />
          <div className="absolute top-2 flex items-center flex-col h-full">
            <div className="text-6xl font-bold strockText">{data.titre}</div>
            <div className="text-4xl stroke-text relative mt-[2em]  w-[70%]">{data.description}</div>
            <div className="w-max mt-5 px-3 py-2 bg-[#5dca32] border-[3px] rounded-md cursor-pointer border-white text-white">
              Voir plus
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Sloger