/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'


function NavBarsImmobilier() {
    const { categorie, Getcategorie, isDark } = useThemes()

    const [category, setCategory] = useState("");
    const [showVetem, SetshowVetem] = useState(false)
    const [showAlimentation, SetshowAlimentation] = useState(false)
    const [showAccessoires, SetshowAccessoires] = useState(false)
    const [showAppariels, SetshowAppariels] = useState(false)
    const [showMobilier, SetshowMobilier] = useState(false)
    const [showVéhicules, SetshowVéhicules] = useState(false)


    const handleCategoryClick = (category) => {
        setCategory(category);
        Getcategorie(false);
    };


    return (
        <div className='flex mt-[10vh]  justify-between h-max items-center border-b p-2 w-full'>
            <div onClick={(e) => { e.stopPropagation(); Getcategorie(!categorie) }} className='bg-[#5dca32] mx-2 p-1 rounded fex justify-center items-center h-max text-white cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list-stars" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5" />
                    <path d="M2.242 2.194a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.28.28 0 0 0-.094.3l.173.569c.078.256-.213.462-.423.3l-.417-.324a.27.27 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.28.28 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.27.27 0 0 0 .259-.194zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.28.28 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.27.27 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.28.28 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.27.27 0 0 0 .259-.194zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.28.28 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.27.27 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.28.28 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.27.27 0 0 0 .259-.194z" />
                </svg>
            </div>

            {categorie &&
                <div className={`fixed  z-[100] w-[20em]  overflow-x-hidden overflow-y-auto top-[18.5vh] left-0 border-r-2 border-[#5dca32] h-[81.5vh] ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}`}>
                    <div className="w-full   font-serif">
                        <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("") }} className={`cursor-pointer flex   justify-start items-center  p-1 w-full h-12    pb-2  transition-all  ${category === "" ? "text-[#5dca32]" : ""}`} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                            <p className="ml-2">Tous les catégories</p>
                        </div>
                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(!showVetem);; SetshowAlimentation(false); e.stopPropagation() }} className={`cursor-pointer flex  relative justify-between items-center  p-1 w-full h-12    pb-2  transition-all   ${category == "Habits des Hommes" || category == 'Habits Enfants Féminins' || category == 'Habits Enfants Masculins' || category == 'Kit Nouveau Né Féminins' || category == 'Kit Nouveau Né Masculins' || category == 'Habits des bébés Féminins ' || category == 'Habits des bébés Masculins ' || category == 'Habits Sports ' || category == 'Chaussures des Femmes' || category == 'Chaussures des Hommes' || category == 'Habits des Femmes' ? "text-[#5dca32]" : ""} `}>
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                                <p className="ml-2">Vêtements</p>
                            </div>
                            <div className={` ${showVetem ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                            </div>
                        </div>
                        <div className={` ${showVetem ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits des Hommes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Hommes") }}>Habits des Hommes</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits des Femmes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Femmes") }}>Habits des Femmes</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Chaussures des Hommes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Hommes") }}>Chaussures des Hommes</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Chaussures des Femmes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Femmes") }}>Chaussures des Femmes</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits Sports ' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Sports ") }}>Habits Sports </div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits des bébés Masculins ' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Masculins ") }}>Habits des bébés Masculins </div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits des bébés Féminins ' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Féminins ") }}>Habits des bébés Féminins </div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Kit Nouveau Né Masculins' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Masculins") }}>Kit Nouveau Né Masculins</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Kit Nouveau Né Féminins' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Féminins") }}>Kit Nouveau Né Féminins</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits Enfants Masculins' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Masculins") }}>Habits Enfants Masculins</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category === 'Habits Enfants Féminins' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Féminins") }}>Habits Enfants Féminins</div>
                        </div>

                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(!showAlimentation); SetshowVetem(false); e.stopPropagation() }} className={`cursor-pointer flex  relative justify-between items-center  p-1 w-full h-12    pb-2  transition-all   ${category == "Les céréales" || category == 'Les légumineuses' || category == 'Les tubercules' || category == 'Les farines' || category == 'Les légumes' || category == 'Les fruits' ? "text-[#5dca32]" : ""} `}>
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                                <p className="ml-2">Alimentation</p>
                            </div>
                            <div className={`${showAlimentation ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                            </div>
                        </div>
                        <div className={` ${showAlimentation ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les céréales' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les céréales") }}>Les céréales</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les légumineuses' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumineuses") }}>Les légumineuses</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les tubercules' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les tubercules") }}>Les tubercules</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les farines' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les farines") }}>Les farines</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les fruits' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les fruits") }}>Les fruits</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les légumes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumes") }}>Les légumes</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les autres produits alimentaires' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les autres produits alimentaires") }}>Les autres produits alimentaires</div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(!showAccessoires); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex  relative justify-between items-center  p-1 w-full h-12    pb-2  transition-all   ${category == "Les valises" || category == 'Les sacs à main des femmes' || category == 'Les sacs à dos ' ? "text-[#5dca32]" : ""} `}>
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                                <p className="ml-2">Accessoires </p>
                            </div>
                            <div className={` ${showAccessoires ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                            </div>
                        </div>
                        <div className={` ${showAccessoires ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les valises' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les valises") }}>Les valises</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les sacs à main des femmes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à main des femmes") }}>Les sacs à main des femmes</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les sacs à dos ' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à dos ") }}>Les sacs à dos </div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(!showAppariels); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex  relative justify-between items-center  p-1 w-full h-12    pb-2  transition-all   ${category == "Les appareils électroniques" || category == 'Les appareils électroménagers' || category == 'Autres équipements de ménage' ? "text-[#5dca32]" : ""} `}>
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                                <p className="ml-2">Électronique et électroménage</p>
                            </div>
                            <div className={` ${showAppariels ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                            </div>
                        </div>
                        <div className={` ${showAppariels ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les appareils électroniques' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroniques") }}>Les appareils électroniques</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les appareils électroménagers' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroménagers") }}>Les appareils électroménagers</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Autres équipements de ménage' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres équipements de ménage") }}>Autres équipements de ménage</div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(!showMobilier); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex  relative justify-between items-center  p-1 w-full h-12    pb-2  transition-all   ${category == "Matériels de décors" || category == 'Les salons' || category == 'Les salles à manger' || category == 'Les lits' || category == 'Les salons des visiteurs' || category == 'Autres produits de menuiserie et soudure' ? "text-[#5dca32]" : ""} `}>
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                                <p className="ml-2">Mobilier</p>
                            </div>
                            <div className={` ${showMobilier ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                            </div>
                        </div>
                        <div className={` ${showMobilier ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Matériels de décors' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Matériels de décors") }}>Matériels de décors</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les salons' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons") }}>Les salons</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les salles à manger' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salles à manger") }}>Les salles à manger</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les lits' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les lits") }}>Les lits</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les salons des visiteurs' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons des visiteurs") }}>Les salons des visiteurs</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Autres produits de menuiserie et soudure' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres produits de menuiserie et soudure") }}>Autres produits de menuiserie et soudure</div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(!showVéhicules); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex  relative justify-between items-center  p-1 w-full h-12    pb-2  transition-all   ${category == "Les automobiles" || category == 'Les motos et bicyclettes' ? "text-[#5dca32]" : ""} `}>
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                                <p className="ml-2">Véhicules</p>
                            </div>
                            <div className={`${showVéhicules ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                            </div>
                        </div>
                        <div className={` ${showVéhicules ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les automobiles' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les automobiles") }}>Les automobiles</div>
                            <div className={`cursor-pointer pl-2 mr-1 ${category == 'Les motos et bicyclettes' ? 'text-[#5dca32] bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les motos et bicyclettes") }}>Les motos et bicyclettes</div>
                        </div>

                        <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("photographie") }} className={`cursor-pointer flex   justify-start items-center  p-1 w-full h-12    pb-2  transition-all  ${category === "photographie" ? "text-[#5dca32]" : ""}`} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                            <p className="ml-2">Photographie</p>
                        </div>
                        <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("videographie") }} className={`cursor-pointer flex   justify-start items-center  p-1 w-full h-12    pb-2  transition-all  ${category === "videographie" ? "text-[#5dca32]" : ""}`} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                            <p className="ml-2">Vidéographie</p>
                        </div>
                        <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("design") }} className={`cursor-pointer flex   justify-start items-center  p-1 w-full h-12    pb-2  transition-all  ${category === "design" ? "text-[#5dca32]" : ""}`} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                            <p className="ml-2">Design</p>
                        </div>
                    </div>
                </div>
            }
            <div className='w-full'>
                <input className='p-2 bg-transparent w-full border rounded focus:border-[#5dca32] outline-none' placeholder='Rechercher le produit....' />
            </div>
            <div className='mx-2'>
                <select className='p-2 bg-transparent rounded cursor-pointer border border-[#5dca32]'>
                    <option className='text-black' value={5}>5 produits</option>
                    <option className='text-black' value={5}>10 produits</option>
                    <option className='text-black' value={5}>12 produits</option>
                    <option className='text-black' value={5}>20 produits</option>
                    <option className='text-black' value={5}>24 produits</option>
                    <option className='text-black' value={5}>30 produits</option>
                    <option className='text-black' value={5}>36 produits</option>
                    <option className='text-black' value={5}>40 produits</option>
                    <option className='text-black' value={5}>48 produits</option>
                    <option className='text-black' value={5}>50 produits</option>
                    <option className='text-black' value={5}>60 produits</option>
                    <option className='text-black' value={5}>70 produits</option>
                    <option className='text-black' value={5}>72 produits</option>
                </select>
            </div>
        </div>
    )
}

export default NavBarsImmobilier