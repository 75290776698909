import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useThemes } from '../../../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';


function HotelsCourant() {
    const { isDark, hoteData, GetHotelDetail } = useThemes()
    const navig = useNavigate()

    return (
        <div>
            <div className='text-lg font-bold pl-3 my-5'> Hôtel qui vous intéresse</div>
            <Swiper
                slidesPerView={5}
                loop={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                spaceBetween={0}
                className="HotelCournat "
            >
                {hoteData.map((data, index) => (
                    <SwiperSlide data-position={index} key={index}>
                        <div onClick={() => { GetHotelDetail(data); navig("/Hotel/detail") }} className='containerImages'>
                            <img
                                src={data.url} alt="  "
                                onLoad={() => {
                                    document.querySelector(`[data-position="${index}"] .spinners`).style.display = 'none';
                                }}
                            />
                            <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinners`}>
                                <svg
                                    className="animate-spin h-6 w-6 text-[#5dca32]"
                                    viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className='text-white bg-[#00000071] px-2 py-1 rounded font-extrabold absolute top-[85%] left-2 text-lg'>
                            {data.name}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default HotelsCourant