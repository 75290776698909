import React from 'react'
import Evaluations from './Evaluations'
function ConfidentEvalu() {

    return (
        <>
            <div>
                <div>
                    <div className='text-[25px] font-bold '>Politique de Livraison et Remboursement</div>
                    <div >
                        Si votre commande n'est pas livrée dans le délai de livraison promis, vous avez droit à un remboursement de 100% du montant de votre achat.
                        <br />
                        <br />
                        Pour bénéficier de ce remboursement, veuillez nous contacter dès que possible après la date de livraison prévue, en fournissant les détails de votre commande.
                        <br />
                        <br />
                        Nous examinerons votre demande et, une fois vérifiée, nous procéderons au remboursement dans les plus brefs délais.
                    </div>
                    <div className='text-[25px] font-bold mt-5 '>Contact</div>
                    Pour toute question ou pour initier une demande de remboursement, veuillez nous contacter
                </div>
            </div>
            <div className='flex'>
                <div className=' w-full border border-dashed border-[#5dca32] p-2 rounded  mx-0.5'>
                    <div className='text-center'>Nous espérons que vous avez apprécié votre expérience sur notre site. Votre satisfaction est notre priorité, et nous nous efforçons constamment d'améliorer nos services.</div>
                    <Evaluations />
                </div>
            </div>
            <div className='mt-5 text-center'>
                Nous vous remercions de votre confiance et espérons que vous apprécierez votre expérience d'achat avec nous.
            </div>
        </>
    )
}

export default ConfidentEvalu