/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Badge, Sidenav } from 'rsuite';
import { useThemes } from '../../../UserContext/UserContext';
import TranslateComponent from '../../TranslateComponent/TranslateComponent';

gsap.registerPlugin(ScrollTrigger);

function NavBars() {
    const { pathname } = useLocation()
    const { toggleDarkMode, isDark, cart, showNotifcation, GetShowNotifcation, howMessage, GetShowMessage } = useThemes()
    const navig = useNavigate()

    let acceuil = /^\/acceuil.*/
    let Immobilier = /^\/Immobilier.*/
    let Hotel = /^\/Hotel.*/
    let tourisVoyage = /^\/tourisVoyage.*/
    let propos = /^\/propos.*/



    const [menu, SetMenue] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth < 1170);
    const [mobile11, setMobile11] = useState(window.innerWidth < 970);
    const [mobile12, setMobile12] = useState(window.innerWidth < 805);
    const [mobile13, setMobile13] = useState(window.innerWidth < 495);
    const [mobile1, setMobile1] = useState(window.innerWidth < 374);


    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 1170);
            setMobile1(window.innerWidth < 374);
            setMobile11(window.innerWidth < 970);
            setMobile12(window.innerWidth < 805);
            setMobile13(window.innerWidth < 495);
            SetMenue(false);
        };

        const handleClick = () => {
            SetMenue(false);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', handleClick);
        };
    }, []);


    useEffect(() => {
        const showAnim = gsap.from('.scrollToBottomNavBars', {
            yPercent: -100,
            paused: true,
            duration: 0.2
        }).progress(1);

        ScrollTrigger.create({
            start: "top top",
            end: "max",
            onUpdate: (self) => {
                self.direction === -1 ? showAnim.play() : showAnim.reverse();
            }
        });
    }, []);


  

    return (
        <div className={`flex justify-between scrollToBottomNavBars  ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'} fixed  z-[100] items-center px-2  border-b border-[#5dca32] w-full h-[10vh]`}>
            <Link to='/' className='flex h-full items-center hover:no-underline focus:no-underline'>
                <div className={`font-serif   ${mobile1 ? 'hidden' : mobile13 ? 'text-[15px]' : 'text-[30px]'} `}>
                    <div class="flex justify-center text-xl font-serif">
                        <span class="bg-gradient-to-r from-white to-red-500 bg-clip-text text-transparent mx-1">Agence</span>
                        <span class="bg-gradient-to-tr from-red-500 to-green-500 bg-clip-text text-transparent mx-1">Burundi</span>
                        <span class="bg-gradient-to-r from-green-500 to-white bg-clip-text text-transparent mx-1">Paradise</span>
                    </div>
                </div>
            </Link>
            <div className='h-full w-max flex'>
                <div className={`flex  ${mobile ? 'items-center' : 'items-end'}`}>
                    <div className={`flex flex-col `}>

                        <div className={` ${mobile ? 'hidden' : 'flex '} items-end `}>
                            {acceuil.test(pathname) || pathname == '/' ? (
                                <Link to='/' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline border-b-2 border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32]  rounded-t-lg'>
                                    Acceuil
                                </Link>
                            ) : (
                                <Link to='/' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline  mx-1 text-[17px] rounded p-2  '>
                                    Acceuil
                                </Link>
                            )}

                            {Immobilier.test(pathname) ? (
                                <Link to='/Immobilier' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline   border-b-2 border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32] rounded-t-lg'>
                                    Tous
                                </Link>
                            ) : (
                                <Link to='/Immobilier' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline text-[17px] mx-1 rounded p-2 '>
                                    Tous
                                </Link>
                            )}

                            {Hotel.test(pathname) ? (
                                <Link to='/Hotel' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline   border-b-2 border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32] rounded-t-lg'>
                                    Hôtel
                                </Link>
                            ) : (
                                <Link to='/Hotel' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline text-[17px] mx-1 rounded p-2 '>
                                    Hôtel
                                </Link>
                            )}



                            {tourisVoyage.test(pathname) ? (
                                <Link to='/tourisVoyage' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline   border-b-2 border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32] rounded-t-lg'>
                                    Tourisme
                                </Link>
                            ) : (
                                <Link to='/tourisVoyage' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline text-[17px] mx-1 rounded p-2 '>
                                    Tourisme
                                </Link>
                            )}


                            {propos.test(pathname) ? (
                                <Link to='/propos' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline   border-b-2 border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32] rounded-t-lg'>
                                    Réservation
                                </Link>
                            ) : (
                                <Link to='/propos' className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline text-[17px] mx-1 rounded p-2 '>
                                    Réservation
                                </Link>
                            )}
                            <div className='w-max h-max px-3 py-1 hover:no-underline focus:no-underline text-[17px] mx-1 cursor-pointer'></div>
                        </div>
                    </div>
                    <div className={`w-[14em] ${menu ? 'right-0' : '-right-full'} transition-all fixed z-[200000000000] top-[8vh]  h-full  border-r-2 border-orange-300  flex-col items-center ${mobile ? 'flex' : 'hidden'} `}>
                        <Sidenav>
                            <div className="w-full  h-[92vh] overflow-x-hidden overflow-y-auto">

                                <div className="w-full h-[100%] flex flex-col">

                                    {acceuil.test(pathname) || pathname == '/' ? (
                                        <Link to='/' className='w-full h-max px-3 mt-1 py-1 border-l-2  border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32]'>
                                            Acceuil
                                        </Link>
                                    ) : (
                                        <Link to='/' className='w-full h-max px-3 mt-1 py-1  mx-1 text-[17px] rounded p-2  '>
                                            Acceuil
                                        </Link>
                                    )}


                                    {Immobilier.test(pathname) ? (
                                        <Link to='/Immobilier' className='w-full h-max px-3 mt-1 py-1 border-l-2  border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32]'>
                                            Tous
                                        </Link>
                                    ) : (
                                        <Link to='/Immobilier' className='w-full h-max px-3 mt-1 py-1  mx-1 text-[17px] rounded p-2  '>
                                            Tous
                                        </Link>
                                    )}

                                    {Hotel.test(pathname) ? (
                                        <Link to='/Hotel' className='w-full h-max px-3 mt-1 py-1 border-l-2  border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32]'>
                                            Hôtel
                                        </Link>
                                    ) : (
                                        <Link to='/Hotel' className='w-full h-max px-3 mt-1 py-1  mx-1 text-[17px] rounded p-2  '>
                                            Hôtel
                                        </Link>
                                    )}
                                    {tourisVoyage.test(pathname) ? (
                                        <Link to='/tourisVoyage' className='w-full h-max px-3 mt-1 py-1 border-l-2  border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32]'>
                                            Tourisme
                                        </Link>
                                    ) : (
                                        <Link to='/tourisVoyage' className='w-full h-max px-3 mt-1 py-1  mx-1 text-[17px] rounded p-2  '>
                                            Tourisme
                                        </Link>
                                    )}

                                    {propos.test(pathname) ? (
                                        <Link to='/propos' className='w-full h-max px-3 mt-1 py-1 border-l-2  border-[#5dca32] text-[17px] mx-1 cursor-pointer text-[#5dca32] focus:text-[#5dca32] hover:text-[#5dca32]'>
                                            Réservation
                                        </Link>
                                    ) : (
                                        <Link to='/propos' className='w-full h-max px-3 mt-1 py-1  mx-1 text-[17px] rounded p-2  '>
                                            Réservation
                                        </Link>
                                    )}

                                </div>
                            </div>
                        </Sidenav>
                    </div>
                </div>

                <div className='h-full  flex items-center'>
                    <TranslateComponent />
                    <button onClick={() => { GetShowMessage(!howMessage) }} className='mx-1 relative bg-[#5dca322d] hover:bg-[#5dca3244] transition-all p-2 rounded cursor-pointer'>
                        <Badge content={3} className='absolute -top-1 text-[10px] -right-1' />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-chat-dots-fill" viewBox="0 0 16 16">
                            <path d="M16 8c0 3.866-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                        </svg>
                    </button>
                    <button onClick={() => { GetShowNotifcation(!showNotifcation) }} className='mx-1 relative bg-[#5dca322d] hover:bg-[#5dca3244] transition-all p-2 rounded cursor-pointer'>
                        <Badge content={9} className='absolute -top-1 text-[10px] -right-1' />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-bell-fill" viewBox="0 0 16 16">
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                        </svg>
                    </button>
                    <button onClick={() => { navig('/Immobilier/card') }} disabled={cart.length == 0} className='mx-1 relative bg-[#5dca322d] hover:bg-[#5dca3244] transition-all p-2 rounded cursor-pointer'>
                        <Badge content={cart.length} className='absolute -top-1 text-[10px] -right-1' />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-cart3" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                    </button>

                    <div className={`w-max bg-green-50 mx-1  hover:bg-green-100 cursor-pointer text-[#5dca32] ${mobile1 ? 'p-1 h-8' : 'p-2'} rounded`}>
                        Se connecter
                    </div>

                    <div onClick={toggleDarkMode} className='relative  p-1 ml-2 h-max'>
                        {isDark === "dark" ?
                            <div className='p-2  bg-green-100 hover:bg-green-300 cursor-pointer text-[#5dca32] rounded-lg'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill animate-spin" viewBox="0 0 16 16">
                                    <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                                </svg>
                            </div>
                            :
                            <div className='p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#5dca32] rounded-lg'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon-fill" viewBox="0 0 16 16">
                                    <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                                </svg>
                            </div>
                        }
                    </div>
                    <div
                        className={`sm:mx-2 mx-0.5 cursor-pointer h-max relative top-2 p-1 text-white rounded bg-gradient-to-r from-[#5dca32] to-[#8cff5e] hover:from-[#5ee429] hover:to-[#32631e] transition-all ${mobile ? 'block' : 'hidden'}`}>

                        <div onClick={(e) => {
                            SetMenue(!menu);
                            e.stopPropagation()
                        }} className="">

                            {menu ? (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBars








