/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'
import FootentContent from '../FootentContent/FootentContent'
import AjouterCartInform from './AjouterCartInform'
import { Button, Modal, Popover, Whisper } from 'rsuite'
import AddressLivraison from './AddressLivraison'
import FactureProduits from './FactureProduits'


function OurPaymentMethod() {
    const navig = useNavigate()
    const { expedition, cart, total, removeFromCart, increaseAmount, decreaseAmount, isDark, charite, setcharite } = useThemes()
    const [desable, Setdesable] = useState(false)
    const [showFacture, SetshowFacture] = useState(false)



    const elemetRefcharite = useRef(null)

    useEffect(() => {
        if (cart.length == 0) {
            navig('/Immobilier')
        }
    }, [cart.length])

    const [url, GetUrl] = useState('')
    const [zoomImage, SetzoomImage] = useState(false)
    const [carts, GetCarts] = useState(false);
    const [ShowMoyenPayement, GetShowMoyenPayement] = useState(false);
    const [isLivraisonSelected, setIsLivraisonSelected] = useState(false);



    return (
        <div className='mt-[10vh] '>
            <div className="h-max  w-full">
                <div className={` justify-center flex`}>
                    <div className={`rounded-lg w-[80%]  p-2`}>
                        <div className={`h-max w-full  shadow-md p-3 mb-2  rounded `}>
                            <div className="font-bold text-[20px]">
                                Votre choix de livraison
                            </div>
                            <div className='flex mt-3'>
                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                    <input
                                        type="radio"
                                        name="paymentOption"
                                        checked={isLivraisonSelected}
                                        className="form-radio"
                                        onChange={(e) => setIsLivraisonSelected(e.target.checked)}
                                    />
                                    <div className="ml-2 flex items-center">
                                        <span>Achèter avec livraison</span>
                                    </div>
                                </label>
                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                    <input
                                        type="radio"
                                        name="paymentOption"
                                        value="sansLivraison"
                                        className="form-radio"
                                    />
                                    <div className="ml-2 flex items-center">
                                        <span>Achèter sans livraison</span>
                                    </div>
                                </label>
                            </div>
                            <Modal dialogClassName="pb-5" overflow={false} size='md' open={isLivraisonSelected} onClose={() => setIsLivraisonSelected(false)}>
                                <Modal.Header>
                                    <div className="flex items-center justify-between">
                                        <h4 className="font-bold sm:text-3xl max-sm:text-[20px] text-center text-[#5dca32]">
                                            Expédition et livraison Agence Burundi Paradise
                                        </h4>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='h-max mb-5'>
                                        <AddressLivraison />
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div class={`flex flex-col  mb-5   w-full`}>
                            <label className="text-lg mb-1">Charité</label>
                            <div className="relative w-full">
                                <input
                                    type="number"
                                    className="p-2 w-full bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                    value={charite} ref={elemetRefcharite} onInput={(e) => setcharite(e.target.value)}
                                    placeholder="Charité" />
                                <div className="absolute top-2.5 bg-yellow-100 right-1 cursor-pointer">
                                    <Whisper
                                        trigger="click"
                                        placement="left"
                                        speaker={
                                            <Popover className="text-nowrap whitespace-break-spaces">
                                                La <span className="font-bold">charité</span> est un concept qui<br />
                                                fait référence à l'action de<br />
                                                donner de manière désintéressée pour aider <br /> les autres,
                                                en particulier ceux  qui sont dans <br /> le besoin
                                                ou qui sont moins fortunés.<br />
                                            </Popover>}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi text-yellow-600 bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                        </svg>
                                    </Whisper>
                                </div>
                            </div>
                        </div>



                        <div className={`h-max w-full  shadow-md p-3 mb-5 rounded`}>
                            <div className="font-bold text-[20px]">
                                Systèmes de paiement
                            </div>
                            <div className="">
                                <div onClick={() => GetCarts(true)} className="flex  w-max p-0.5 items-center my-1 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                    </svg>
                                    <label htmlFor="cartes" className="flex items-center cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cassette" viewBox="0 0 16 16">
                                            <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H7Z" />
                                            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13ZM1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.691l-1.362-2.724A.5.5 0 0 0 12 10H4a.5.5 0 0 0-.447.276L2.19 13H1.5a.5.5 0 0 1-.5-.5v-9ZM11.691 11l1 2H3.309l1-2h7.382Z" />
                                        </svg>
                                        <div className="ml-1">
                                            Ajouter une carte
                                        </div>
                                    </label>
                                </div>
                                <Modal overflow={false} size='xs' open={carts} onClose={() => GetCarts(false)}>
                                    <Modal.Header>
                                        <div className="flex justify-center w-full">
                                            <p className=" text-center text-[#5dca32]">
                                                Nous assurons la sécurité de vos données de paiement.
                                            </p>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className={` h-max rounded-2xl  p-3 `}>
                                            <AjouterCartInform GetCarts={GetCarts} />
                                        </div>

                                    </Modal.Body>
                                </Modal>

                                <div className="flex flex-col items-start my-1">
                                    <div className="flex items-center  p-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                        </svg>
                                        <label htmlFor="image" className="flex items-center cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                            </svg>
                                            <input type="file" hidden
                                                onChange={(e) => GetUrl(URL.createObjectURL(e.target.files[0]))}
                                                id="image" />
                                            <div className="ml-1">
                                                Image de preuve
                                            </div>
                                        </label>
                                    </div>
                                    {url !== '' &&
                                        <div onClick={() => SetzoomImage(true)} className="w-[3em] ml-5 h-[3em] cursor-pointer rounded border  bg-white overflow-hidden">
                                            <img src={`${url}`} className="w-full h-full object-contain object-center" />
                                        </div>
                                    }

                                    {zoomImage && <div className="w-full top-0 p-2  left-0 fixed bg-[#00000085] flex z-[1000] overflow-y-auto h-full  justify-center">
                                        <div className={`w-[95%] h-[95vh] p-2 relative rounded-2xl overflow-hidden `}>
                                            <div className="w-full pr-2 flex justify-end mt-3 absolute right-1">
                                                <div onClick={() => SetzoomImage(false)} className="bg-red-500 p-2 rounded-full text-white cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-full h-full">
                                                <img className={`w-full h-full object-contain`} src={`${url}`} />
                                            </div>
                                        </div>
                                    </div>
                                    }



                                </div>
                                <div
                                    onClick={() => GetShowMoyenPayement(!ShowMoyenPayement)}
                                    className="flex items-center p-1 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                    </svg>

                                    <label htmlFor="autreMoyen" className="flex items-center p-1 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                                            <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                            <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
                                        </svg>
                                        <div className='ml-1'>
                                            Autre moyen
                                        </div>
                                    </label>
                                </div>
                                <div className={`pl-5 transition-all ${ShowMoyenPayement ? 'h-[14em]' : 'h-0 overflow-hidden'}`}>
                                    <div class="space-y-1">
                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio" />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/lumitel.png' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Lumicash</span>
                                            </div>
                                        </label>


                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/ecocashe.jpg' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Ecocash</span>
                                            </div>
                                        </label>

                                        <label class="flex items-center  w-max p-1 cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/feza.png' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Feza</span>
                                            </div>
                                        </label>

                                        <label class="flex items-center  w-max p-1 cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/mobinotel.png' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Mobinotel</span>
                                            </div>
                                        </label>


                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/enoti.jpg' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Bancobu eNoti</span>
                                            </div>
                                        </label>


                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/New-logo-CECM-01.jpg' className="w-full h-full object-contain" />
                                                </div>
                                                <span>CECM Akaravyo</span>
                                            </div>
                                        </label>

                                    </div>
                                </div>

                            </div>
                        </div>



                        <div className={` rounded-lg  w-full border  p-2`}>
                            <div >Votre produit: {cart.length} {1 == 2 ? 'article' : 'articles'}</div>
                            {cart.map((data, index) => (
                                <div data-position={index} key={index} className={` relative flex ${(index + 1) === cart.length ? '' : 'border-b'}    m-2  p-2 full `}>
                                    <div class={`z-[1000] flex absolute top-0 left-0 w-full p-2   justify-end`}>
                                        <svg onClick={() => removeFromCart(data.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi cursor-pointer h-4 w-4 bi-trash3-fill" viewBox="0 0 16 16">
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                        </svg>
                                    </div>
                                    <div className={` p-2 relative block overflow-hidden    w-[10em] h-[10em]`}>
                                        <img
                                            onLoad={() => {
                                                document.querySelector(`[data-position="${index}"] .spinne`).style.display = 'none';
                                            }}
                                            src={data.url}
                                            className="h-full object-cover   rounded-lg w-full"
                                        />
                                        <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinne`}>
                                            <svg
                                                className="animate-spin h-8 w-8 text-[#5dca32]"
                                                viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className={` relative  ml-3  h-full w-[85%] overflow-hidden `}>
                                        <div className={` text-gray-800'} `}>
                                            <div className="flex flex-row justify-between ">
                                                <div className="font-bold ">
                                                    {data.title}
                                                </div>
                                            </div>
                                            <div className="space-x-2">
                                                Categories : {data.category}
                                            </div>
                                            <div className="flex items-baseline mb-1 space-x-2 font-roboto">
                                                <div >Prix:</div>
                                                <div className={` text-[#5dca32] font-semibold`}>
                                                    {data && data.price.toFixed(0)}Fbu
                                                </div>
                                                <div className={` md:text-base ml-3 text-right  text-red-400 line-through`}>
                                                    {data && (data.price + (data.price * 20) / 100).toFixed(0)}Fbu
                                                </div>
                                            </div>
                                            <div className="space-x-2  w-full">
                                                {data.description}
                                            </div>
                                            <div className="">
                                                Disponibilité: {data.qty}
                                            </div>
                                        </div>
                                        <div className="mt-4  flex">
                                            <div className="flex border border-gray-300 divide-x divide-gray-300 w-max">
                                                <button
                                                    onClick={() => {
                                                        if (data.amount == 1) {
                                                            Setdesable(false)
                                                            removeFromCart(data.id)
                                                        } else {
                                                            decreaseAmount(data.id)
                                                            Setdesable(false)
                                                        }
                                                    }}
                                                    class="h-6  w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none">
                                                    -
                                                </button>
                                                <div className="h-6 w-6 md:h-8 md:w-8 text-base flex items-center justify-center">
                                                    {data.amount}
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        if (data.amount < data.qty) {
                                                            increaseAmount(data.id)
                                                            Setdesable(false)
                                                        }
                                                        else {
                                                            Setdesable({ ...desable, [data.id]: true });
                                                            toast.warning("Vous avez atteint la quantité maximale disponible pour ce produit");
                                                        }
                                                    }}
                                                    disabled={desable[data.id]}
                                                    className={`${desable[data.id] ? 'opacity-30' : 'opacity-100'}  h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none`}>
                                                    +
                                                </button>
                                            </div>

                                            <div className="flex  justify-end w-full">
                                                <div class="flex   mb-1 font-roboto ">
                                                    <p class="text-xl text-primary font-semibold">
                                                        {(data.price * data.amount).toLocaleString()} Fbu
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={` sticky top-[10.5vh]    h-full px-2 sm:px-8 py-2 rounded-lg border shadow-2xl  mx-3`}>
                        <div className="flex w-full max-sm:text-xl text-[#5dca32] font-bold text-2xl mb-5">Sommaire</div>
                        <hr className="my-4" />
                        <div className="flex justify-between mb-4">
                            <p className="text-lg font-bold">Sous total</p>
                            <div className="">
                                <p className=""> {total.toLocaleString()} Fbu</p>
                            </div>
                        </div>
                        <div className="flex justify-between mb-4">
                            <p className="text-lg font-bold">Charité</p>
                            <p className="">{parseInt(charite).toLocaleString()}Fbu</p>
                        </div>
                        <div className="flex justify-between mb-4">
                            <p className="text-lg font-bold">Expédition</p>
                            <p className="">{expedition} Fbu</p>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-lg font-bold">Total</p>
                            <p className="font-bold">{(total + parseInt(charite) + parseInt(expedition)).toLocaleString()} Fbu</p>
                        </div>
                        <div
                            to="/Orders/Paiement"
                            class="block w-full mt-6 py-2  text-center cursor-pointer text-white bg-[#5dca32] border border-[#5dca32] rounded-2xl hover:bg-transparent hover:text-[#5dca32] transition"
                        >
                            Commande
                        </div>
                        <button onClick={() => SetshowFacture(true)}>SetshowFacture</button>
                        <div className="w-full flex justify-center">
                            <div className="w-[20em] mt-3 text-center text-gray-400">
                                Agence Burundi Paradise protège vos informations et vos paiements
                            </div>
                        </div>
                    </div>
                </div>
                {showFacture && <FactureProduits />}
                <FootentContent />
            </div>
        </div>
    )
}

export default OurPaymentMethod