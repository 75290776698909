import React, { useEffect, useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import QrCode from './QrCode';

function FactureProduits() {
    const { cart, total, charite, expedition } = useThemes()

    const [quantite, setQuantiteTotal] = useState(0)
    useEffect(() => {
        const total = cart.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.amount;
        }, 0);
        setQuantiteTotal(total);
    }, [cart]);


    return (

        <div class="p-2 border w-[40em]">
            <div class="text-2xl font-bold mb-4 w-full text-center text-[#5dca32]">FACTURE</div>
            <div className="flex justify-between">
                <div class="mb-4">
                    <div class="font-bold">Informations du client:</div>
                    <div class=" m-2 flex flex-col">
                        <ldiv>Nom :NDAYIZEYE Télésphore</ldiv>
                        <ldiv>Adresse : Kinanira</ldiv>
                        <ldiv>Contact : 290302</ldiv>
                        <ldiv>Service : Produits</ldiv>
                        <ldiv>Paiement avec : Lumicash</ldiv>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="font-bold">Informations du campanie :</div>
                    <div class=" m-2 flex flex-col">
                        <div>Nom:Karorero Business company</div>
                        <div>Adresse : Bujumbura Maire de la Ville N_24</div>
                        <div>Contact : Karorero@agenceburundiparadise.com</div>
                        <div>NIF : 23.0223/34</div>
                        <div>Date :2/02/2020</div>
                    </div>
                </div>
            </div>

            <div class="mb-4 w-full">
                <div class="font-bold mt-2">Détails des produits </div>
                <table className="w-full p-1 border">
                    <thead>
                        <tr className="border">
                            <th className="border-r text-left p-1">Nom du produit</th>
                            <th className="border-r text-left p-1">Qauntite</th>
                            <th className="border-r text-left p-1">Prix unitaire</th>
                            <th className="border-r text-left p-1">Prix total</th>
                        </tr>
                    </thead>

                    <tbody>
                        {cart && cart.map((data, index) => (
                            <tr key={index}>
                                <td className="border-r border-b p-1">{data.title && data.title.length > 20 ? data.title.slice(0, 20) + ".." : data.title}</td>
                                <td className="border-r border-b p-1">{data.amount.toFixed(0)}</td>
                                <td className="border-r border-b p-1">{data.price.toFixed(0)}</td>
                                <td className="border-r border-b p-1">{(parseInt(data.price * data.amount)).toLocaleString()}</td>
                            </tr>
                        ))}
                        <tr className="border-b border-r">
                            <td className="border-r border-b p-1 font-bold">Qauntite total</td>
                            <td className="border-r border-b p-1">{quantite.toLocaleString()} cartons</td>
                            <td colSpan={2} className="border-r text-center font-bold">-</td>
                        </tr>

                        <tr className="border-b border-r">
                            <td className="border-r border-b p-1 font-bold">Total</td>
                            <td className="border-r border-b p-1">{cart.length} produits</td>
                            <td colSpan={2} className="border-r text-center font-bold">{total.toLocaleString()} fbu</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='flex justify-between items-center w-full'>
                <div>
                    <div class="mb-4">
                        <div class="font-bold">Sous-total : <span>{total.toLocaleString()} fbu</span></div>
                    </div>

                    <div class="mb-4">
                        <div class="font-bold">TVA : (2%) <span>{(total * 0.02).toFixed(0)} fbu</span> </div>
                    </div>

                    <div class="mb-4">
                        <div class="font-bold">Charité : <span>{parseInt(charite).toLocaleString()} fbu</span></div>
                    </div>

                    <div class="mb-4">
                        <div class="font-bold">Réduction : (3%) <span>{(total * 0.03).toFixed(0)} fbu</span></div>
                    </div>

                    <div class="mb-4">
                        <div class="font-bold">Expédition : <span>{parseInt(expedition).toLocaleString()} fbu</span></div>
                    </div>

                    <div class="mb-4">
                        <div class="font-bold">Montant total à payer : <span >{(parseInt(total) + (parseInt(total) * 0.02) + parseInt(charite) - (parseInt(total) * 0.03) + parseInt(expedition)).toLocaleString()} fbu</span></div>
                    </div>
                </div>
                <div>
                    <QrCode />
                </div>
            </div>
            <p class="mt-8">Merci d'avoir choisi notre service !</p>
        </div>
    )
}

export default FactureProduits