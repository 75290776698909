/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Routes, Route, Link, } from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
import './App.css'
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "apexcharts/dist/apexcharts.css";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'rsuite/dist/rsuite.min.css';
import 'react-multi-carousel/lib/styles.css';
import { ToastContainer } from 'react-toastify';

//.......................... Visiteurs...............................
import { useThemes } from "./UserContext/UserContext";
import { CustomProvider, Modal } from "rsuite";
import NavBars from './Compenent/Visiteurs/NavBars/NavBars';
import AcceuilleComponent from "./Compenent/Visiteurs/Acceuille/AcceuilleComponent";
import ImmobilesComponent from "./Compenent/Visiteurs/Immobiles/ImmobilesComponent";
import DetailProduits from "./Compenent/Visiteurs/Immobiles/DetailProduits";
import CardProduct from "./Compenent/Visiteurs/Immobiles/CardProduct";
import OurPaymentMethod from "./Compenent/Visiteurs/Immobiles/OurPaymentMethod";
import Hotels from "./Compenent/Visiteurs/Hotel/Hotels";
import VoyageurClient from "./Compenent/Visiteurs/Hotel/VoyageurClient";
import HotelDetail from "./Compenent/Visiteurs/Hotel/HotelDetail";
import PaymentHotel from "./Compenent/Visiteurs/Hotel/PaymentHotel";
import Tourisme from "./Compenent/Visiteurs/Tourisme/Tourisme";
import { useEffect } from "react";
import Notifica from "./Compenent/Visiteurs/Notification/Notifica";
import Messages from "./Compenent/Visiteurs/Message/Message";
import ConfidentEvalu from "./Compenent/Visiteurs/Immobiles/ConfidentEvalu";


function App() {

    useEffect(() => {
        const aos_init = () => {
            AOS.init({
                once: true,
                duration: 1000,
                easing: 'ease-out-cubic',
            });
        }

        window.addEventListener('load', () => {
            aos_init();
        });
    }, []);


    const { isDark, HundlwScrollTop, getWidth, isScroll, showNotifcation, howMessage, showConf, SetshowConf } = useThemes()
    useEffect(() => {
        if (showNotifcation) {
            document.documentElement.style.overflow = "hidden"
        } else if (howMessage) {
            document.documentElement.style.overflow = "hidden"
        } else {
            document.documentElement.style.overflow = "auto"
        }
    }, [showNotifcation, howMessage])

    return (
        <CustomProvider theme={isDark}>

            <div
                className='h-[3px] fixed  bg-[#59e63c] top-0   left-0 z-[2000]'
                style={{
                    width: `${getWidth * 100}%`,
                }}
            >
            </div>
            <NavBars />
            <Routes>
                <Route path="/" Component={AcceuilleComponent} />
                <Route path="/Immobilier" Component={ImmobilesComponent} />
                <Route path="/Immobilier/detail" Component={DetailProduits} />
                <Route path="/Immobilier/card" Component={CardProduct} />
                <Route path="/Immobilier/pay" Component={OurPaymentMethod} />
                <Route path="/Hotel" Component={Hotels} />
                <Route path="/Hotel/detail" Component={HotelDetail} />
                <Route path="/Hotel/PaymentHotel" Component={PaymentHotel} />
                <Route path="/tourisVoyage" Component={Tourisme} />
            </Routes>

            <div onClick={HundlwScrollTop}
                className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 top-[90%] bg-[#617a5c] hover:bg-[#59e63c] duration-75 text-white z-[30]`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
                    <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
                </svg>
            </div>
            <Notifica />
            <Messages />
            <Modal onClose={() => SetshowConf(false)} open={showConf}>
                <ConfidentEvalu />
            </Modal>
            <ToastContainer />

        </CustomProvider>
    )
}

export default App