/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';


function AjouterCartInform() {

    const [NumerCart, SetNumerCart] = useState('')
    const elemetRefNumerCart = useRef(null)

    const [Titulaire, SetTitulaire] = useState('')
    const elemetRefTitulaire = useRef(null)

    const [date, Setdate] = useState('')
    const elemetRefdate = useRef(null)

    const [VCC, setVCC] = useState('')
    const elemetRefVCC = useRef(null)

    function handleSubmit(e) {
        e.preventDefault()

        if (NumerCart.trim() === '') {
            toast.warning('Votre Numéro de la Carte est obligatoire !', {
                autoClose: 2000
            });
            elemetRefNumerCart.current.focus()
            return false;
        } else if (Titulaire.trim() === '') {
            toast.warning('Titulaire de la carte est obligatoire', {
                autoClose: 2000
            });
            elemetRefTitulaire.current.focus()
            return false;
        }
        else if (date == "") {
            toast.warning("Precise la date!", {
                autoClose: 2000
            });
            elemetRefdate.current.focus()
            return false;
        }
        else if (VCC.trim() == '') {
            toast.warning("la valeur de vérification de la carte est obligatoire !!", {
                autoClose: 2000
            });
            elemetRefVCC.current.focus();
            return false;
        }
    }

    return (
        <>
            <div className="">
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="flex flex-col  justify-center ">
                        <div className={`w-full-lg  flex flex-col  items-center`}>
                            <div className="px-1 w-full ">
                                <div className="flex items-center my-1 ">
                                    <div className="flex items-center flex-wrap">
                                        <div className=" flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cassette" viewBox="0 0 16 16">
                                                <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H7Z" />
                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13ZM1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.691l-1.362-2.724A.5.5 0 0 0 12 10H4a.5.5 0 0 0-.447.276L2.19 13H1.5a.5.5 0 0 1-.5-.5v-9ZM11.691 11l1 2H3.309l1-2h7.382Z" />
                                            </svg>
                                            <div className="ml-1">
                                                Ajouter une carte
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <div className="w-[2em] h-[2em]"><img className="w-full h-full object-contain bg-white" src='../image/VisaCart.png' /></div>
                                            <div className="w-[2em] h-[2em]"><img className="w-full h-full object-contain bg-white" src='../image/VisaCart.png' /></div>
                                            <div className="w-[2em] h-[2em]"><img className="w-full h-full object-contain bg-white" src='../image/VisaCart.png' /></div>
                                            <div className="w-[2em] h-[2em]"><img className="w-full h-full object-contain bg-white" src='../image/VisaCart.png' /></div>
                                            <div className="w-[2em] h-[2em]"><img className="w-full h-full object-contain bg-white" src='../image/VisaCart.png' /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="flex my-6 flex-col  w-full">
                                        <div className={` transition-all flex-col flex w-[100%] justify-between `}>
                                            <div class={`flex flex-col mb-2 h-11  w-full`}>
                                                <label>Numéro du carte</label>
                                                <input
                                                    className="p-2  bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                    ref={elemetRefNumerCart} value={NumerCart} onInput={(e) => SetNumerCart(e.target.value)}
                                                    placeholder=" Numéro du carte" />
                                            </div>

                                            <div class={`flex flex-col mt-5 h-11  w-full`}>
                                                <label>Titulaire de la carte</label>
                                                <input
                                                    className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                    ref={elemetRefTitulaire} value={Titulaire}
                                                    onInput={(e) => SetTitulaire(e.target.value)}
                                                    placeholder="Titulaire de la carte" />
                                            </div>
                                        </div>
                                        <div className={` transition-all flex-col flex w-[100%] justify-between`}>

                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label>Date</label>
                                                <input
                                                    className="bg-transparent p-2 outline-none rounded border focus:border-[#5dca32]"
                                                    type="date" value={date} ref={elemetRefdate} onInput={(e) => Setdate(e.target.value)}
                                                />
                                            </div>

                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label> Valeur de vérification de (VCC)</label>
                                                <input
                                                    className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                    value={VCC}
                                                    ref={elemetRefVCC}
                                                    onInput={(e) => setVCC(e.target.value)}
                                                    placeholder="Valeur de vérification " />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="inline-flex items-start text-gray-400">
                                    <label className="relative flex items-center rounded-full p-3" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>
                                    </label>
                                    <label className="mt-px text-center  select-none font-light" >
                                        La facturation de votre commande sera en Fbu.
                                    </label>
                                </div>
                                <div className="flex justify-end items-center mt-4">
                                    <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-[#5dca21]   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Confirme' className='cursor-pointer '></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AjouterCartInform;













