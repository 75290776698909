/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'
import { Modal } from 'rsuite'
import AjouterCartInformHotel from './AjouterCartInformHotel'
import FootentContent from '../FootentContent/FootentContent'

function PaymentHotel() {
    const navig = useNavigate()
    const {
        sommeHeader,
        hoteDetail,
        data, addRoom,
        totals,
        itemAmounts,
        payData,
        increaseAdults, decreaseAdults, increaseChildren, decreaseChildren, handleAgeChange, removeBlock, setChambre } = useThemes()
    const [desable, Setdesable] = useState(false)

    const [url, GetUrl] = useState('')
    const [zoomImage, SetzoomImage] = useState(false)
    const [carts, GetCarts] = useState(false);
    const [ShowMoyenPayement, GetShowMoyenPayement] = useState(false);

    console.log("payData", payData, "totals", totals, 'itemAmounts', itemAmounts)

    useEffect(() => {
        if (payData[0].chambre.length == 0) {
            navig('/Hotel')
            setChambre([{ adults: 1, children: 0, childAges: [] }])
        }
    }, [payData[0].chambre.length])

    // console.log("data", data)
    return (
        <div className='mt-[10vh]'>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/Hotel")} className="text-[#5dca32] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div className="h-max  w-full">
                <div className={` justify-center flex`}>
                    <div className={`rounded-lg w-[80%]  p-2`}>
                        <div className={`h-max w-full  shadow-md p-3 mb-5 rounded`}>
                            <div className="font-bold text-[20px]">
                                Systèmes de paiement
                            </div>
                            <div className="">
                                <div onClick={() => GetCarts(true)} className="flex  w-max p-0.5 items-center my-1 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                    </svg>
                                    <label htmlFor="cartes" className="flex items-center cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cassette" viewBox="0 0 16 16">
                                            <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H7Z" />
                                            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13ZM1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.691l-1.362-2.724A.5.5 0 0 0 12 10H4a.5.5 0 0 0-.447.276L2.19 13H1.5a.5.5 0 0 1-.5-.5v-9ZM11.691 11l1 2H3.309l1-2h7.382Z" />
                                        </svg>
                                        <div className="ml-1">
                                            Ajouter une carte
                                        </div>
                                    </label>
                                </div>
                                <Modal overflow={false} size='xs' open={carts} onClose={() => GetCarts(false)}>
                                    <Modal.Header>
                                        <div className="flex justify-center w-full">
                                            <p className=" text-center text-[#5dca32]">
                                                Nous assurons la sécurité de vos données de paiement.
                                            </p>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className={` h-max rounded-2xl  p-3 `}>
                                            <AjouterCartInformHotel GetCarts={GetCarts} />
                                        </div>

                                    </Modal.Body>
                                </Modal>

                                <div className="flex flex-col items-start my-1">
                                    <div className="flex items-center  p-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                        </svg>
                                        <label htmlFor="image" className="flex items-center cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                            </svg>
                                            <input type="file" hidden
                                                onChange={(e) => GetUrl(URL.createObjectURL(e.target.files[0]))}
                                                id="image" />
                                            <div className="ml-1">
                                                Image de preuve
                                            </div>
                                        </label>
                                    </div>
                                    {url !== '' &&
                                        <div onClick={() => SetzoomImage(true)} className="w-[3em] ml-5 h-[3em] cursor-pointer rounded border  bg-white overflow-hidden">
                                            <img src={`${url}`} className="w-full h-full object-contain object-center" />
                                        </div>
                                    }

                                    {zoomImage && <div className="w-full top-0 p-2  left-0 fixed bg-[#00000085] flex z-[1000] overflow-y-auto h-full  justify-center">
                                        <div className={`w-[95%] h-[95vh] p-2 relative rounded-2xl overflow-hidden `}>
                                            <div className="w-full pr-2 flex justify-end mt-3 absolute right-1">
                                                <div onClick={() => SetzoomImage(false)} className="bg-red-500 p-2 rounded-full text-white cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-full h-full">
                                                <img className={`w-full h-full object-contain`} src={`${url}`} />
                                            </div>
                                        </div>
                                    </div>
                                    }



                                </div>
                                <div
                                    onClick={() => GetShowMoyenPayement(!ShowMoyenPayement)}
                                    className="flex items-center p-1 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                    </svg>

                                    <label htmlFor="autreMoyen" className="flex items-center p-1 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                                            <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                            <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
                                        </svg>
                                        <div className='ml-1'>
                                            Autre moyen
                                        </div>
                                    </label>
                                </div>
                                <div className={`pl-5 transition-all ${ShowMoyenPayement ? 'h-[14em]' : 'h-0 overflow-hidden'}`}>
                                    <div class="space-y-1">
                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio" />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/lumitel.png' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Lumicash</span>
                                            </div>
                                        </label>


                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/ecocashe.jpg' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Ecocash</span>
                                            </div>
                                        </label>

                                        <label class="flex items-center  w-max p-1 cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/feza.png' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Feza</span>
                                            </div>
                                        </label>

                                        <label class="flex items-center  w-max p-1 cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/mobinotel.png' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Mobinotel</span>
                                            </div>
                                        </label>


                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/enoti.jpg' className="w-full h-full object-contain" />
                                                </div>
                                                <span>Bancobu eNoti</span>
                                            </div>
                                        </label>


                                        <label class="flex items-center  w-max p-1  cursor-pointer">
                                            <input type="radio" name="paymentOption" class="form-radio " />
                                            <div className="ml-2 flex items-center">
                                                <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                    <img src='../image/New-logo-CECM-01.jpg' className="w-full h-full object-contain" />
                                                </div>
                                                <span>CECM Akaravyo</span>
                                            </div>
                                        </label>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={` rounded-lg  w-full border  p-2`}>
                            <div >Votre demande: {payData[0].chambre.length} {payData[0].chambre.length > 1 ? 'chambres' : 'chambre'}</div>
                            {payData.map((data, index) => (
                                <div key={index}>
                                    <div>
                                        <div className='flex'>
                                            <div className={` p-2 relative block overflow-hidden    w-[10em] h-[10em]`}>
                                                <img className="h-full object-cover   rounded-lg w-full" src={data.url} alt='   ' />
                                            </div>
                                            <div>
                                                <div className='text-[20px] font-bold'>{data.name}</div>
                                                <div className='text-[15px] mt-[1px] first-letter:uppercase'>{data.city},{data.location.address}</div>
                                                <div className='text-[15px] mt-[1px] first-letter:uppercase'>Chambre disponible: {data.availableRooms}</div>
                                                <div className='text-[15px] mt-[1px] first-letter:uppercase'> Date d'entree :{data.dateDepart}</div>
                                                <div className='text-[15px] mt-[1px] first-letter:uppercase'> Date de sortir :{data.dateEnd}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {data.chambre.map((datachambre, indexchambre) => (
                                        <div data-position={indexchambre} key={indexchambre} className={` border-[1px] rounded relative flex ${(index + 1) === data.chambre.length ? '' : 'border-b'}    m-2  p-2 full `}>
                                            <div class={`z-[100] flex absolute top-0 left-0 w-full p-2   justify-end`}>
                                                <svg onClick={() => removeBlock(indexchambre)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi cursor-pointer h-4 w-4 bi-trash3-fill" viewBox="0 0 16 16">
                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                </svg>
                                            </div>
                                            <div className="sm:mt-5 w-full    p-3">
                                                <div className='text-xl mb-5 font-bold'>Chambre {indexchambre + 1} </div>
                                                <div className='flex justify-center flex-col'>
                                                    <div className='flex items-center'>
                                                        <div className="text-lg">Adultes</div>
                                                        <div className="flex ml-3 border border-gray-300 divide-x divide-gray-300 w-max">
                                                            <button
                                                                className={`h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                                                onClick={() => {
                                                                    if (datachambre.adults == 1) {
                                                                        removeBlock(indexchambre)
                                                                    } else {
                                                                        decreaseAdults(indexchambre)
                                                                    }
                                                                }}
                                                            >
                                                                -
                                                            </button>
                                                            <div className="h-6 w-6 text-base flex items-center justify-center">
                                                                {datachambre.adults}
                                                            </div>
                                                            <button

                                                                className={`opacoty-100 h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                                                onClick={() => { increaseAdults(indexchambre) }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className='flex items-start mt-2'>
                                                        <div>
                                                            <div className="text-lg">Enfants</div>
                                                            <div className="text-xs text-gray-500">0 à 17 ans</div>
                                                        </div>
                                                        <div className="flex mt-1 ml-3 border border-gray-300 divide-x divide-gray-300 w-max">
                                                            <button
                                                                className="h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none"
                                                                onClick={() => decreaseChildren(indexchambre)}
                                                            >
                                                                -
                                                            </button>
                                                            <div className="h-6 w-6 text-base flex items-center justify-center">
                                                                {datachambre.children}
                                                            </div>
                                                            <button
                                                                className={` ${desable[indexchambre] ? 'opacity-50' : 'opacity-100'} h-6 w-6 text-xl flex items-center justify-center cursor-pointer select-none`}
                                                                disabled={desable[indexchambre]}
                                                                onClick={() => {
                                                                    if (datachambre.children < 5) {
                                                                        increaseChildren(indexchambre)
                                                                        Setdesable(false)
                                                                    } else {
                                                                        Setdesable({ ...desable, [indexchambre]: true });
                                                                        toast.warning("Vous avez atteint les nombres d'enfants maximale");
                                                                    }

                                                                }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {/* Affichage des champs de saisie pour les âges des enfants */}
                                                    {datachambre.children > 0 && (
                                                        <div className="mt-2">
                                                            {datachambre.childAges.map((age, childIndex) => (
                                                                <select
                                                                    key={childIndex}
                                                                    className="border bg-transparent border-[#5dca32] rounded-md px-2 py-1 mt-1"
                                                                    value={age}
                                                                    onChange={(e) => handleAgeChange(indexchambre, childIndex, parseInt(e.target.value))}
                                                                >
                                                                    <option className='hidden'>Âge de l'enfant {childIndex + 1}</option>
                                                                    <option className='text-black cursor-pointer' value="1">1</option>
                                                                    <option className='text-black cursor-pointer' value="2">2</option>
                                                                    <option className='text-black cursor-pointer' value="3">3</option>
                                                                    <option className='text-black cursor-pointer' value="4">4</option>
                                                                    <option className='text-black cursor-pointer' value="5">5</option>
                                                                    <option className='text-black cursor-pointer' value="6">6</option>
                                                                    <option className='text-black cursor-pointer' value="7">7</option>
                                                                    <option className='text-black cursor-pointer' value="8">8</option>
                                                                    <option className='text-black cursor-pointer' value="9">9</option>
                                                                    <option className='text-black cursor-pointer' value="10">10</option>
                                                                    <option className='text-black cursor-pointer' value="11">11</option>
                                                                    <option className='text-black cursor-pointer' value="12">12</option>
                                                                    <option className='text-black cursor-pointer' value="13">13</option>
                                                                    <option className='text-black cursor-pointer' value="14">14</option>
                                                                    <option className='text-black cursor-pointer' value="15">15</option>
                                                                    <option className='text-black cursor-pointer' value="16">16</option>
                                                                    <option className='text-black cursor-pointer' value="17">17</option>
                                                                </select>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className='w-full flex justify-end'
                                                >
                                                    <div>
                                                        {(datachambre.totalOccupants * data.initialPrice).toLocaleString()} fbu
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                    <div className='flex justify-end'>
                                        <div className='ml-2 bg-[#5dca32] w-max px-3 py-2 text-white cursor-pointer' onClick={addRoom} >
                                            Ajouter une chambre
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={` sticky top-[10.5vh]    h-full px-2 sm:px-8 py-2 rounded-lg border shadow-2xl  mx-3`}>
                        <div className="flex w-full max-sm:text-xl text-[#5dca32] font-bold text-2xl mb-5">Sommaire</div>
                        <hr className="my-4" />
                        <div className="flex justify-between mb-4">
                            <p className="text-lg font-bold">Personne total</p>
                            <div className="">
                                <p className=""> {totals.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="flex justify-between mb-4">
                            <p className="text-lg font-bold">Sous total</p>
                            <div className="">
                                <p className=""> {(payData[0].initialPrice * totals).toLocaleString()} Fbu</p>
                            </div>
                        </div>
                        <div className="flex justify-between mb-4">
                            <p className="text-lg font-bold">Expédition</p>
                            <p className="">0 Fbu</p>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-lg font-bold">Total</p>
                            <p className="font-bold">{(payData[0].initialPrice * totals).toLocaleString()} Fbu</p>
                        </div>
                        <div
                            to="/Orders/Paiement"
                            class="block w-full mt-6 py-2  text-center cursor-pointer text-white bg-[#5dca32] border border-[#5dca32] rounded-2xl hover:bg-transparent hover:text-[#5dca32] transition"
                        >
                            Commande
                        </div>
                        <div className="w-full flex justify-center">
                            <div className="w-[20em] mt-3 text-center text-gray-400">
                                Agence Burundi Paradise protège vos informations et vos paiements
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FootentContent />
        </div >
    )
}

export default PaymentHotel