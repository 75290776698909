import React from 'react'

function Service() {
    return (
        <div className='w-full h-max overflow-hidden relative mt-10'>
            <div className="sm:text-2xl text-[30px] font-bold mb-4 uppercase  text-[#5dca32] ml-2">Nos services</div>
            <div className='bg-[#5dca32] animate-ping w-10 h-10 t-1/2 left-3 absolute'></div>
            <div data-aos="zoom-in-down">
                <div className="sm:text-[20px]  inline-flex items-center font-bold mb-4  ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    Immobilier
                </div>
                <div className='flex justify-around items-center' data-aos="fade-down" data-aos-delay="600">
                    <div className='w-1/2 border-r border-[#5dca32]'>
                        <div className='text-[22px] ml-5' data-aos="zoom-in" data-aos-delay="500">À vendre</div>
                        <div className='h-[60vh] w-full flex justify-center items-center '>
                            <div className='w-[95%] h-[95%] rounded overflow-hidden'>
                                <img className='h-full w-full object-cover' src='https://pro.regiondo.com/wp-content/uploads/2023/03/tourism-trends-2023-travel-industry-1024x576.jpg' alt='   ' />
                            </div>
                        </div>
                        <div className='text-[20px] p-2'>Grâce à notre moteur de recherche avancé, vous pouvez facilement trouver les destinations qui correspondent à vos critères (budget, thématique, activités, etc.). Consultez les informations détaillées sur les villes, régions et pays, et comparez les différentes options pour choisir la meilleure.
                            <br />
                            <div className='flex w-full justify-end  items-center pr-3'>
                                <div className='flex items-center text-[#5dca32b7] transition-all hover:border-b hover:border-[#5dca32] p-1 hover:text-[#5dca32] cursor-pointer'>
                                    <div>Voir autre</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-2 bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1 h-full bg-[#5dca32]'>
                        <div className='bg-[#3945e7] animate-ping w-5 rounded-full h-5 top-1/2 right-1 absolute'></div>

                    </div>
                    <div className='w-1/2'>
                        <div className='text-[22px] ml-5'>À louer</div>
                        <div className='h-[60vh]  flex justify-center items-center w-full'>
                            <div className='w-[95%] h-[95%] rounded  overflow-hidden flex justify-center items-center'>
                                <img className='h-full w-full object-cover' src='https://renoverpourgagner.com/wp-content/uploads/2023/07/AdobeStock_603805220.jpeg' alt='   ' />
                            </div>
                        </div>
                        <div className='text-[20px] p-2'>
                            Nos équipes d'experts analysent en permanence les différents marchés immobiliers pour identifier les meilleures opportunités d'investissement. Nous vous proposons une sélection de biens correspondant à votre stratégie d'investissement (appartements, maisons, bureaux, commerces, etc.).
                            <br />
                            <div className='flex w-full justify-end  items-center pr-3'>
                                <div className='flex items-center text-[#5dca32b7] transition-all hover:border-b hover:border-[#5dca32] p-1 hover:text-[#5dca32] cursor-pointer'>
                                    <div>Voir autre</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-2 bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service