/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useThemes } from '../../../UserContext/UserContext';
import { Modal, Popover, Whisper } from 'rsuite';
import { toast } from 'react-toastify';
import AjouterCartInform from "./AjouterCartInform";


const ClientPhotgraphie = () => {

    const { produitDetail, SetshowConf, setIsLivraisonSelected } = useThemes()
    const [isPaimentEnligne, setisPaimentEnligne] = useState(false)
    const [carts, GetCarts] = useState(false)
    const [url, GetUrl] = useState('')
    const [zoomImage, SetzoomImage] = useState(false)
    const [ShowMoyenPayement, GetShowMoyenPayement] = useState(false)


    const [nom_complet, Setnom_complet] = useState('')
    const elemetRefnom_complet = useRef(null)

    const [email, Setemail] = useState('')
    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const elemetRefemail = useRef(null)

    const [telephone, Settelephone] = useState('')
    const elemetReftelephone = useRef(null)


    const [typeProjet, settypeProjet] = useState('')
    const elemetReftypeProjet = useRef(null)

    const [autretypeProjet, setAutretypeProjet] = useState('')
    const elemetRefAutretypeProjet = useRef(null)

    const today = new Date().toISOString().split('T')[0]; // Date actuelle au format ISO sans l'heure

    const [eventDate, setEventDate] = useState(null);
    const elementDateDepart = useRef(null)

    const [heures, Setheures] = useState('');
    const elementHeures = useState(null)

    const [receptionDate, setReceptionDate] = useState(null);
    const elementDateRecrpt = useRef(null)

    const [selectedOption, setSelectedOption] = useState('');
    const [price, setPrice] = useState(0);
    const [daysDifference, setDaysDifference] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [echellePrix, GetEchellePrix] = useState('');
    const [percentageChange, setPercentageChange] = useState(0);

    const prixSplit = echellePrix && echellePrix.split(" - ")[1].split("€")[0];
    let basePrice = parseInt(prixSplit);

    if (selectedOption === 'photo') {
        basePrice = parseInt(prixSplit);
    } else if (selectedOption === 'video') {
        basePrice = parseInt(prixSplit) + 2200;
    } else if (selectedOption === 'photoVideo') {
        basePrice = parseInt(prixSplit) + 3400;
    }

    useEffect(() => {
        if (eventDate && receptionDate) {
            calculatePrice(receptionDate);
        }
    }, [selectedOption, receptionDate, eventDate]);

    const handleEventDateChange = (event) => {
        const selectedDate = event.target.value;
        setEventDate(selectedDate);
        if (receptionDate && new Date(receptionDate) < new Date(selectedDate)) {
            setReceptionDate(selectedDate);
        }
    };

    const handleReceptionDateChange = (event) => {
        const selectedDate = event.target.value;
        setReceptionDate(selectedDate);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // const calculatePricea = (date) => {  // pour premier proger d'ajouter le % en ce qui choisir le plusieur jours
    //     const selectedDate = new Date(date);
    //     const eventDateObj = new Date(eventDate);



    //     const millisecondsPerDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes par jour
    //     const differenceInTime = selectedDate.getTime() - eventDateObj.getTime(); // Différence en millisecondes entre les deux dates
    //     const daysDifference = Math.ceil(differenceInTime / millisecondsPerDay); // Différence en jours arrondie à l'entier supérieur

    //     let discountPercentage = daysDifference * 0.5; // Réduction de 0,5% par jour
    //     discountPercentage = Math.min(discountPercentage, 15); // Limiter le pourcentage de réduction à 15%

    //     const discount = basePrice * (discountPercentage / 100); // Calcul de la réduction
    //     const calculatedPrice = basePrice - discount; // Prix calculé après réduction

    //     setPrice(calculatedPrice);
    //     setDaysDifference(daysDifference);
    //     setDiscountPercentage(discountPercentage);
    // };

    const calculatePrice = (date) => {
        const selectedDate = new Date(date);
        const eventDateObj = new Date(eventDate);

        if (isNaN(selectedDate.getTime()) || isNaN(eventDateObj.getTime())) {
            setPrice(0);
            setDaysDifference(0);
            setPercentageChange(0);
            return;
        }

        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes par jour
        const differenceInTime = selectedDate.getTime() - eventDateObj.getTime(); // Différence en millisecondes entre les deux dates
        const daysDifference = Math.ceil(differenceInTime / millisecondsPerDay); // Différence en jours arrondie à l'entier supérieur

        let percentageChange = 0;

        if (daysDifference < 7) {
            percentageChange = (7 - daysDifference) * 0.5;
        } else {
            percentageChange = 0; // Pas de réduction si plus de 7 jours
        }

        const additionalCharge = basePrice * (percentageChange / 100); // Calcul de l'augmentation
        const calculatedPrice = basePrice + additionalCharge; // Prix calculé après augmentation

        setPrice(calculatedPrice);
        setDaysDifference(daysDifference);
        setPercentageChange(percentageChange); // Affichage de l'augmentation nette
    };


    const minDateReception = eventDate ? new Date(eventDate).toISOString().split('T')[0] : today;
    const maxDateReception = eventDate ? new Date(new Date(eventDate).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] : '';


    const [lieu, Setlieu] = useState('')
    const elemetReflieu = useRef(null)


    const [isTextarea, setIsTextarea] = useState('');
    const [charite, setcharite] = useState(0);
    const elemetRefcharite = useRef(null)



    const textaresRef = useRef(null)

    const data = {
        nom_complet: nom_complet,
        email: email,
        telephone: telephone,
        typeProjet: typeProjet,
        autretypeProjet: autretypeProjet,
        lieu: lieu,
        isTextarea: isTextarea,
        datePrev: eventDate,
        heuresPrev: heures,
        produits_souhait: selectedOption,
        prix_souhaite: basePrice,
        dataRecept: receptionDate,
        charite: charite
    }



    function handleSubmit(e) {
        e.preventDefault()

        // if (nom_complet.trim() === '') {
        //     toast.warning('Votre nom complet est obligatoire ', {
        //         autoClose: 2000
        //     });
        //     GetshowSynyhes(false)
        //     elemetRefnom_complet.current.focus()
        //     return false;
        // } else if (email.trim() === '') {
        //     toast.warning('L\'email est obligatoire', {
        //         autoClose: 2000
        //     });
        //     GetshowSynyhes(false)
        //     elemetRefemail.current.focus()
        //     return false;
        // } else if (!email.trim().match(emailRegex)) {
        //     toast.error('Votre email est incorrecte !!')
        //     GetshowSynyhes(false)
        //     elemetRefemail.current && elemetRefemail.current.focus()
        //     return false
        // }
        // else if (telephone.trim() == "") {
        //     toast.warning("Némero de téléphone  est obligatoire !!", {
        //         autoClose: 2000
        //     });
        //     GetshowSynyhes(false)
        //     elemetReftelephone.current.focus()
        //     return false;
        // }
        // else
        
        if (typeProjet == '') {
            toast.warning("Le type du projet  est obligatoire !!", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elemetReftypeProjet.current.focus();
            return false;
        }
        else if (typeProjet == 'autre' && autretypeProjet.trim() == '') {
            toast.warning("Autre type du projet  est obligatoire !!", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elemetRefAutretypeProjet.current.focus();
            return false;
        }
        else if (lieu.trim() == '') {
            toast.warning('Le lieu du projet est obligatoire', {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elemetReflieu.current.focus();
            return false;
        }
        else if (isTextarea.trim() == '') {
            toast.warning('La description de voter demande est obligatoire', {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            textaresRef.current.focus();
            return false;
        }
        else if (eventDate == null) {
            toast.warning("La date  de début est obligatoire", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            return false;
        }
        else if (heures == '') {
            toast.warning("L'heure' de début est obligatoire", {
                autoClose: 2000
            });
            return false;
        }
        else if (receptionDate == null) {
            toast.warning("La date  de reception est obligatoire", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            return false;
        } else if (selectedOption == '') {
            toast.warning("S'il vous plait, Selectione le prix que vous souhaitez", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            return false;
        }
        else {
            setIsLivraisonSelected(false)
            SetshowConf(true)
            toast.success('Donne envoyer')
            console.log("data", data)
        }
    }

    const [showSynyhes, GetshowSynyhes] = useState(false)

    const hunleContinue = () => {
        // if (nom_complet.trim() === '') {
        //     toast.warning('Votre nom complet est obligatoire ', {
        //         autoClose: 2000
        //     });
        //     GetshowSynyhes(false)
        //     elemetRefnom_complet.current.focus()
        //     return false;
        // } else if (email.trim() === '') {
        //     toast.warning('L\'email est obligatoire', {
        //         autoClose: 2000
        //     });
        //     GetshowSynyhes(false)
        //     elemetRefemail.current.focus()
        //     return false;
        // } else if (!email.trim().match(emailRegex)) {
        //     toast.error('Votre email est incorrecte !!')
        //     GetshowSynyhes(false)
        //     elemetRefemail.current && elemetRefemail.current.focus()
        //     return false
        // }
        // else if (telephone.trim() == "") {
        //     toast.warning("Némero de téléphone  est obligatoire !!", {
        //         autoClose: 2000
        //     });
        //     GetshowSynyhes(false)
        //     elemetReftelephone.current.focus()
        //     return false;
        // }
        // else 
        
        if (typeProjet == '') {
            toast.warning("Le type du projet  est obligatoire !!", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elemetReftypeProjet.current.focus();
            return false;
        }
        else if (typeProjet == 'autre' && autretypeProjet.trim() == '') {
            toast.warning("Autre type du projet  est obligatoire !!", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elemetRefAutretypeProjet.current.focus();
            return false;
        }
        else if (lieu.trim() == '') {
            toast.warning('Le lieu du projet est obligatoire', {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elemetReflieu.current.focus();
            return false;
        }
        else if (isTextarea.trim() == '') {
            toast.warning('La description de voter demande est obligatoire', {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            textaresRef.current.focus();
            return false;
        }
        else if (eventDate == null || eventDate == "") {
            toast.warning("La date  de début est obligatoire", {
                autoClose: 2000
            });
            elementDateDepart.current.focus()
            GetshowSynyhes(false)
            return false;
        }
        else if (heures == '') {
            toast.warning("L'heure' de début est obligatoire", {
                autoClose: 2000
            });
            elementHeures.current.focus()
            return false;
        }
        else if (receptionDate == null || receptionDate == "") {
            toast.warning("La date  de reception est obligatoire", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            elementDateRecrpt.current.focus()
            return false;
        }
        else if (!basePrice) {
            toast.warning("S'il vous plait, Selectione le prix que vous souhaitez", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            return false;
        }
        else if (selectedOption == '') {
            toast.warning("S'il vous plait, Selectione le produit que vous souhaitez", {
                autoClose: 2000
            });
            GetshowSynyhes(false)
            return false;
        } else {
            GetshowSynyhes(true)
        }
    }

    return (

        <div className="h-max">
            <form onSubmit={handleSubmit} className="w-full">
                <div className="flex flex-col h-max   justify-center ">
                    <div className={`w-full h-max  flex flex-col items-center`}>
                        <div className="px-1 h-max w-full">
                            <div
                                className="w-full"
                            >


                                {!showSynyhes ?
                                    <div className="flex flex-col h-max w-full">
                                        <div className=" transition-all flex flex-col w-[100%] ">
{/* 
                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label className="text-lg mb-1">Nom complet</label>
                                                <input
                                                    className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                    ref={elemetRefnom_complet} value={nom_complet} onInput={(e) => Setnom_complet(e.target.value)}
                                                    placeholder="Nom complet" />
                                            </div>
                                            <div class={`flex flex-col mt-2   w-full`}>
                                                <label className="text-lg mb-1">Email fonctionnel</label>
                                                <input
                                                    className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                    ref={elemetRefemail} value={email} onInput={(e) => Setemail(e.target.value)}
                                                    placeholder="Email fonctionnel" />
                                            </div> */}

                                            <div className=" transition-all mt-5 flex flex-col w-[100%] ">

                                                {/* <div class={`flex flex-col     w-full`}>
                                                    <label className="text-lg mb-1">Numéro de téléphone</label>
                                                    <input
                                                        className="p-2 bg-transparent  outline-none rounded border focus:border-[#5dca32]"
                                                        value={telephone} ref={elemetReftelephone} onInput={(e) => Settelephone(e.target.value)}
                                                        placeholder="Numéro de téléphone" />
                                                </div> */}

                                                <div class={`flex flex-col mt-5   w-full`}>
                                                    <label className="text-lg mb-1" for="project-type">Type de projet</label>
                                                    <select
                                                        ref={elemetReftypeProjet}
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        name="project-type"
                                                        value={typeProjet}
                                                        onChange={(e) => settypeProjet(e.target.value)}
                                                    >
                                                        <option className="text-black" hidden value="">Sélectionnez un type de projet</option>
                                                        <option className="text-black" value="portrait">Portrait</option>
                                                        <option className="text-black" value="mariage">Mariage</option>
                                                        <option className="text-black" value="evenement">Événement</option>
                                                        <option className="text-black" value="autre">Autre</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {typeProjet == "autre"
                                                && <div class={`flex flex-col mt-5  w-full`}>
                                                    <label className="text-lg mb-1">Autre type du projet</label>
                                                    <input
                                                        className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={autretypeProjet} ref={elemetRefAutretypeProjet} onInput={(e) => setAutretypeProjet(e.target.value)}
                                                        placeholder="Autre type du projet" />
                                                </div>
                                            }
                                            <div class={`flex flex-col mt-5  w-full`}>
                                                <label className="text-lg mb-1">Lieu du projet</label>
                                                <input
                                                    className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                    value={lieu} ref={elemetReflieu} onInput={(e) => Setlieu(e.target.value)}
                                                    placeholder="Lieu du projet" />
                                            </div>
                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label className="text-lg mb-1">Informations supplémentaires</label>
                                                <textarea ref={textaresRef} value={isTextarea} onInput={(e) => setIsTextarea(e.target.value)} class="w-full p-2 border outline-none min-h-[10em] bg-transparent border-green-300 rounded-lg" placeholder="Ajouter un témoignage"></textarea>
                                            </div>
                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label className="text-lg mb-1" htmlFor="eventDate">Date de début prévue</label>
                                                <input
                                                    id="eventDate"
                                                    name="eventDate"
                                                    type="date"
                                                    ref={elementDateDepart}
                                                    value={eventDate || ''}
                                                    onChange={handleEventDateChange}
                                                    min={today}
                                                    className="p-2 bg-transparent w-full  outline-none rounded border focus:border-[#5dca32]"

                                                />
                                            </div>

                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label className="text-lg mb-1" htmlFor="eventDate">Heure de début prévue</label>
                                                <input
                                                    id="eventDate"
                                                    name="eventDate"
                                                    type="time"
                                                    ref={elementHeures}
                                                    value={heures}
                                                    onChange={(e) => Setheures(e.target.value)}
                                                    className="p-2 bg-transparent w-ful  outline-none rounded border focus:border-[#5dca32]"

                                                />
                                            </div>

                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label className="text-lg mb-1" htmlFor="receptionDate">Date de réception  prévue</label>
                                                <input
                                                    id="receptionDate"
                                                    name="receptionDate"
                                                    type="date"
                                                    ref={elementDateRecrpt}
                                                    value={receptionDate || ''}
                                                    onChange={handleReceptionDateChange}
                                                    min={minDateReception}
                                                    max={maxDateReception}
                                                    className="p-2 bg-transparent w-ful outline-none rounded border focus:border-[#5dca32]"

                                                />
                                            </div>
                                            <div className={`h-max w-full  shadow-md p-3 mb-5  rounded `}>
                                                <label className="text-lg mb-1">
                                                    Échelle des prix
                                                </label>
                                                <div className='flex mt-3 flex-wrap'>
                                                    <label class="flex items-center  w-max p-1  cursor-pointer">
                                                        <input
                                                            type="radio"
                                                            name="EchellOption"
                                                            className="form-radio"
                                                            value={`${produitDetail.priceRange.hourly} par heure`}
                                                            onChange={(e) => GetEchellePrix(e.target.value)}
                                                        />
                                                        <div className="ml-2 flex items-center">
                                                            <span>{produitDetail.priceRange.hourly.split(" - ")[1]} par heure</span>
                                                        </div>
                                                    </label>
                                                    <label class="flex items-center  w-max p-1  cursor-pointer">
                                                        <input
                                                            type="radio"
                                                            name="EchellOption"
                                                            className="form-radio"
                                                            value={`${produitDetail.priceRange.halfDay} par demi-journée`}
                                                            onChange={(e) => GetEchellePrix(e.target.value)}
                                                        />
                                                        <div className="ml-2 flex items-center">
                                                            <span>{produitDetail.priceRange.halfDay.split(" - ")[1]} par demi-journée</span>
                                                        </div>
                                                    </label>


                                                    <label class="flex items-center  w-max p-1  cursor-pointer">
                                                        <input
                                                            type="radio"
                                                            name="EchellOption"
                                                            className="form-radio"
                                                            value={`${produitDetail.priceRange.fullDay}  par jour`}
                                                            onChange={(e) => GetEchellePrix(e.target.value)}
                                                        />
                                                        <div className="ml-2 flex items-center">
                                                            <span>{produitDetail.priceRange.fullDay.split(" - ")[1]} par jour</span>
                                                        </div>
                                                    </label>

                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <label className="text-lg mb-1">Choix vos produit:</label>
                                                <label htmlFor="photoOption" className="mx-4 cursor-pointer">
                                                    <input
                                                        id="photoOption"
                                                        name="option"
                                                        type="radio"
                                                        value="photo"
                                                        onChange={handleOptionChange}
                                                        className="mr-1"
                                                    />
                                                    Photos
                                                </label>
                                                <label htmlFor="videoOption" className="mr-4 cursor-pointer">
                                                    <input
                                                        id="videoOption"
                                                        name="option"
                                                        type="radio"
                                                        value="video"
                                                        checked={selectedOption === 'video'}
                                                        onChange={handleOptionChange}
                                                        className="mr-1"
                                                    />
                                                    Vidéo
                                                </label>
                                                <label htmlFor="photoVideoOption" className="mr-4 cursor-pointer">
                                                    <input
                                                        id="photoVideoOption"
                                                        name="option"
                                                        type="radio"
                                                        value="photoVideo"
                                                        checked={selectedOption === 'photoVideo'}
                                                        onChange={handleOptionChange}
                                                        className="mr-1"
                                                    />
                                                    Photos et Vidéo
                                                </label>
                                            </div>



                                            <div class={`flex flex-col mt-5   w-full`}>
                                                <label className="text-lg mb-1">Charité</label>
                                                <div className="relative w-full">
                                                    <input
                                                        type="number"
                                                        className="p-2 w-full bg-transparent outline-none rounded border focus:border-[#5dca32]"
                                                        value={charite} ref={elemetRefcharite} onInput={(e) => setcharite(e.target.value)}
                                                        placeholder="Charité" />
                                                    <div className="absolute top-2.5 bg-yellow-100 right-1 cursor-pointer">
                                                        <Whisper
                                                            trigger="click"
                                                            placement="left"
                                                            speaker={
                                                                <Popover className="text-nowrap whitespace-break-spaces">
                                                                    La <span className="font-bold">charité</span> est un concept qui<br />
                                                                    fait référence à l'action de<br />
                                                                    donner de manière désintéressée pour aider <br /> les autres,
                                                                    en particulier ceux  qui sont dans <br /> le besoin
                                                                    ou qui sont moins fortunés.<br />
                                                                </Popover>}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi text-yellow-600 bi-info-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                                            </svg>
                                                        </Whisper>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className=" flex w-full justify-end mt-5">
                                            <div onClick={() => hunleContinue()} className='hover:border cursor-pointer px-3 py-2 rounded hover:border-[#5dca32] hover:text-[#5dca32] text-white hover:bg-transparent  bg-[#5dca32]'> Continue</div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        {console.log("eventDate", eventDate)}

                                        <div className='md:text-3xl text-xl  mb-5 font-bold'>Synthèse de votre demande</div>
                                        <div class="mb-4 w-full">
                                            <div class=" m-2 flex flex-col">
                                                <div className='mt-2 text-[18px] flex items-center sm:text-[20px]'>
                                                    <div className='text-[#5dca32] mr-1'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                        </svg>
                                                    </div>
                                                    Nom:{produitDetail.title}
                                                </div>
                                                <div className='mt-2 text-[18px] sm:text-[20px] flex items-center'> <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div>  Spécialisation  : {produitDetail.specialization[0]}, {produitDetail.specialization[1]}</div>
                                                <div className='mt-2 text-[18px] sm:text-[20px] flex items-center'> <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div>  Échelle des prix : {basePrice.toLocaleString() == "NaN" ? GetshowSynyhes(false) : basePrice.toLocaleString()} fbu</div>
                                                {!autretypeProjet && <div className='mt-2 text-[18px] flex items-center sm:text-[20px]'> <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div>  Type de projet: {selectedOption == "" ? GetshowSynyhes(false) : selectedOption}</div>}
                                                {autretypeProjet && <div className='mt-2 text-[18px] flex items-center sm:text-[20px]'> <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div>  Type de projet: {autretypeProjet == "" ? GetshowSynyhes(false) : autretypeProjet}</div>}
                                                <div className='mt-2 text-[18px] sm:text-[20px] flex items-center'>  <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div> Lieu du projet: {lieu == "" ? GetshowSynyhes(false) : lieu}</div>
                                                <div className='mt-2 text-[18px] sm:text-[20px] flex items-center'>  <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div> Date de début prévue:  {eventDate == null || eventDate == '' ? GetshowSynyhes(false) : eventDate}</div>
                                                <div className='mt-2 text-[18px] sm:text-[20px] flex items-center'>  <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div> Heure de début prévue:  {heures == "" ? GetshowSynyhes(false) : heures}</div>
                                                <div className='mt-2 text-[18px] sm:text-[20px] flex items-center'>  <div className='text-[#5dca32] mr-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                    </svg>
                                                </div> Date de réception : {receptionDate == null || receptionDate == '' ? GetshowSynyhes(false) : receptionDate} <span className='text-sm'></span> </div>
                                            </div>
                                        </div>

                                        <div class="mb-2">
                                            <div class="font-bold text-[18px] ">Sous total : <span>{basePrice.toLocaleString()}fbu</span></div>
                                        </div>
                                        <div class="mb-2">
                                            <div class="font-bold text-[18px] ">TVA : (2%) <span>{(basePrice * 2 / 100).toLocaleString()}fbu</span> </div>
                                        </div>
                                        <div class="mb-2">
                                            <div class="font-bold text-[18px] ">Charité : <span>{parseInt(charite).toLocaleString()}</span></div>
                                        </div>

                                        <div class="mb-2">
                                            <div class="font-bold text-[18px] ">Augmentation  : <span>{percentageChange}%</span></div>
                                        </div>
                                        <div class="mb-2">
                                            <div class="font-bold text-[18px]">Montant total à payer : <span className='text-[#5dca32]  font-extrabold'>{(parseInt(price) + parseInt(charite) + parseInt((basePrice * 2 / 100).toFixed(0))).toLocaleString()}fbu</span></div>
                                        </div>


                                        <div className={`h-max w-full  shadow-md p-3 mb-5  rounded `}>
                                            <div className="font-bold text-[20px]">
                                                Votre choix de paiment
                                            </div>
                                            <div className='flex mt-3 flex-wrap'>
                                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                                    <input
                                                        type="radio"
                                                        name="paymentOption"
                                                        checked={isPaimentEnligne}
                                                        onChange={(e) => setisPaimentEnligne(true)}
                                                    />
                                                    <div className="ml-2 flex items-center">
                                                        <span>Paiement en ligne</span>
                                                    </div>
                                                </label>
                                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                                    <input
                                                        type="radio"
                                                        name="paymentOption"
                                                        value="Paiement en personne"
                                                        className="form-radio"
                                                        onChange={(e) => setisPaimentEnligne(false)}
                                                    />
                                                    <div className="ml-2 flex items-center">
                                                        <span>Paiement en personne</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        {isPaimentEnligne &&
                                            <div className={`h-max w-full  shadow-md p-3 mb-5 rounded`}>
                                                <div className="font-bold text-[20px]">
                                                    Systèmes de paiement
                                                </div>
                                                <div className="">
                                                    <div onClick={() => GetCarts(true)} className="flex  w-max p-0.5 items-center my-1 ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                                        </svg>
                                                        <label htmlFor="cartes" className="flex items-center cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cassette" viewBox="0 0 16 16">
                                                                <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm9-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7 6a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2H7Z" />
                                                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13ZM1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-.691l-1.362-2.724A.5.5 0 0 0 12 10H4a.5.5 0 0 0-.447.276L2.19 13H1.5a.5.5 0 0 1-.5-.5v-9ZM11.691 11l1 2H3.309l1-2h7.382Z" />
                                                            </svg>
                                                            <div className="ml-1">
                                                                Ajouter une carte
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <Modal overflow={false} size='xs' open={carts} onClose={() => GetCarts(false)}>
                                                        <Modal.Header>
                                                            <div className="flex justify-center w-full">
                                                                <p className=" text-center text-[#5dca32]">
                                                                    Nous assurons la sécurité de vos données de paiement.
                                                                </p>
                                                            </div>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className={` h-max rounded-2xl`}>
                                                                <AjouterCartInform GetCarts={GetCarts} />
                                                            </div>

                                                        </Modal.Body>
                                                    </Modal>

                                                    <div className="flex flex-col items-start my-1">
                                                        <div className="flex items-center  p-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                                            </svg>
                                                            <label htmlFor="image" className="flex items-center cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                                                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                                                </svg>
                                                                <input type="file" hidden
                                                                    onChange={(e) => GetUrl(URL.createObjectURL(e.target.files[0]))}
                                                                    id="image" />
                                                                <div className="ml-1">
                                                                    Image de preuve
                                                                </div>
                                                            </label>
                                                        </div>
                                                        {url !== '' &&
                                                            <div onClick={() => SetzoomImage(true)} className="w-[3em] ml-5 h-[3em] cursor-pointer rounded border  bg-white overflow-hidden">
                                                                <img src={`${url}`} className="w-full h-full object-contain object-center" />
                                                            </div>
                                                        }

                                                        {zoomImage && <div className="w-full top-0 p-2  left-0 fixed bg-[#00000085] flex z-[1000] overflow-y-auto h-full  justify-center">
                                                            <div className={`w-[95%] h-[95vh] p-2 relative rounded-2xl overflow-hidden `}>
                                                                <div className="w-full pr-2 flex justify-end mt-3 absolute right-1">
                                                                    <div onClick={() => SetzoomImage(false)} className="bg-red-500 p-2 rounded-full text-white cursor-pointer">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full h-full">
                                                                    <img className={`w-full h-full object-contain`} src={`${url}`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        <div
                                                            onClick={() => GetShowMoyenPayement(!ShowMoyenPayement)}
                                                            className="flex items-center p-1 cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5dca32" class="bi bi-arrow-right-circle-fill mr-2" viewBox="0 0 16 16">
                                                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                                            </svg>

                                                            <label htmlFor="autreMoyen" className="flex items-center p-1 cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                                                                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                                                    <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
                                                                </svg>
                                                                <div className='ml-1'>
                                                                    Autre moyen
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className={`pl-5 transition-all ${ShowMoyenPayement ? 'h-[14em]' : 'h-0 overflow-hidden'}`}>
                                                            <div class="space-y-1">
                                                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                                                    <input type="radio" name="paymentOptionOperateur" class="form-radio" />
                                                                    <div className="ml-2 flex items-center">
                                                                        <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                                            <img src='../image/lumitel.png' className="w-full h-full object-contain" />
                                                                        </div>
                                                                        <span>Lumicash</span>
                                                                    </div>
                                                                </label>


                                                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                                                    <input type="radio" name="paymentOptionOperateur" class="form-radio " />
                                                                    <div className="ml-2 flex items-center">
                                                                        <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                                            <img src='../image/ecocashe.jpg' className="w-full h-full object-contain" />
                                                                        </div>
                                                                        <span>Ecocash</span>
                                                                    </div>
                                                                </label>

                                                                <label class="flex items-center  w-max p-1 cursor-pointer">
                                                                    <input type="radio" name="paymentOptionOperateur" class="form-radio " />
                                                                    <div className="ml-2 flex items-center">
                                                                        <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                                            <img src='../image/feza.png' className="w-full h-full object-contain" />
                                                                        </div>
                                                                        <span>Feza</span>
                                                                    </div>
                                                                </label>

                                                                <label class="flex items-center  w-max p-1 cursor-pointer">
                                                                    <input type="radio" name="paymentOptionOperateur" class="form-radio " />
                                                                    <div className="ml-2 flex items-center">
                                                                        <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                                            <img src='../image/mobinotel.png' className="w-full h-full object-contain" />
                                                                        </div>
                                                                        <span>Mobinotel</span>
                                                                    </div>
                                                                </label>


                                                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                                                    <input type="radio" name="paymentOptionOperateur" class="form-radio " />
                                                                    <div className="ml-2 flex items-center">
                                                                        <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                                            <img src='../image/enoti.jpg' className="w-full h-full object-contain" />
                                                                        </div>
                                                                        <span>Bancobu eNoti</span>
                                                                    </div>
                                                                </label>


                                                                <label class="flex items-center  w-max p-1  cursor-pointer">
                                                                    <input type="radio" name="paymentOptionOperateur" class="form-radio " />
                                                                    <div className="ml-2 flex items-center">
                                                                        <div className="w-[1em] rounded-full mr-1 overflow-hidden h-[1em]">
                                                                            <img src='../image/New-logo-CECM-01.jpg' className="w-full h-full object-contain" />
                                                                        </div>
                                                                        <span>CECM Akaravyo</span>
                                                                    </div>
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className=" flex w-full justify-end items-center mt-5">
                                            <div onClick={() => GetshowSynyhes(false)} className=' mx-2 cursor-pointer px-3 py-2 border rounded' >Retour</div>
                                            <div>
                                                <input type="submit" value="Envoyer la demande" className='hover:border mx-2 cursor-pointer px-3 py-2 rounded hover:border-[#5dca32] hover:text-[#5dca32] text-white hover:bg-transparent  bg-[#5dca32]' />
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <div class="text-[15px]  mt-5 flex ">
                                                <div className='text-[18px] font-bold'>
                                                    N.B:
                                                </div>
                                                <div className='text-center'>
                                                    Chez Agence Burundi paradise, nous comprenons que votre événement est unique et mérite une couverture complète. C'est pourquoi nous avons mis en place un système de réductions avantageux pour vous récompenser lorsque vous optez pour une réservation prolongée.<br />
                                                    Plus vous réservez de jours, plus les réductions sont importantes.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default ClientPhotgraphie;








// import React, { useState, useEffect } from 'react';

// const WeddingForm = () => {
//     const today = new Date().toISOString().split('T')[0]; // Date actuelle au format ISO sans l'heure

//     const [eventDate, setEventDate] = useState(null); // Initialiser eventDate à null
//     const [heures, Setheures] = useState('');

//     const [receptionDate, setReceptionDate] = useState(null); // Initialiser receptionDate à null
//     const [selectedOption, setSelectedOption] = useState('');
//     const [price, setPrice] = useState(0);
//     const [daysDifference, setDaysDifference] = useState(0);
//     const [percentageChange, setPercentageChange] = useState(0); // Utiliser pourcentage de changement au lieu de réduction
//     const [echellePrix, GetEchellePrix] = useState('');

//     //const prixSplit = echellePrix && echellePrix.split(" - ")[1].split("€")[0];
//     let basePrice = 0;

//     if (selectedOption === 'photo') {
//         basePrice = 1500;
//     } else if (selectedOption === 'video') {
//         basePrice = 1500 + 2200;
//     } else if (selectedOption === 'photoVideo') {
//         basePrice = 1500 + 3400;
//     }

//     useEffect(() => {
//         if (eventDate && receptionDate) {
//             calculatePrice(receptionDate);
//         }
//     }, [selectedOption, receptionDate, eventDate]);

//     const handleEventDateChange = (event) => {
//         const selectedDate = event.target.value;
//         setEventDate(selectedDate);
//         if (receptionDate && new Date(receptionDate) < new Date(selectedDate)) {
//             setReceptionDate(selectedDate);
//         }
//     };

//     const handleReceptionDateChange = (event) => {
//         const selectedDate = event.target.value;
//         setReceptionDate(selectedDate);
//     };

//     const handleOptionChange = (event) => {
//         setSelectedOption(event.target.value);
//     };

    // const calculatePrice = (date) => {
    //     const selectedDate = new Date(date);
    //     const eventDateObj = new Date(eventDate);

    //     if (isNaN(selectedDate.getTime()) || isNaN(eventDateObj.getTime())) {
    //         setPrice(0);
    //         setDaysDifference(0);
    //         setPercentageChange(0);
    //         return;
    //     }

    //     const millisecondsPerDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes par jour
    //     const differenceInTime = selectedDate.getTime() - eventDateObj.getTime(); // Différence en millisecondes entre les deux dates
    //     const daysDifference = Math.ceil(differenceInTime / millisecondsPerDay); // Différence en jours arrondie à l'entier supérieur

    //     let percentageChange = 0;

    //     if (daysDifference < 7) {
    //         percentageChange = (7 - daysDifference) * 0.5;
    //     } else {
    //         percentageChange = 0; // Pas de réduction si plus de 7 jours
    //     }

    //     const additionalCharge = basePrice * (percentageChange / 100); // Calcul de l'augmentation
    //     const calculatedPrice = basePrice + additionalCharge; // Prix calculé après augmentation

    //     setPrice(calculatedPrice);
    //     setDaysDifference(daysDifference);
    //     setPercentageChange(percentageChange); // Affichage de l'augmentation nette
    // };

//     const minDateReception = eventDate ? new Date(eventDate).toISOString().split('T')[0] : today;
//     const maxDateReception = eventDate ? new Date(new Date(eventDate).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] : '';

//     return (
//         <div className="bg-gray-100 p-8">
//             <h1 className="text-2xl font-bold mb-4">Formulaire de demande de photographie et vidéo de mariages</h1>

//             <form onSubmit={(event) => event.preventDefault()}>
//                 <div className="mb-4">
//                     <label className="font-bold block mb-2" htmlFor="eventDate">Date de l'événement :</label>
//                     <input
//                         id="eventDate"
//                         name="eventDate"
//                         type="date"
//                         value={eventDate || ''} // Afficher une chaîne vide si eventDate est null
//                         onChange={handleEventDateChange}
//                         min={today}
//                         className="w-full p-2 border border-gray-300"
//                     />
//                 </div>

//                 <div className="mb-4">
//                     <label className="font-bold block mb-2" htmlFor="receptionDate">Date de réception :</label>
//                     <input
//                         id="receptionDate"
//                         name="receptionDate"
//                         type="date"
//                         value={receptionDate || ''} // Afficher une chaîne vide si receptionDate est null
//                         onChange={handleReceptionDateChange}
//                         min={minDateReception} // Utilisation de la date de l'événement comme date minimale
//                         max={maxDateReception} // Utilisation de 7 jours après la date de l'événement comme date maximale
//                         className="w-full p-2 border border-gray-300"
//                     />
//                 </div>

//                 <div className="mb-4">
//                     <label className="font-bold block mb-2">Choix :</label>
//                     <label htmlFor="photoOption" className="mr-4">
//                         <input
//                             id="photoOption"
//                             name="option"
//                             type="radio"
//                             value="photo"
//                             checked={selectedOption === 'photo'}
//                             onChange={handleOptionChange}
//                             className="mr-1"
//                         />
//                         Photos
//                     </label>
//                     <label htmlFor="videoOption" className="mr-4">
//                         <input
//                             id="videoOption"
//                             name="option"
//                             type="radio"
//                             value="video"
//                             checked={selectedOption === 'video'}
//                             onChange={handleOptionChange}
//                             className="mr-1"
//                         />
//                         Vidéo
//                     </label>
//                     <label htmlFor="photoVideoOption" className="mr-4">
//                         <input
//                             id="photoVideoOption"
//                             name="option"
//                             type="radio"
//                             value="photoVideo"
//                             checked={selectedOption === 'photoVideo'}
//                             onChange={handleOptionChange}
//                             className="mr-1"
//                         />
//                         Photos et Vidéo
//                     </label>
//                 </div>
//                 <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Envoyer la demande</button>
//             </form>

           
//                 <div>
//                     <p className="mt-4">Nombre de jours entre l'événement et la réception : {daysDifference}</p>
//                     <p className="mt-2">Pourcentage de changement : {percentageChange}%</p>
//                 </div>
            
//             <p className="mt-4">Prix calculé : {price}$</p>
//         </div>
//     );
// };

// export default WeddingForm;

