/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from 'react'
import TourismeCourant from './TourismeCourant'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'
import FootentContent from '../FootentContent/FootentContent'
import { Modal } from 'rsuite'
import VoyageurClientTourism from './VoyageurClientTourism'


function Tourisme() {
    const navig = useNavigate()
    const {
        GetHotelDetail, hoteData, HundlwScrollTop, isDark, isclients, setIsclients, totals, logChambreValues, data,
        setlieu,

        SetdateDepart,
        SetdateEnd, lieu, dateDepart, dateEnd,
        categories,
        SetCategorie } = useThemes()


    const elemetReflieu = useRef(null)
    const elemetRefdateDepart = useRef(null)
    const elemetRefdateEnd = useRef(null)
    const elemetRefcategorie = useRef(null)

    useEffect(() => {
        const today = new Date();
        const dateToday = today.toISOString().split('T')[0];
        const nextWeek = new Date(today);
        nextWeek.setDate(today.getDate() + 1);
        const dateNextWeek = nextWeek.toISOString().split('T')[0];
        SetdateDepart(dateToday);
        SetdateEnd(dateNextWeek);
    }, [dateDepart, dateEnd]);

    const hundleSubmit = (e) => {
        e.preventDefault()

        if (lieu == '') {
            toast.warning('Où allez-vous ??', {
                autoClose: 2000
            });
            elemetReflieu.current.focus()

            return false;
        } else if (dateDepart === '') {
            toast.warning("La date d'entrée", {
                autoClose: 2000
            });
            elemetRefdateDepart.current.focus()
            return false;
        } else if (dateEnd === '') {
            toast.warning("La date de sortir", {
                autoClose: 2000
            });
            elemetRefdateEnd.current.focus()
            return false;
        } else {
            toast.success(`Vous avez recherché les hote de ${lieu}`)
            console.log("hotel recher", data)
        }
    }


    return (
        <div className='mt-[10vh]'>
            <div>
                <div className='relative h-[35vh] overflow-hidden'>
                    <img src='https://www.yaga-burundi.com/wp-content/uploads/2023/02/Web_Photo_frame_Landscape_3.png' className='w-full object-cover object-bottom' alt='   ' />
                    <div className='absolute flex flex-col w-full items-center top-12 left-2'>
                        <div className={`text-[80px] stroketext1`}>Tourisme</div>
                    </div>
                </div>
                <form onSubmit={hundleSubmit} className='flex gap-2 items-end mt-1 h-max p-2'>
                    <div class={`flex flex-col   w-full`}>
                        <label className="text-lg mb-1" for="project-type">Où allez-vous</label>
                        <select
                            ref={elemetReflieu}
                            className="p-2.5 bg-transparent cursor-pointer outline-none rounded border focus:border-[#5dca32]"
                            name="project-type"
                            value={lieu}
                            onChange={(e) => setlieu(e.target.value)}
                        >
                            <option value="" hidden>Où allez-vous</option>
                            <option className="text-black cursor-pointer" value="bubanza">Bubanza</option>
                            <option className="text-black cursor-pointer" value="bujumbura_mairie">Bujumbura Mairie</option>
                            <option className="text-black cursor-pointer" value="bujumbura_rural">Bujumbura Rural</option>
                            <option className="text-black cursor-pointer" value="bururi">Bururi</option>
                            <option className="text-black cursor-pointer" value="cankuzo">Cankuzo</option>
                            <option className="text-black cursor-pointer" value="cibitoke">Cibitoke</option>
                            <option className="text-black cursor-pointer" value="gitega">Gitega</option>
                            <option className="text-black cursor-pointer" value="karuzi">Karuzi</option>
                            <option className="text-black cursor-pointer" value="kayanza">Kayanza</option>
                            <option className="text-black cursor-pointer" value="kirundo">Kirundo</option>
                            <option className="text-black cursor-pointer" value="makamba">Makamba</option>
                            <option className="text-black cursor-pointer" value="muramvya">Muramvya</option>
                            <option className="text-black cursor-pointer" value="muyinga">Muyinga</option>
                            <option className="text-black cursor-pointer" value="mwaro">Mwaro</option>
                            <option className="text-black cursor-pointer" value="ngozi">Ngozi</option>
                            <option className="text-black cursor-pointer" value="rumonge">Rumonge</option>
                            <option className="text-black cursor-pointer" value="rutana">Rutana</option>
                            <option className="text-black cursor-pointer" value="ruyigi">Ruyigi</option>
                        </select>
                    </div>
                    <div class={`flex flex-col    w-full`}>
                        <label className="text-lg mb-1">Date d'entree</label>
                        <input
                            className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                            value={dateDepart} ref={elemetRefdateDepart} onInput={(e) => SetdateDepart(e.target.value)}
                            type="date"
                        />
                    </div>
                    <div class={`flex flex-col    w-full`}>
                        <label className="text-lg mb-1">Date de sortie</label>
                        <input
                            className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                            value={dateEnd} ref={elemetRefdateEnd} onInput={(e) => SetdateEnd(e.target.value)}
                            type="date"
                        />
                    </div>
                    <div onClick={() => setIsclients(true)} class={`flex flex-col  cursor-pointer  w-full`}>
                        <label className="text-lg mb-1">Invites</label>
                        <input
                            className="p-2 bg-transparent outline-none rounded border focus:border-[#5dca32]"
                            value={totals} ref={elemetRefdateEnd} onInput={(e) => SetdateEnd(e.target.value)}
                            type="number"
                            readOnly
                        />
                        {/* {totals} {totals > 1 ? 'clients' : 'client'}  */}

                    </div>
                    <div class={`flex flex-col   w-full`}>
                        <label className="text-lg mb-1" for="project-type">Catégorie</label>
                        <select
                            ref={elemetRefcategorie}
                            className="p-2.5 bg-transparent cursor-pointer outline-none rounded border focus:border-[#5dca32]"
                            name="project-type"
                            value={categories}
                            onChange={(e) => SetCategorie(e.target.value)}
                        >
                            <option value="" hidden>Catégorie</option>
                            <option className="text-black cursor-pointer" value="appartement">Appartement</option>
                            <option className="text-black cursor-pointer" value="maison">Maison</option>
                            <option className="text-black cursor-pointer" value="Chambre">Chambre</option>
                        </select>
                    </div>
                    <button type='submite' class={`flex flex-col    w-max`}>
                        <div className="p-2 cursor-pointer bg-transparent text-[#5dca32] outline-none rounded border border-[#5dca32]">
                            Rechercher
                        </div>
                    </button>
                </form>

                <TourismeCourant />
                <div className={`mt-5 p-4  text-[#5dca32] text-[25px] `}>
                    Rafraîchissez votre expérience de voyage en optant pour un hébergement qui sort de l'ordinaire.
                </div>
            </div>

            <div className='flex flex-wrap'>
                {hoteData.map((data, index) => (
                    <div data-position={index} key={index} className='border w-[13.5em] relative rounded  m-1 overflow-hidden'>
                        <div className="relative w-full  h-max  overflow-hidden">
                            <div onClick={() => { navig(`/Hotel/detail`); GetHotelDetail(data); HundlwScrollTop() }} className='cursor-pointer w-full h-[12em] relative  overflow-hidden'>
                                <img className="w-full h-full object-cover  object-center"
                                    src={data.url}
                                    alt='   '
                                    onLoad={() => {
                                        document.querySelector(`[data-position="${index}"] .spinner`).style.display = 'none';
                                    }}
                                />
                                <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinner`}>
                                    <svg
                                        className="animate-spin h-8 w-8 text-[#5dca32]"
                                        viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className='p-2'>
                                <div className='flex items-center justify-between'>
                                    <div onClick={() => { navig(`/Hotel/detail`); GetHotelDetail(data); HundlwScrollTop() }} className={` cursor-pointer font-bold text-[17px] pr-1  text-nowrap text-ellipsis overflow-hidden  max-sm:text-[12px] sm:mb-2 first-letter:uppercase`}>
                                        {data.name}
                                    </div>
                                    <div onClick={() => console.log("vous aimez le produit")} className='cursor-pointer flex items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                                        </svg>
                                        <div className='ml-0.5'>{data.numberOfReviews}K</div>
                                    </div>
                                </div>
                                {data.price && <div onClick={() => { navig(`/Hotel/detail`); GetHotelDetail(data); HundlwScrollTop() }} className={`flex cursor-pointer justify-between  items-baseline sm:mb-1 sm:space-x-2`}>
                                    <div>Prix</div>
                                    <div className='flex items-center'>
                                        <div className={`text-[13px]  mr-1  text-[#5dca32] font-semibold`}>
                                            {data.price.toFixed(0).toLocaleString()} fbu
                                        </div>
                                        <div className="text-[13px] text-right md:text-sm text-red-400 line-through">
                                            {(data.price + (data.price * 20 / 100)).toFixed(0).toLocaleString()} fbu
                                        </div>
                                    </div>
                                </div>
                                }
                                <div onClick={() => { navig(`/Hotel/detail`); GetHotelDetail(data); HundlwScrollTop() }} className='text-left cursor-pointer text-wrap text-[11px] '>
                                    {data.bio && data.bio.length > 50 ? data.bio.slice(0, 50) + '...' : data.bio}
                                </div>

                                <div onClick={() => { navig(`/Hotel/detail`); GetHotelDetail(data) }} className="flex cursor-pointer items-baseline my-1 space-x-2">
                                    <p className={`text-[12px] font-thin`}>
                                        Emplacement: {data.location.city},{data.location.avenue}
                                    </p>
                                </div>

                                <div className="text-[12px] font-thin">
                                    Agréments : {data.amenities[0]},{data.amenities[1]},{data.amenities[2]}
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>


            <div className='w-full flex justify-center  mt-10'>
                <div className='text-gray-500 w-[80%] text-center'>
                    Profitez de nos offres spéciales et de nos soldes exceptionnels, jusqu'à -50% de réduction sur une sélection de produits de haute qualité. Nos articles sont fabriqués avec des matériaux durables et sont couverts par une garantie satisfait . La livraison est rapide , et le paiement en ligne est sécurisé. Nos clients sont enchantés de leurs achats, comme en témoignent les notes élevées qu'ils nous ont attribuées. Mais ne tardez pas, ces offres à durée limitée risquent de s'épuiser rapidement ! Découvrez dès maintenant notre gamme de produits innovants et tendance, dotés de fonctionnalités avancées pour vous simplifier la vie.
                </div>
            </div>
            <FootentContent />

            <Modal dialogClassName="pb-5" overflow={false} size='md' open={isclients} onClose={() => { logChambreValues() }}>
                <Modal.Header>
                    <div className="flex items-center justify-between">
                        <div className="font-bold sm:text-xl max-sm:text-[18px] text-center text-[#5dca32]">
                            {totals.toLocaleString()} {totals > 1 ? 'clients' : 'client'}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='h-max'>
                        <VoyageurClientTourism />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Tourisme