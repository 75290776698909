import React from 'react'

function Reservation() {
    return (
        <div className='mt-10 overflow-hidden'>
            <div className="sm:text-[20px]  inline-flex items-center font-bold mb-4  ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                </svg>
                Réservation de vols
            </div>
            <div className=' h-[80vh] relative'>
                <img className='h-full w-full' src='https://www.airtransat.com/getmedia/879f9309-a298-4066-b0a2-65d293cc0fcd/vols-flights-generic-generiques-plane-avion-1000x600.aspx?width=1000&height=600&ext=.jpg' alt='    ' />
                <div className='absolute w-full h-full  text-center bg-gradient-to-r from-[#ff000086] to-[#5dca3286]  p-2 text-white right-1 text-[25px]'>
                    <span className='block mb-5'> Réservation de vols avec Agence Burundi Paradise</span>
                    Que vous voyagiez seul ou en groupe, Agence Burundi Paradise s'occupe de l'organisation de vos déplacements aériens pour votre séjour au Burundi. Profitez d'un service personnalisé et de tarifs compétitifs
                    grâce à nos partenariats avec les compagnies aériennes locales et internationales.
                    <br />
                    <br />
                    Nos conseillers voyages se chargent de la réservation de vos billets d'avion, depuis votre ville de départ jusqu'à votre arrivée au Burundi. Nous recherchons pour vous les meilleures options en termes d'horaires, de durée de vol et de prix.
                    <div className='flex w-full justify-center  mt-10 items-center pr-3'>
                        <div className='flex items-center text-[white] transition-all hover:border-b hover:border-[#5dca32] p-1 hover:text-[#5dca32] cursor-pointer'>
                            <div>Voir autre</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-2 bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Reservation