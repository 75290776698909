/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Sidenav } from 'rsuite'
import { useThemes } from '../../../UserContext/UserContext'

function Messages() {
    const [user, GetUsers] = useState([])
    const { howMessage, GetShowMessage } = useThemes()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const discution = await axios.get('https://jsonplaceholder.typicode.com/comments')
                GetUsers(discution.data)
            } catch (error) {
                console.log("il y a des erreurs lors de la recuperation des utilisateurs", error)
            }
        }
        fetchUser()
    }, [])




    return (
        <>
            {
                howMessage &&
                <div className='flex bg-[rgba(0,0,0,0.12)] z-[1000]  fixed top-0 left-0 w-full h-[100vh] justify-end '>
                    <div onClick={(e) => { e.stopPropagation() }} className='h-[100vh] border-l-2  border-[#5dca32] w-[27em]'>
                        <Sidenav>
                            <div className="h-[7vh] bg-[#5dca32] relative flex justify-center items-center font-serif text-white uppercase text-lg w-full text-center">
                                discussion
                            </div>
                            <>
                                <div className='flex px-1  h-[8vh] relative justify-between mt-1 w-full '>
                                    <div onClick={() => GetShowMessage(false)} className={` cursor-pointer  h-[95%]  flex justify-center items-center`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi rounded bg-white text-[#5dca32] bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                                        </svg>
                                    </div>
                                    <div className='w-full'>
                                        <div className=''>
                                            <div className='w-full  text-center font-bold text-lg'> NIYOMUKIZA Justin</div>
                                            <div className='w-full text-center text-[#5dca32] text-sm'>En ligne</div>
                                        </div>
                                    </div>

                                </div>
                                <div className='overflow-x-hidden h-[70vh] border-t overflow-y-auto'>
                                    <Sidenav>
                                        {user && user.map((data, index) => (
                                            <div key={index} className='p-2 flex'>
                                                <div className={`p-1  flex w-full ${index % 2 == 0 ? ' justify-start' : 'justify-end'} `}>
                                                    <div className={`border w-[80%] ${index % 2 == 0 ? 'bg-[#5dca3260]' : null} p-2 rounded-xl relative`}>
                                                        <div className="w-full">
                                                            {data.body}
                                                            <div className='w-full text-[10px] text-end'>
                                                                {new Date().toLocaleString()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Sidenav>
                                </div>
                                <div className='h-[15vh] p-0.5 flex items-end pb-2 w-full'>
                                    <textarea
                                        className='w-[90%] outline-none border-gray-300 bg-transparent rounded resize-none p-1 h-[13vh] focus:border focus:border-[#5dca32] border'
                                        placeholder="Tape ici..."
                                    >
                                    </textarea>
                                    <button className='text-white ml-0.5 p-1.5 rounded bg-[#5dca21]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-send-check" viewBox="0 0 16 16">
                                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372zm-2.54 1.183L5.93 9.363 1.591 6.602z" />
                                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686" />
                                        </svg>
                                    </button>
                                </div>
                            </>

                        </Sidenav>
                    </div>

                </div>
            }
        </>
    )
}

export default Messages





























// import React, { useState, useRef, useEffect } from 'react';
// import { MessageBox } from 'react-chat-elements';
// import 'react-chat-elements/dist/main.css';
// import { v4 as uuidv4 } from 'uuid';

// function Messages() {
//     const [messages, setMessages] = useState([]);
//     const [currentMessage, setCurrentMessage] = useState('');
//     const inputRef = useRef(null);

//     useEffect(() => {
//         if (inputRef.current) {
//             inputRef.current.focus();
//         }
//     }, []);

//     const handleSend = (forwarded = false, reply = null) => {
//         if (currentMessage.trim() !== '') {
//             const newMessage = {
//                 id: uuidv4(),
//                 text: currentMessage,
//                 position: 'left',
//                 type: 'text',
//                 date: new Date(),
//                 avatar: 'https://placekitten.com/50/50', // URL de l'avatar
//                 title: 'User', // Titre du message
//                 forwarded, // Indicateur de message transféré
//                 forwardedMessageText: forwarded ? `Fwd: ${currentMessage}` : '', // Texte du message transféré
//                 reply, // Réponse au message
//             };
//             setMessages([...messages, newMessage]);
//             setCurrentMessage('');
//             if (inputRef.current) {
//                 inputRef.current.focus();
//             }
//         }
//     };

//     const handleForward = (id) => {
//         const messageToForward = messages.find((msg) => msg.id === id);
//         if (messageToForward) {
//             setCurrentMessage(`Fwd: ${messageToForward.text}`);
//         }
//     };

//     const handleReply = (id) => {
//         const messageToReply = messages.find((msg) => msg.id === id);
//         if (messageToReply) {
//             setCurrentMessage(`Re: ${messageToReply.text}`);
//         }
//     };

//     const handleRemove = (id) => {
//         setMessages(messages.filter((msg) => msg.id !== id));
//     };

//     return (
//         <div className="flex flex-col items-center overflow-auto min-h-[80vh] bg-gray-100">
//             <header className="bg-blue-600 w-full py-4 text-white text-center">
//                 <h1 className="text-2xl">React Chat Elements Example</h1>
//             </header>
//             <main className="flex flex-col items-center w-full flex-grow p-4">
//                 <div className="bg-white w-full max-w-2xl p-4 rounded-lg shadow-md flex-grow overflow-y-auto mb-4">
//                     {messages.map((msg) => (
//                         <div key={msg.id} className="mb-4 relative">
//                             <MessageBox
//                                 position={msg.position}
//                                 type={msg.type}
//                                 text={msg.text}
//                                 date={msg.date}
//                                 avatar={msg.avatar}
//                                 title={msg.title}
//                                 titleColor={msg.reply ? "#8717ae" : "#3b82f6"} // Couleur du titre pour les réponses
//                                 forwarded={msg.forwarded}
//                                 forwardedMessageText={msg.forwardedMessageText} // Texte du message transféré
//                                 reply={msg.reply}
//                                 notch={true} // Ajouter un espace réservé pour le bouton de suppression
//                                 onRemove={() => handleRemove(msg.id)} // Gérer la suppression du message
//                                 className={`${msg.forwarded ? 'bg-yellow-100' : ''} ${msg.reply ? 'bg-purple-100' : ''} p-2 rounded`} // Styles conditionnels
//                             />
//                             {msg.forwarded && (
//                                 <div className="text-xs text-gray-500 italic">
//                                     Forwarded
//                                 </div>
//                             )}
//                             <div className="absolute top-0 right-0 flex flex-col items-end space-y-1">
//                                 <button
//                                     className="text-blue-500 text-xs mt-1"
//                                     onClick={() => handleReply(msg.id)}
//                                 >
//                                     Reply
//                                 </button>
//                                 <button
//                                     className="text-blue-500 text-xs mt-1"
//                                     onClick={() => handleForward(msg.id)}
//                                 >
//                                     Forward
//                                 </button>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
// <div className="flex w-full max-w-2xl">
//     <input
//         ref={inputRef}
//         onKeyPress={(e) => {
//             if (e.key === 'Enter') {
//                 handleSend();
//             }
//         }}
//         type="text"
//         value={currentMessage}
//         onChange={(e) => setCurrentMessage(e.target.value)}
//         placeholder="Type your message here..."
//         className="flex-grow p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:border-blue-500"
//     />
//     <button
//         onClick={() => handleSend()}
//         className="bg-blue-600 text-white p-2 rounded-r-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
//     >
//         Send
//     </button>
// </div>
//             </main>
//         </div>
//     );
// }

// export default Messages;


