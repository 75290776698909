import React from 'react'
import ProduitsCournat from './ProduitsCournat'
import NavBarsImmobilier from './NavBarsImmobilier'
import Product from './Product'
import FootentContent from '../FootentContent/FootentContent'

function ImmobilesComponent() {
    return (
        <div>
            <NavBarsImmobilier />
            <ProduitsCournat />
            <Product />
            <FootentContent />
        </div>
    )
}

export default ImmobilesComponent