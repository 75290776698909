import React from 'react'

function Tourisme() {
    return (
        <div className='flex mt-[5em] overflow-hidden relative'>
            <div className='w-1/2'>
                <div className="sm:text-[20px]  inline-flex items-center font-bold mb-4  ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5dca32" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                    </svg>
                    Tourisme
                </div>
                <div className='h-[60vh] w-full flex justify-center items-center '>
                    <div className='w-[95%] h-[95%] animation rounded overflow-hidden'>
                        <img className='h-full w-full object-cover'
                            src='https://pro.regiondo.com/wp-content/uploads/2023/03/tourism-trends-2023-travel-industry-1024x576.jpg'
                            alt='   '
                        />
                    </div>
                </div>
            </div>
            <div className='w-1/2'>
                <div className='text-[20px] p-2 mt-10'>
                    Que vous soyez un voyageur indépendant ou un organisateur de groupes, Agence Burundi Paradise est votre partenaire idéal pour découvrir le Burundi. Nous vous proposons une gamme complète de services sur-mesure pour vivre une expérience inoubliable.
                    <br />
                    <br />
                    Que vous soyez un voyageur indépendant ou un organisateur de groupes, Agence Burundi Paradise est votre partenaire idéal pour découvrir le Burundi. Nous vous proposons une gamme complète de services sur-mesure pour vivre une expérience inoubliable.      <br />
                    <div className='flex w-full justify-end  items-center pr-3'>
                        <div className='flex items-center text-[#5dca32b7] transition-all hover:border-b hover:border-[#5dca32] p-1 hover:text-[#5dca32] cursor-pointer'>
                            <div>Voir autre</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-2 bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#3235ca] rounded-lg animate-ping w-10 h-10 top-1/2 left-1 absolute'></div>
            <div className='bg-[#e7cd39] animate-ping w-10 rounded-xl h-10 bottom-1/2 right-1/3 absolute'></div>
        </div>
    )
}

export default Tourisme